import React, { useEffect, useState } from "react";
import { GoClock } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  SetSelectedCategory,
  addtoCart,
  clearCart,
  decrementCart,
  getCartTotal,
} from "../redux/cart/CartSlice";

const ProductList = ({ selectedTag }) => {
  const { isAuth } = useSelector((store) => store.Authentication);
  const { productBySubcat, subCategorytotal } = useSelector(
    (store) => store.subCategory
  );
  const { productByCatTag, tagtotal } = useSelector((store) => store.Tag);
  const { CartItems, selectedCategory } = useSelector((store) => store.Cart);
  const dispatch = useDispatch();

  const [currCatTag, setCurrCatTag] = useState("");
  const [showProducts, setShowProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);


  useEffect(() => {
    if (selectedTag !== "" && selectedTag !== undefined) {
      const getproducts = async () => {
        const url = `${Baseurl}/api/v1/product/productbyCatTagid/${selectedTag._id}`;
        const fetchproducts = await axios(url);
        try {
          if (fetchproducts.data.success === true) {
            const prod = fetchproducts.data.product.filter(
              (data) => data.outOfStock === false
            );
            let productbySubcat = prod.map(
              (product) => (product = { ...product, cartQuantity: 0 })
            );
            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = productbySubcat.findIndex(
                (product) => product._id === cartitem.ProductId
              );
              if (itemIndex >= 0) {
                productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
              }
            });
            setShowProducts(productbySubcat);
            setTempLoading(false);
          }
        } catch (error) { }
      };
      getproducts();
    }
  }, [selectedTag, CartItems]);

  const addToCartPress = (product, index) => {
    if (selectedCategory === product.category || selectedCategory === "") {
      if (selectedCategory === "") {
        dispatch(SetSelectedCategory(product.category));
      }

      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) { }
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
      };
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    } else {
      dispatch(SetSelectedCategory(product.category));
      dispatch(clearCart());
      dispatch(getCartTotal());
      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) { }
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
      };
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    }
  };

  const decrementCartPress = (product, index) => {
    try {
      let products = showProducts;
      products[index].cartQuantity = products[index].cartQuantity - 1;
      setShowProducts(products);
    } catch (error) { }
    const formData = {
      ProductId: product._id,
      ProductName: product.name,
      CatId: product.categoryId,
      CatName: product.category,
      Brand: product.brand,
      Services: product.services,
      Duration: product.duration,
      ImgUrl: product.ImgUrlMbl,
      Price: product.sellingPrice,
      Qty: 1,
      TotalAmount: product.sellingPrice * 1,
      Mrp: product.mrp,
      TotalPrice: product.sellingPrice * 1,
      TotalMrp: product.mrp * 1,
      Discount: product.mrp * 1 - product.sellingPrice * 1,

      productCostPrice: product.productCostPrice,
    };
    dispatch(decrementCart(formData));
    dispatch(getCartTotal());
  };

  return (
    <>
      <div className="mobpro">
        {showProducts.length > 0 ? (
          <>
            <div id="waxing" className="waxtopselling mx-auto rounded p-1 ">
              <div id="topselling" className="heading1wax ">
                <h5 style={{ color: '#163c83' }} >{selectedTag.name}</h5>
              </div>
              {showProducts.map((product, index) => (
                <div className="card bg-white mb-2 p-1" key={index}>
                  <div className="row w-100 mx-auto">
                    <div className="col-lg-5 col-12">
                      <div>
                        <img
                          className="img-fluid"
                          src={product.ImgUrlDesk}
                          alt="img"
                        // style={{ height: "230px" }}
                        />
                        <span className="service_time">
                          <GoClock className="text-dark" />{" "}
                          <label className="mt-1" htmlFor="time">
                            {product.duration}
                          </label>
                        </span>
                        {product.newLaunch ? (
                          <>
                            <span className="bestseller1">BESTSELLER</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.mustTry ? (
                          <>
                            <span className="bestseller1">MustTry</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.outOfStock ? (
                          <>
                            <span className="bestseller1">OutOfSTock</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.inDemand ? (
                          <>
                            <span className="bestseller1">InDemand</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.hotDeal ? (
                          <>
                            <span className="bestseller1">HotDeal</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {<div className="col-lg-7 col-12">
                      <div id="productcard" className="card-text">
                        <p id="product-name">{product.name}</p>
                        <ul id="card1">
                          {product.services.map((indService, index) => (
                            <li key={index}>{indService.service}</li>
                          ))}
                        </ul>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="ratebtn">
                            <p id="p1">
                              ₹{product.sellingPrice}/- &nbsp;
                              <span className="text-success">
                                {product.discount}% off{" "}
                              </span>{" "}
                              <span
                                style={{ textDecoration: "line-through" }}
                                className="text-secondary"
                              >
                                ₹{product.mrp}
                              </span>
                            </p>
                          </div>

                          {product.cartQuantity > 0 ? (
                            <>
                              <button
                                id="btn-indec"
                                className="btn text-white  d-flex rounded justify-content-between"
                              >
                                <span
                                  onClick={() =>
                                    decrementCartPress(product, index)
                                  }
                                >
                                  <FaMinus style={{ cursor: "pointer" }} />
                                </span>
                                <span className="">{product.cartQuantity}</span>
                                <span
                                  onClick={() => addToCartPress(product, index)}
                                >
                                  <FaPlus style={{ cursor: "pointer" }} />
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  justifyContent: window.matchMedia(
                                    "(max-width:768px)"
                                  ).matches
                                    ? "center"
                                    : "normal",
                                }}
                                className="d-flex"
                                onClick={() => addToCartPress(product, index)}
                              >
                                <button
                                  id="addto"
                                  className="btn text-white "
                                >
                                  ADD TO CART
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              ))}
            </div>

            {/* 03-06-24 */}
            {/* <button>Toggle bottom offcanvas</button> */}
          </>
        ) : (
          <></>
        )}
      </div>

    </>
  );
};

export default ProductList;
