import {createSlice} from '@reduxjs/toolkit';


const initialState = {
  SalonOrderTotal: [].slice().reverse(),
  SalonCurrentOrder: '',
  salonOrderLoading: true,
};

const OrderSlice = createSlice({
  name: 'OrderSalon',
  initialState,
  reducers: {
    SalonOrderget(state, action) {
      state.SalonOrderTotal = action.payload;
      state.salonOrderLoading = false;
    },
    SalonClearorder(state, action) {
      state.SalonOrderTotal = [];
    },
    SalonClearCurrentorder(state, action) {
      state.SalonCurrentOrder = '';
    },
    SalonNewOrder(state, action) {
      if (action.payload.success) {
        state.SalonOrderTotal = [
          action.payload.order,
          ...state.SalonOrderTotal,
        ];
        state.SalonCurrentOrder = action.payload.order;
      }
    },
  },
});

export const {
  SalonOrderget,
  SalonClearorder,
  SalonClearCurrentorder,
  SalonNewOrder,
} = OrderSlice.actions;
export default OrderSlice.reducer;
