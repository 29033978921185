import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { GoClock } from "react-icons/go";
import Media1 from "../../pages/media1";
import { useDispatch, useSelector } from "react-redux";
import SubCategorylist from "../SubCategorylist";
import ProductList from "../ProductList";
import { useNavigate, useParams } from "react-router-dom";
import { setProductBySubcat } from "../../redux/subCategory/subCategorySlice";
import { SetLoginModalOpen } from "../../redux/authentication/AuthenticationSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import SalonSlider from "./SalonSlider";
import SalonListDetail from "./SalonListDetail";

const SearchMobCat = () => {
  const { categorytotal } = useSelector((store) => store.category);
  const { subCategorytotal, subCategoryLoading } = useSelector(
    (store) => store.subCategory
  );
  const { tagtotal, tagLoading, productByCatTag } = useSelector(
    (store) => store.Tag
  );
  const { CartItems, cartTotalAmount } = useSelector((store) => store.Cart);
  const { isAuth } = useSelector((store) => store.Authentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [superCategory, setSuperCategory] = useState("");
  const [defaultSubcat, setDefaultSubcat] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [catTag, setCatTag] = useState([]);
  const [selectedTag, setselectedTag] = useState("");
  const [catTagLoading, setCatTagLoading] = useState(true);

  console.log('hiii');

  useEffect(() => {
    const selectedCategory = categorytotal.find(
      (cat) => cat.slugUrl === params.caturl
    );
    if (selectedCategory) {
      setSubCategoryName(selectedCategory.name);
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/subcategory/subcategoryid/${selectedCategory._id}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const filteredSubCategories = fetchproducts.data.subcategory;
              setSubCategory(filteredSubCategories);
              setDefaultSubcat(filteredSubCategories[0]);
              dispatch(setProductBySubcat(filteredSubCategories[0]));
            }
          } catch (error) { }
        };
        fetchData();
      } catch (error) { }
    }
  }, [categorytotal, params.caturl, subCategorytotal]);

  useEffect(() => {
    if (defaultSubcat !== "") {
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/categorytag/tagsbysubcat/${defaultSubcat._id}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const catTag = fetchproducts.data.tags;
              setCatTag(catTag);
              setselectedTag(catTag[0]);
              setCatTagLoading(false);
            }
          } catch (error) { }
        };
        fetchData();
      } catch (error) { }
    }
  }, [defaultSubcat]);

  const handleCheckout = () => {
    if (!isAuth) {
      dispatch(SetLoginModalOpen(true));
    } else {
      navigate("/cart");
    }
  };

  return (
    <>
          <div className="container-fluid-lg productPage">
            <div
              style={{
                position: "sticky",
                top: "0",
                zIndex: "90",
                backgroundColor: "#ffffff",
              }}
              className="contmedia1"
            >
              <SalonSlider/>
            </div>
            <div className="container mt-2">
              <div className="row w-100 mx-auto justify-content-center h-100">
                {/* <div className="col-lg-4 col-12">
                  <SubCategorylist
                    cattag={catTag}
                    getSelectedTag={getSelectedTag}
                  />
                </div> */}
                <div className="col-lg-8 col-12">
                  <SalonListDetail />
                </div>
              </div>
            </div>
            {CartItems.length > 0 ? (
              <div className="product-checkout shadow mobileVersion">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "11pt" }}>
                    TotalCost:{cartTotalAmount}
                  </span>
                </div>
                <div>
                  <button
                    className="product-checkout-btn"
                    onClick={() => handleCheckout()}
                    style={{ backgroundColor: "#c8386b" }}
                  >
                    VIEW CART
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
    </>
  );
};

export default SearchMobCat;
