import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  createSaloon,
  serviceImages,
  validateSlugUrl,
} from "../redux/saloon/SaloonSlice";
import QRCode from "qrcode";

const AddSaloon = () => {
  const { desktopimage, imageLoading, mobileimage } = useSelector(
    (store) => store.saloon
  );
  const { superCategorytotal } = useSelector((store) => store.superCategory);
  const dispatch = useDispatch();

  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [password, setPassword] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [salonType, setSalonType] = useState("");

  const locations = [
    { name: "Ameerpet" },
    { name: "Panjagutta" },
    { name: "Somajiguda" },
    { name: "Himayat Nagar" },
    { name: "Kachiguda" },
    { name: "Khairtabad" },
    { name: "Karwan" },
    { name: "Amberpet" },
    { name: "Shamshabad" },
    { name: "Adibatla" },
    { name: "Maheshwaram" },
    { name: "Shadnagar" },
    { name: "Rajendranagar" },
    { name: "Srisailam Highway" },
    { name: "Miyapur" },
    { name: "Pocharam" },
    { name: "Bachupally" },
    { name: "Nizampet" },
    { name: "Bollaram" },
    { name: "Yepral" },
    { name: "Shamirpet" },
    { name: "LB Nagar" },
    { name: "Nagole" },
    { name: "Uppal" },
    { name: "Habsiguda" },
    { name: "Ghatkesar" },
    { name: "Nacharam" },
    { name: "Peerzadiguda" },
    { name: "Vanastalipuram" },
    { name: "Gachibowli" },
    { name: "Kondapur" },
    { name: "Tellapur" },
    { name: "Kukatpalli" },
    { name: "Attapur" },
    { name: "Kokapet" },
    { name: "Patancheru" },
    { name: "Madhapur" },
    { name: "Appa Junction" },
  ];

  const saloonFor = [
    { name: "Male" },
    { name: "Female" },
    { name: "Unisex" },
    { name: "Pet" },
  ];

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const nameSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(nameSlug);
    const response = await dispatch(validateSlugUrl(nameSlug));
    if (response.payload.success) {
      setError("Saloon alredy exist");
      setErrorcolor("red");
    } else if (nameSlug.length <= 2) {
      setErrorcolor("red");
      setError("minimum 3 letters");
      setCheckSlugUrl("");
    } else if (nameSlug.length >= 3) {
      setErrorcolor(" ");
      setError("");
      setCheckSlugUrl(nameSlug);
    }
  };

  const selectArea = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setArea(text);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();

    if (checkslugUrl !== "") {
      const qrData = JSON.stringify({
        name: name,
        phone: phone,
        address: address,
      });
      try {
        const qrcode = await QRCode.toDataURL(qrData);
        const formData = {
          name: name,
          slugUrl: checkslugUrl,
          saloonFor: salonType,
          password: password,
          phone: phone,
          address: address,
          desktopImage: desktopimage,
          mobileImage: desktopimage,
          area: area,
          show: show,
          qrcode: qrcode,
        };
        console.log(formData, "1");
        const response = await dispatch(createSaloon(formData));
        if (response.payload.success) {
          alert("Saloon Created");
          setName("");
          setPhone("");
          setAddress("");
          setArea("");
          setShow("");
          setPassword("");
          setCheckSlugUrl("");
        }
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    } else {
      alert("please enter correct values");
    }
  };

  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(serviceImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      <div className="w-100 mobilelogoimage">
        <img
          src="/salonpartnerlogo.png"
          alt="imglogo"
          style={{ height: "30vh", width: "50vw" }}
          height="100"
          width="100"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="w-25 desktoplogoimage">
          <img
            src="/salonpartnerlogo.png"
            alt="imglogo"
            style={{ height: "50vh", width: "20vw" }}
            height="100"
            width="100"
          />
        </div>

        <div
          className="w-75 desktopinputcontainer"
          style={{ border: "1px solid #ddd", marginTop: "80px" }}
        >
          <form onSubmit={handlesubmit}>
            <h4 className="p-4">Add Saloon</h4>
            <div className="p-10" style={{ padding: "20px" }}>
              <div className="p-6">
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext"
                    >
                      Name *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      required
                      onChange={(e) => verifyslugurl(e)}
                    />
                    <p style={{ color: errorcolor }} className="mt-2">
                      {error}
                    </p>
                  </Col>
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext"
                    >
                      Phone *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone"
                      value={phone}
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Col>
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext"
                    >
                      Password *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext1"
                    >
                      Saloon Type
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        setSalonType(e.target.value);
                      }}
                      value={saloonFor.name}
                      name={saloonFor.name}
                    >
                      <option value="0">{"Select saloon for"}</option>
                      {saloonFor.map((data, index) => (
                        <option
                          key={data.index}
                          value={data.name}
                          name={data.name}
                          required
                          selected={salonType === data.name}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md>
                    <Form.Label className="disabletext1">Shop Image</Form.Label>
                    <Form.Control type="file" onChange={categoryImageChange} />
                    <div className="text-center mt-2">
                      * The image width and height should be 191px * 218px
                    </div>
                    <div>
                      {imageLoading ? (
                        <div></div>
                      ) : (
                        <img src={desktopimage} height={100} alt="img" />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Address *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">Area</Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        selectArea(e);
                      }}
                      value={superCategorytotal.name}
                      name={superCategorytotal.name}
                    >
                      <option value="0">{"Select Area"}</option>
                      {locations
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((data, index) => (
                          <option
                            key={data.index}
                            value={data.name}
                            name={data.name}
                            required
                            selected={area === data.name}
                          >
                            {data.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
              <div className="card-footer ">
                {checkslugUrl !== "" &&
                phone !== "" &&
                name !== "" &&
                salonType !== "" &&
                area !== "" &&
                password !== "" &&
                address !== "" ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    Add Saloon
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    disabled
                    type="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    Add Saloon
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div
          className="w-100 mobileverinputcontainer"
          style={{ border: "1px solid #ddd", marginTop: "10px" }}
        >
          <form onSubmit={handlesubmit}>
            <h4 className="p-4">Add Saloon</h4>
            <div className="" style={{ padding: "8px" }}>
              <div className="p-6">
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext4"
                    >
                      Name *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      required
                      onChange={(e) => verifyslugurl(e)}
                    />
                    <p style={{ color: errorcolor }} className="mt-2">
                      {error}
                    </p>
                  </Col>
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext4"
                    >
                      Phone *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone"
                      value={phone}
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Col>
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext2"
                    >
                      Password *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      className="disabletext3"
                    >
                      Saloon Type
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        setSalonType(e.target.value);
                      }}
                      value={saloonFor.name}
                      name={saloonFor.name}
                    >
                      <option value="0">{"Select saloon for"}</option>
                      {saloonFor.map((data, index) => (
                        <option
                          key={data.index}
                          value={data.name}
                          name={data.name}
                          required
                          selected={salonType === data.name}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md>
                    <Form.Label className="disabletext3">Shop Image</Form.Label>
                    <Form.Control type="file" onChange={categoryImageChange} />
                    <div className="text-center mt-2">
                      * The image width and height should be 191px * 218px
                    </div>
                    <div>
                      {imageLoading ? (
                        <div></div>
                      ) : (
                        <img src={desktopimage} height={100} alt="img" />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card-footer ">
                {checkslugUrl !== "" &&
                phone !== "" &&
                name !== "" &&
                salonType !== "" &&
                area !== "" &&
                password !== "" &&
                address !== "" ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    Add Saloon
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    disabled
                    type="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    Add Saloon
                  </button>
                )}
              </div>
            </div>
          </form>
          <div style={{ marginBottom: "100px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default AddSaloon;
