import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";
import { Card } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const { Meta } = Card;

const BeautySlider1 = () => {
  const { salonatHome_Sub_cat } = useSelector((store) => store.subCategory);
  const navigate = useNavigate();
  const params = useParams();

  return (
    <>
      <div id="heading3" className="mt-3 px-2m d-flex justify-content-start">
        <h4>
          <span >Beauty Essentials</span>
        </h4>
      </div>
      <div className="mt-1">
        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          freeMode={true}
          // pagination={{
          //   clickable: false,
          // }}
          modules={[Pagination, Mousewheel, Autoplay]}
          loop={true}
          // autoplay={{
          //   delay: 1500,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          // }}
          breakpoints={{
            360: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            460: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            720: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1080: {
              slidesPerView: 5,
              // spaceBetween: 5,
            },
          }}
          className="mySwiper"
        >
          {salonatHome_Sub_cat.map((data, index) => (
            <SwiperSlide
              key={index}
              onClick={() => navigate(`/salon-at-home/${data.name}`)}
              style={{cursor:"pointer"}}
            >
              <Card
                hoverable
                style={{height:'272px'}}
                className="sliderHome"
                cover={
                  <img
                    alt="example"
                    src={data.desktopImage}
                    className="sliderHomeImg"
                  />
                }
              >
                <h6 className="beautytext">{data.name}</h6>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default BeautySlider1;
