import React from "react";
import {
  Apple,
  Facebook,
  Instagram,
  LocationOn,
  Pinterest,
  Search,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { FaGooglePlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate=useNavigate()
  return (
    <>
      <div id="footer" className="text-white mt-3 p-0 m-0 w-100 desktopVersion">
        <div className="container mt-3">
          <div className="row w-100">
          <div className="col-lg-3 col-sm-6 col-6">
              <ul>
                <li style={{ color: "#C8386B",lineHeight:"28px" }}>More from Saloon Treat</li>

                <Link style={{ textDecoration: "none", color: "#fff" }}>
                  <li  className="moreform">
                    {" "}
                    1209 Mig chitrapuri hills, Khaja guda, Hyderabad, 500089
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 col-6">
              <ul>
                <li style={{ color: "#C8386B" }}>About</li>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>About Us</li>
                </Link>
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Privacy Policy</li>
                </Link>
                <Link
                  to="/faq"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>FAQs</li>
                </Link>
                <Link
                  to="/terms-conditions"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Terms and Conditions</li>
                </Link>
                <Link
                  to="/cancellation-policy"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Cancellation Policy</li>
                </Link>
                <Link
                  to="/refundPage"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Refund Policy</li>
                </Link>
                <Link
                  to="/ShippingPolicy"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Shipping & Delivery Policy</li>
                </Link>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 col-6">
              <ul>
                <li style={{ color: "#C8386B" }}>Categories</li>
                <li>Salon At Home</li>
                <li>Spa At Home</li>
                <li>Advance Facials</li>
                <li>Male Spa</li>
                <li>Male Grooming</li>
                <li>Hair Studio</li>
              </ul>
            </div>

            <div className="col-lg-3 col-sm-6 col-6">
              <ul>
                <li style={{ color: "#C8386B" }}>Contact & Support</li>
                <Link
                  to="/contact-us"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <li>Contact Us</li>
                </Link>
                <li>info@salontreat.com</li>
                {/* <li>+91 9797 63 9797</li> */}
              </ul>
            </div>
          </div>
          <hr />
          {/* <div id="city" className="mt-2">
            <span
              className="city_new"
              style={{
                width: "1070px",
                wordBreak: "break-word",
                margin: "auto",
              }}
            >
              <a className="footer-colour" href="/agra-at-home-services">
                Agra
              </a>
              &nbsp;|&nbsp;
              <a className="footer-colour" href="/ahmedabad-at-home-services">
                Ahmedabad
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/aligarh-at-home-services">
                Aligarh
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/amritsar-at-home-services">
                Amritsar
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/bareilly-at-home-services">
                Bareilly
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/bengaluru-at-home-services">
                Bengaluru
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/bhagalpur-at-home-services">
                Bhagalpur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/bhopal-at-home-services">
                Bhopal
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/bhubaneswar-at-home-services">
                Bhubaneswar
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/chandigarh-at-home-services">
                Chandigarh
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/cuttack-at-home-services">
                Cuttack
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/dehradun-at-home-services">
                Dehradun
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/delhi-at-home-services">
                Delhi
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/faridabad-at-home-services">
                Faridabad
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/gandhi-nagar-at-home-services">
                GandhiNagar
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/ghaziabad-at-home-services">
                Ghaziabad
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/gorakhpur-at-home-services">
                Gorakhpur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/greater-noida-at-home-services">
                GreaterNoida
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/gurugram-at-home-services">
                Gurugram
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/gwalior-at-home-services">
                Gwalior
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/haridwar-at-home-services">
                Haridwar
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/haldwani-at-home-services">
                Haldwani
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/hyderabad-at-home-services">
                Hyderabad
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/indore-at-home-services">
                Indore
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/jabalpur-at-home-services">
                Jabalpur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/jaipur-at-home-services">
                Jaipur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/jammu-at-home-services">
                Jammu
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/jhansi-at-home-services">
                Jhansi
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/kanpur-at-home-services">
                Kanpur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/lucknow-at-home-services">
                Lucknow
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/ludhiana-at-home-services">
                Ludhiana
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/meerut-at-home-services">
                Meerut
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/mohali-at-home-services">
                Mohali
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/moradabad-at-home-services">
                Moradabad
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/mumbai-at-home-services">
                Mumbai
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/mysuru-at-home-services">
                Mysore
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/nagpur-at-home-services">
                Nagpur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/noida-at-home-services">
                Noida
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/patna-at-home-services">
                Patna
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/panchkula-at-home-services">
                Panchkula
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/prayagraj-at-home-services">
                Prayagraj
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/pune-at-home-services">
                Pune
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/raipur-at-home-services">
                Raipur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/roorkee-at-home-services">
                Roorkee
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/rudrapur-at-home-services">
                Rudrapur
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/srinagar-at-home-services">
                Srinagar
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/tirupati-at-home-services">
                Tirupati
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/ujjain-at-home-services">
                Ujjain
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/varanasi-at-home-services">
                Varanasi
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/vijayawada-at-home-services">
                Vijayawada
              </a>
              &nbsp;|&nbsp;
              <a class="footer-colour" href="/zirakpur-at-home-services">
                Zirakpur
              </a>
            </span>
          </div> */}
          <div id="icon" className="mt-4 ">
            <div className="d-flex flex-wrap justify-content-between ">
              <div id="icon1" className="p-1">
                <span>
                  <Instagram style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>
              <div id="icon1" className="p-1">
                <span>
                  <Facebook style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>
              <div id="icon1" className="p-1">
                <span>
                  <Twitter style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>
              <div id="icon1" className="p-1">
                <span>
                  <Pinterest style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>
              <div id="icon1" className="p-1">
                <span>
                  <YouTube style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>

              <div id="icon1" className="p-1">
                <span>
                  <Apple style={{ cursor: "pointer", color: "#AE2E70" }} />
                </span>
              </div>
              <div id="icon1" className="p-1">
                <span>
                  <FaGooglePlay
                    style={{ cursor: "pointer", color: "#AE2E70" }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5" onClick={()=>navigate("/saloonNearMe")}>
          <p style={{ fontSize: "9pt" }} className="text-center text-white">
            Copyright 2024 @SaloonTreat | Powered by: Dexterous Technology. All
            rights reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
