import React, { useEffect, useState } from "react";
import { VscThreeBars } from "react-icons/vsc";
import ReactModal from "react-modal";
import {
  CheckOutlined,
  Close,
  LocationOn,
  PersonRounded,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Cart4 } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginOtp,
  SetLoginModalOpen,
  setLoginMobile,
  authActions,
  loginAuthentication,
  signout,
} from "../../redux/authentication/AuthenticationSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import { IoLogOut, IoSearchOutline } from "react-icons/io5";

const MobileHeader = () => {
  const {
    isAuth,
    loginData,
    isLoginModalOpen,
    loginMobile,
    currentAddress,
    address,
  } = useSelector((store) => store.Authentication);

  const { CartItems } = useSelector((store) => store.Cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [login, setlogin] = useState(false);
  const [Otp, setOtp] = useState(false);
  const [register, setRegister] = useState(false);

  const [number, setNumber] = useState("");
  const [otpsave, setotpsave] = useState("");
  const [otpvalue1, setOtpvalue1] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [termCheckbox, setTermCheckbox] = useState(false);

  const [errorMassage, setErrorMassage] = useState("");
  const [loginbtnLoad, setLoginbtnLoad] = useState(true);
  const [otpbtnLoad, setOtpbtnLoad] = useState(true);
  const [regdbtnLoad, setRegdbtnLoad] = useState(true);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    if (loginMobile) {
      setNumber(loginMobile);
    }
  }, [loginMobile]);

  const setNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setErrorMassage("");
    setNumber(value);
    if (value.length === 10) {
      setErrorMassage("");
      setLoginbtnLoad(false);
    } else {
      setErrorMassage("Please Enter Valid Number");
      setLoginbtnLoad(true);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }

      const formdata = {
        number: number,
        OTP: OTP,
      };
      setotpsave(formdata);
      dispatch(LoginOtp(formdata));
      dispatch(setLoginMobile(number));
      // dispatch(loginAuthentication(formdata));
      dispatch(SetLoginModalOpen(false));
      setNumber("");
      setOtp(true);
      setlogin(false);
    } else {
      setErrorMassage(" Please enter valid number");
    }
  };

  const resendClick = () => {
    setOtpvalue1("");
    setSeconds(30);
    setIsActive(true);
    dispatch(loginAuthentication(otpsave));
  };

  const setOtpChange = (e) => {
    const value = e.target.value;
    setOtpvalue1(value);
    if (value.length === 6) {
      setOtpbtnLoad(false);
    } else {
      setOtpbtnLoad(true);
    }
  };

  const verifyClicks = async () => {
    let optvalue = String(otpvalue1);
    if (optvalue.length === 6) {
      setErrorMassage("");

      if (optvalue === String(otpsave.OTP)) {
        try {
          const config = {
            Headers: { "Content-Type": "application/json" },
          };
          const formData = {
            Mobile: number,
          };
          const userDetails = await axios.post(
            `${Baseurl}/api/v1/client/loginclient`,
            formData,
            config
          );

          if (userDetails.data.success) {
            const loginDetails = {
              ...userDetails.data.client,
              isAuth: true,
            };
            dispatch(authActions.signin(loginDetails));
            // navigate("/");
            setOtp(false);
            toast.success("Logged in Successfully");
            dispatch(SetLoginModalOpen(false));
            dispatch(setLoginMobile(""));
          } else {
            // resgistation
            setRegister(true);
            setOtp(false);
          }
        } catch (error) {
          setRegister(true);
          setOtp(false);
        }
      } else {
        setErrorMassage("please enter correct OTP");
      }
    }
  };

  const onSubmitRegister = async (e) => {
    e.preventDefault();
    if (name !== "" && email !== "") {
      try {
        const formData = {
          Name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
          Email: email.trim(),
          Mobile: number,
          Gender: gender,
          readCheckbox: termCheckbox,
        };

        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/client/new`;
        const ClientDetails = await axios.post(url, formData, config);

        if (ClientDetails.data.success) {
          setRegister(false);
          const loginDeatails = {
            ...ClientDetails.data.client,
            isAuth: true,
          };
          dispatch(authActions.signin(loginDeatails));
          navigate("/");
          setName("");
          setEmail("");
          setNumber("");
          setGender("");
        } else {
        }
      } catch (error) {}
    } else if (name === "") {
      setNameError("Please provide name");
    } else if (email === "") {
      setEmailError("Please provide email");
    }
  };
  return (
    <div className="container-fluid-lg mobileVersion">
      <div id="nav" className="container-fluid border">
        <div className="">
          <span className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <img
                src="/assets/st.png"
                id="logo"
                className="img-fluid"
                alt=""
              />
            </Link>
            <div
              id="logoText"
              className="fs-5 text-secondary fw-lighter"
              onClick={() => navigate("/")}
            >
              | Home Saloon
            </div>
          </span>
        </div>

        <div
          id="sub-nav"
          className="d-flex justify-content-between align-items-center"
        >
          {/* <h6 style={{ margin: "0 10px" }}>Blog</h6> | */}
          {!isAuth ? (
            <>
              <h6
                onClick={() => dispatch(SetLoginModalOpen(true))}
                style={{ margin: "0 10px" }}
              >
                Login / Sign Up
              </h6>
            </>
          ) : (
            <>
              <h6 style={{ margin: "0 10px" }}>{loginData.Name}</h6>
            </>
          )}

          {isAuth ? (
            <>
              <div>
                <span onClick={() => setmodal(true)} style={{ color: "#000" }}>
                  <VscThreeBars className="fs-2" />
                </span>
              </div>
            </>
          ) : (
            <></>
          )}

          {isAuth ? (
            <>
              <div className="mx-2 ">
                <div className="d-flex mb-2 justify-content-between">
                  <span
                    onClick={() => navigate("/cart")}
                    style={{ color: "#000", cursor: "pointer" }}
                  >
                    <Cart4 className="fs-4" />
                  </span>

                  <span
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                    className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
                  >
                    {CartItems.length}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mx-2 ">
                <div className="d-flex mb-2 justify-content-between">
                  <span
                    onClick={() => dispatch(SetLoginModalOpen(true))}
                    style={{ color: "#000", cursor: "pointer" }}
                  >
                    <Cart4 className="fs-4" />
                  </span>

                  <span
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                    className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
                  >
                    {CartItems.length}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        <ReactModal
          className="rounded border-0 mt-5"
          isOpen={modal}
          onRequestClose={() => setmodal(false)}
          style={{
            content: {
              width: "200px",
              height: "200px",
              marginLeft: "82%",
              backgroundColor: "#FFFFFF",
            },
            overlay: { backgroundColor: "rgba(0,0,0,0)", zIndex: "96" },
          }}
        >
          <table id="modal" className="responsive table table-hover">
            <tbody>
              {/* <tr style={{ cursor: "pointer" }}>
                <td>Contribute</td>
              </tr>
              <tr style={{ cursor: "pointer" }}>
                <td>Franchise</td>
              </tr>
              <tr style={{ cursor: "pointer" }}>
                <td>Spa at Events</td>
              </tr> */}

              {isAuth ? (
                <>
                  <tr
                    onClick={() => navigate("/profile") || setmodal(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Profile</td>
                  </tr>
                  <tr
                    onClick={() => navigate("/checkout") || setmodal(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Checkout</td>
                  </tr>
                  <tr
                    onClick={() => dispatch(signout())}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Log Out</td>
                  </tr>
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </ReactModal>
      </div>
      <ReactModal
        isOpen={isLoginModalOpen}
        onRequestClose={() => dispatch(SetLoginModalOpen(false))}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "68%"
              : "26%",
            height: "285px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0)", // Transparent background color
            boxShadow: "none", // Remove any shadow
            zIndex: "969",
          },
        }}
      >
        <div className="rounded">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>Login</h5>
            <span
              style={{ cursor: "pointer", color: "#CE665B" }}
              onClick={() => dispatch(SetLoginModalOpen(false))}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-4 mx-1">
            <p style={{ fontSize: "10pt", color: "#373B3E" }}>
              Enter Your Mobile Number *
            </p>
          </div>
          <div style={{height:"35px"}}  className="d-flex rounded border mx-1">
            <div className="d-flex justify-content-center align-items-center">
              <span
                className="d-flex justify-content-center align-items-center "
                style={{ borderRight: "1px solid #C4C4C4",height:"35px",width:'35px' ,paddingTop:'2px'}}
              >
                +91
              </span>
            </div>

            <input
              type="text"
              style={errorMassage ? { color: "#000" } : {}}
              placeholder="Mobile Number"
              className="form-control  border-0"
              name="number"
              id=""
              value={number}
              minLength={10}
              maxLength={10}
              pattern="[6-9]{1}[0-9]{9}"
              onChange={(e) => setNumberChange(e)}
            />
          </div>
          <div id="btn" className="mt-4 mx-1">
            {loginbtnLoad ? (
              <>
                <div
                  style={{ backgroundColor: "#e09faf", transition: ".2s" }}
                  className="btn text-white w-100"
                >
                  Login
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={(e) => onSubmitHandler(e)}
                  style={{ backgroundColor: "#B93C5B", transition: ".2s" }}
                  className="btn text-white w-100"
                >
                  Login
                </div>
              </>
            )}
          </div>

          <div className="mt-3">
            <p
              className="text-center justify-content-center d-flex align-items-center"
              style={{ fontSize: "8pt" }}
            >
              <input
                style={{ backgroundColor: "#B93C5B" }}
                type="checkbox"
                name=""
                id=""
              />
              &nbsp; I have read{" "}
              <span
                onClick={() =>
                  navigate("/terms-conditions") ||
                  dispatch(SetLoginModalOpen(false))
                }
                style={{ textDecoration: "underline" ,cursor:"pointer"}}
              >
                &nbsp; Terms and Conditions
              </span>
            </p>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        id="OTP"
        isOpen={Otp}
        onRequestClose={() => setOtp(false)}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "70%"
              : "26%",
            height: "300px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            background: "rgba(0,0,0,0.6)",
            filter: `blur('5px')`,
            zIndex: "96",
          },
        }}
      >
        <div className="rounded">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>OTP</h5>
            <span
              style={{ cursor: "pointer", color: "##CE665B" }}
              onClick={() => setOtp(false)}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-2 mx-1">
            <p>Please Enter OTP</p>
          </div>
          <div className="mt-2 mx-1">
            <p style={{ fontSize: "8pt", color: "#51475E" }}>
              One Time Password(OTP) has been sent to your mobile number{" "}
              {number}, Please the same here to login.
              <span
                style={{ textDecoration: "underline",cursor:"pointer" }}
                className="text-primary"
                onClick={() => {
                  setOtp(false);
                  dispatch(SetLoginModalOpen(true));
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div style={{ marginBottom: "4px" }} className="mt-3 mx-2">
            <input
              className="form-control"
              type="text"
              name=""
              id=""
              maxLength={6}
              placeholder="One Time Password"
              value={otpvalue1}
              onChange={(e) => setOtpChange(e)}
            />
            <div className="mt-3">
            {seconds === 0 ? (
              <span style={{ fontSize: "9pt" }}>
                Did not received the OTP? Retry Via:
              </span>
            ) : (
              <span style={{ fontSize: "9pt" }}>
                Did not received the OTP? Retry In 00:{seconds}s
              </span>
            )}
              <br />
            {seconds === 0 && (
              <span
                id="resend"
                style={{ color: "#B93C5B" ,cursor:"pointer"}}
                onClick={() => resendClick()}
              >
                Resend OTP
              </span>
            )}
            </div>
           
          </div>
          {otpbtnLoad ? (
            <>
              <div className="mx-auto d-flex justify-content-center align-items-center ">
                <button
                  className="btn text-white mx-auto w-50"
                  style={{ backgroundColor: "#e09faf" }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                className="mx-auto d-flex justify-content-center align-items-center "
                onClick={() => verifyClicks()}
              >
                <button
                  className="btn text-white mx-auto w-50"
                  style={{ backgroundColor: "#B93C5B" }}
                >
                  Submit
                </button>
              </div>
            </>
          )}

          {/* <div className="mt-3">
            <p id="changeMobNum" style={{ fontSize: "9pt" }}>
              Change Mobile Number
            </p>
          </div> */}
        </div>
      </ReactModal>

      <ReactModal
        isOpen={register}
        onRequestClose={() => setRegister(false)}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "75%"
              : "26%",
            height: "380px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            background: "rgba(0,0,0,0.6)",
            filter: `blur('5px')`,
            zIndex: "96",
          },
        }}
      >
        <div className="">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>Register</h5>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setRegister(false)}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-3">
            <p style={{ fontWeight: "500" }} className=" mx-1">
              Please Enter your Details
            </p>
          </div>
          <div className="input2 mt-3 mx-1">
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              name="text"
              id=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input3 mt-3 mx-1">
            <input
              type="email"
              placeholder="Enter Email"
              className="form-control"
              name="text"
              id=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <div className="gender px-2 d-flex align-items-center justify-content-between mt-3">
            <span>Gender:</span>
            <span>
              <input
                name="gender"
                type="radio"
                onClick={() => {
                  setGender("Male");
                }}
                checked={gender === "Male"}
              />{" "}
              Male
            </span>
            <span>
              <input
                name="gender"
                type="radio"
                onClick={() => {
                  setGender("Female");
                }}
              />{" "}
              Female
            </span>
          </div> */}
          {/* <div className="input3 mt-3 mx-1">
            <input
              type="text"
              placeholder="Refercode(optional)"
              className="form-control"
              name="text"
              id=""
            />
          </div> */}
          <div className="mt-3">
            <button
              // onClick={() => setOtp(true) || setRegister(false)}
              onClick={(e) => onSubmitRegister(e)}
              style={{ backgroundColor: "#B93C5B" }}
              className="btn w-100 text-white"
            >
              Register
            </button>
          </div>

          <div className="mt-3">
            <p
              className="text-center justify-content-center d-flex align-items-center"
              style={{ fontSize: "8pt" }}
            >
              <input
                style={{ backgroundColor: "#B93C5B" }}
                type="checkbox"
                name=""
                id=""
                onClick={() => setTermCheckbox(!termCheckbox)}
              />
              &nbsp; I have read &nbsp;
              <span style={{ textDecoration: "underline",cursor:"pointer" }}>
                Terms and Conditions
              </span>
            </p>
          </div>
        </div>
      </ReactModal>

      {/*mobile navbar*/}
      <div id="mobnav" className="container-fluid bg-white ">
        <div className="row w-100 mx-auto justify-content-center align-items-center">
          <div className="col-2 col-sm-2 col-md-2">
            <span onClick={() => setmodal2(true)} style={{ color: "#000" }}>
              <VscThreeBars className="fs-2" />
            </span>
          </div>
          <div className="col-7 col-sm-8 col-md-8 d-flex justify-content-center mt-3">
            <div style={{}} className="search mb-3">
              <div
                style={{
                  overflow: "hidden",
                  width: "fit-content",
                  height: "28px",
                }}
                className="d-flex align-items-center border boder-1 border-dark rounded-3"
              >
                <input
                  className="form-control border-0 rounded-0 w-100"
                  placeholder="Search here..."
                  type="search"
                  name=""
                />

                <span
                  className="p-2"
                  style={{
                    backgroundColor: "#000",
                    overflow: "hidden",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <IoSearchOutline
                    style={{ fontSize: "20px", color: "#fff" }}
                  />
                </span>
              </div>
            </div>
            {/* <LocationOn style={{ color: "#DF356F" }} />
                <p id="location" class="search_location">
                  25, Shivaji Marg, Block..
                </p> */}
          </div>

          <div className="col-2 d-flex justify-content-around col-sm-1 col-md-2 ">
            {!isAuth ? (
              <>
                <p
                  onClick={() => dispatch(SetLoginModalOpen(true))}
                  style={{ color: "#000", fontWeight: "lighter" }}
                >
                  <PersonRounded />
                </p>
              </>
            ) : (
              <>
                <p
                  onClick={() => navigate("/profile")}
                  style={{ color: "#000", fontWeight: "lighter" }}
                >
                  <PersonRounded />
                </p>
              </>
            )}

            <div className="">
              {isAuth ? (
                <>
                  <p
                    onClick={() => navigate("/cart")}
                    style={{ color: "#000", fontWeight: "lighter" }}
                  >
                    <Cart4 />
                  </p>
                </>
              ) : (
                <>
                  <p
                    onClick={() => dispatch(SetLoginModalOpen(true))}
                    style={{ color: "#000", fontWeight: "lighter" }}
                  >
                    <Cart4 />
                  </p>
                </>
              )}
            </div>
            <span
              style={{
                backgroundColor: "#000",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
              }}
              className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
            >
              {CartItems.length}
            </span>
          </div>
        </div>

        <div id="modal" style={{ position: "relative" }}>
          <ReactModal
            className="p-2"
            isOpen={modal2}
            onRequestClose={() => setmodal2(false)}
            style={{
              content: {
                width: "80%",
                height: "100%",
                overflow: "auto",
                backgroundColor: "#ffffff",
              },
              overlay: { backgroundColor: "rgba(0,0,0,0)", zIndex: "96" },
            }}
          >
            <div
              style={{
                backgroundColor: "#AA2B57",
                height: "132px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderBottom: "1px",
              }}
            >
              <div
                className="row h-100 text-white w-100 align-items-center mx-auto"
                style={{ backgroundColor: "" }}
              >
                <div className="col-4">
                  <img
                    width="75"
                    style={{ borderRadius: "50%" }}
                    height="75"
                    src="/assets/stlogo1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                {!isAuth ? (
                  <>
                    <div
                      className="col-8"
                      onClick={() =>
                        dispatch(SetLoginModalOpen(true)) && setmodal2(false)
                      }
                    >
                      <h4>Login</h4>
                      <h6>to your Account</h6>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-8">
                      <h4>{loginData.Name}</h4>
                    </div>
                  </>
                )}
              </div>
              <div
                className="mt-3 p-2  "
                style={{ backgroundColor: "#F8F8F8" }}
              >
                {/* <div
                  onClick={() => navigate("/") || setmodal2(false)}
                  className="h6  bg-white p-2 justify-content-start"
                >
                  <span style={{display:'inline-flex',alignItems:"center",justifyContent: "flex-start"}}>
                  <img
                    src="	https://www.yesmadam.com/static/images/home-icon.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="fw-bold mx-1 ">Home</p>
                  </span>
                  
                </div> */}
                <div onClick={() => navigate("/") || setmodal2(false)}>
                  <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                    <img
                      src="https://www.yesmadam.com/static/images/home-icon.svg"
                      style={{
                        color: "#AA2B57",
                        width: "20px",
                        height: "20px",
                        fontWeight: "bolder",
                      }}
                      alt=""
                      className="h3 mt-1"
                    />
                    <p
                      id="menu"
                      className="fw-bold mx-1"
                      style={{ margin: "0" }}
                    >
                      Home
                    </p>
                  </span>
                </div>

                {/* <span className="h6 m-2 bg-white align-items-center p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/franchise-icon.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Franchise</p>
                </span>
                <span className="h6 m-2 bg-white align-items-center p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/hair-and-skin-care.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">
                    Hair &amp; Skin Care Products
                  </p>
                </span>
                <span className="h6 m-2 bg-white align-items-center p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/previous-order.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Previous Orders</p>
                </span>
                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/our-team.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Our Team </p>
                </span>
                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/location.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Our Locations</p>
                </span> */}
                <div
                  onClick={() => {
                    navigate("/privacy-policy") || setmodal2(false);
                  }}
                >
                  <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                    <img
                      src="https://www.yesmadam.com/static/images/policies.svg"
                      style={{
                        color: "#AA2B57",
                        width: "20px",
                        height: "20px",
                        fontWeight: "bolder",
                      }}
                      alt=""
                      className="h3"
                    />
                    <p
                      id="menu"
                      className="mx-1  fw-bold"
                      style={{ margin: "0" }}
                    >
                      Privacy Policy
                    </p>
                  </span>
                </div>
                <div>
                  <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                    <img
                      src="https://www.yesmadam.com/static/images/faq.svg"
                      style={{
                        color: "#AA2B57",
                        width: "20px",
                        height: "20px",
                        fontWeight: "bolder",
                      }}
                      alt=""
                      className="mt-1 h3"
                    />
                    <p
                      id="menu"
                      style={{ margin: "0" }}
                      className="mx-1 fw-bold"
                    >
                      FAQ
                    </p>
                  </span>
                </div>

                <div>
                  <span
                    className="h6 m-2 align-items-center bg-white p-2 d-flex"
                    style={{ marginRight: "auto" }}
                  >
                    <img
                      src="https://www.yesmadam.com/static/images/rate.svg"
                      style={{
                        color: "#AA2B57",
                        width: "20px",
                        height: "20px",
                        fontWeight: "bolder",
                      }}
                      alt=""
                      className="mt-1 h3"
                    />
                    <p id="menu" className="mx-1 fw-bold">
                      Rate Us
                    </p>
                  </span>
                </div>

                {/* <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/blog.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Blog</p>
                </span> */}
                <div>
                  <span
                    style={{ marginRight: "auto" }}
                    className="h6 m-2 align-items-center bg-white p-2 d-flex"
                  >
                    <img
                      src="	https://www.yesmadam.com/static/images/help.svg"
                      style={{
                        color: "#AA2B57",
                        width: "20px",
                        height: "20px",
                        fontWeight: "bolder",
                      }}
                      alt=""
                      className="mt-1 h3"
                    />
                    <p id="menu" className="mx-1 fw-bold">
                      Help & Support
                    </p>
                  </span>
                </div>

                {isAuth ? (
                  <>
                    <div>
                      <span
                        style={{ marginRight: "auto" }}
                        className="h6 m-2 align-items-center bg-white p-2 d-flex"
                      >
                        <span>
                          <PersonRounded
                            style={{
                              color: "#AA2B57",
                              width: "20px",
                              height: "20px",
                              fontWeight: "bolder",
                            }}
                          />
                        </span>
                        <p id="menu" className="mx-1 mt-1 fw-bold">
                          Profile
                        </p>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{ marginRight: "auto" }}
                        className="h6 m-2 align-items-center bg-white p-2 d-flex"
                      >
                        <span>
                          <Cart4
                            style={{
                              color: "#AA2B57",
                              width: "20px",
                              height: "20px",
                              fontWeight: "bolder",
                            }}
                          />
                        </span>
                        <p id="menu" className="mx-1 mt-1 fw-bold">
                          Checkout
                        </p>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{ marginRight: "auto" }}
                        className="h6 m-2 align-items-center bg-white p-2 d-flex"
                      >
                        <span>
                          <IoLogOut
                            style={{
                              color: "#AA2B57",
                              width: "20px",
                              height: "20px",
                              fontWeight: "bolder",
                            }}
                          />
                        </span>
                        <p id="menu" className="mx-1 mt-1 fw-bold">
                          Logout
                        </p>
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
