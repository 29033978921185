import React from "react";
import SubcatPageDesk from "./SubcategoryPage/SubcatPageDesk";
import "../Desktop.css";
import Waxing from "./waxing";
const SubcategoryPage = () => {
  return (
    <>
      <div className="desktop-home">
        <SubcatPageDesk />
      </div>
      <div className="mob-home">
        <Waxing />
      </div>
    </>
  );
};

export default SubcategoryPage;
