import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  superCategorytotal: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal"))
    : [],
  isSuperCategoryAvailable: localStorage.getItem("superCatTotal")
    ? true
    : false,
  superCategoryLoading: true,
};
   
export const getSuperCategory = createAsyncThunk(
  "superCategory/getSuperCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/supercategory/all`;
      const resp = await axios(url);
      return resp.data.supercategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("Category Not Found");
    }
  }
);

const superCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSuperCategory.pending, (state) => {
        state.superCategoryLoading = true;
      })
      .addCase(getSuperCategory.fulfilled, (state, action) => {
        state.superCategorytotal = action.payload;
        localStorage.setItem(
          "superCatTotal",
          JSON.stringify(state.superCategorytotal)
        );
        state.isSuperCategoryAvailable = true;
        state.superCategoryLoading = false;
      })
      .addCase(getSuperCategory.rejected, (state) => {
        state.superCategoryLoading = true;
      });
  },
});
export const {} = superCategorySlice.actions;
export default superCategorySlice.reducer;
