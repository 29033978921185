import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  categorytotal: [],
  menAndWomenCategory: [],
  animalCateory: [],
  productCateory: [],
  isCategoryAvailable: localStorage.getItem("categorytotal") ? true : false,
  categoryLoading: true,
};

export const getCategory = createAsyncThunk(
  "Category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios(url);
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("Category Not Found");
    }
  }
);

const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categorytotal = action.payload;
        state.menAndWomenCategory = state.categorytotal.filter(
          (cat) => cat.superCategoryId === "665ee4ae0231e8883837d03a"
        );
        state.animalCateory = state.categorytotal.filter(
          (cat) => cat.superCategoryId === "665ee4ca0231e8883837d045"
        );
        state.productCateory = state.categorytotal.filter(
          (cat) => cat.superCategoryId === "665ee4f10231e8883837d05c"
        );
        localStorage.setItem(
          "categorytotal",
          JSON.stringify(state.categorytotal)
        );
        state.isCategoryAvailable = true;
        state.categoryLoading = false;
      })
      .addCase(getCategory.rejected, (state) => {
        state.categoryLoading = false;
      });
  },
});
export const {} = CategorySlice.actions;
export default CategorySlice.reducer;
