import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { setChoosedCategory, setClickedCategory } from "../../redux/saloon/SaloonSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const SalonSlider = (props) => {
    const { CartItems, cartTotalAmount } = useSelector((store) => store.Cart);
    const { clickedSaloon, clickedCategory, saloonList,categoryChoosedBySearch } = useSelector((store) => store.saloon);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCat, setSelectedCat] = useState('')
    const [catBySaloon, setCatBySaloon] = useState('')
    const [servicesByCat, setServicesByCat] = useState('')
    const targetDivRef = useRef(null);
    const [showProducts, setShowProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoryName, setSubCategoryName] = useState("");
    const [defaultSubcat, setDefaultSubcat] = useState("");
    const [selectedTag, setselectedTag] = useState("");

    useEffect(() => {
        if (clickedSaloon) {
            const getCategories = async () => {
                const url = `${Baseurl}/api/v1/saloonCategory/categorybysupercatid/${clickedSaloon}`
                const resp = await axios.get(url);
                if (resp.data.success) {
                    setCatBySaloon(resp.data.category)
                    const categories=resp.data.category
                    const selectedCategory=categories.findIndex((indCategory)=>
                        indCategory._id===categoryChoosedBySearch
                    )
                    if (selectedCategory >=0) {
                        setSelectedCat(categories[selectedCategory]);
                        
                    await dispatch(setChoosedCategory(resp.data.category[selectedCategory]))
                    await dispatch(setClickedCategory(resp.data.category[selectedCategory]._id))
                    }
                }
            }
            getCategories()
        }
    }, [clickedSaloon])

    useEffect(() => {
        if (clickedCategory) {
            const getCategories = async () => {
                const url = `${Baseurl}/api/v1/saloonServices/servicesbycatid/${clickedCategory}`
                console.log(url, 'url');
                const resp = await axios.get(url);
                if (resp.data.success) {
                    console.log(resp.data.services, 'resp.data.services');
                    const services = resp.data.services;
                    let productbySubcat = services.map(
                        (product) => (product = { ...product, cartQuantity: 0 })
                    );
                    console.log(productbySubcat, 'productbySubcat');
                    CartItems.forEach((cartitem) => {
                        let itemIndex = -1;
                        itemIndex = productbySubcat.findIndex(
                            (product) => product._id === cartitem.ProductId
                        );
                        if (itemIndex >= 0) {
                            productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
                        }
                    });
                    setServicesByCat(productbySubcat)
                }
            }
            getCategories()
        }
    }, [clickedCategory])

    const handleSaloonChange = async (subcat) => {
        setSelectedCat(subcat)
        await dispatch(setClickedCategory(subcat._id))
        await dispatch(setChoosedCategory(subcat))
    }

    useEffect(() => {
        setSubCategories(props.subcat);
        setTags(props.cattag);
        setSubCategoryName(props.subCategoryName);
    }, [
        props.subcat,
        props.cattag,
        props.subCategoryName,
        props.defaultSubcat,
        props.selectedTag,
    ]);
    const changeCatClick = (indSubCat) => {
        setDefaultSubcat(indSubCat);
        props.getSelectedSubcat(indSubCat);
    };
    const changeProduct = (indSubCat) => {
        setselectedTag(indSubCat);
        props.getSelectedTag(indSubCat);
    };

    const Setting = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        autoplay: false,
    };
    const mobileSettings = {
        speed: 500,
        slidesToShow: 6,
        dots: false,
        infinite: false,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
    };
    let sliderSettings = Setting;

    if (window.matchMedia("(min-width:768px)").matches) {
        sliderSettings = mobileSettings;
    }

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="container-fluid-lg h-100">
            <div className="container-fluid-lg shadow bg-white w-100 d-flex align-items-center">
                <div onClick={goBack}>
                    <h2 style={{ color: "#DD638C" }}>
                        <IoIosArrowBack className="fw-bolder" />
                    </h2>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mx-auto">
                    <h4
                        style={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "lighter",
                            fontFamily: "cursive",
                            marginTop: "6px",
                        }}
                        className="text-center fw-bold"
                    >
                        {saloonName}
                    </h4>
                </div> */}
            </div>

            <div
                className="container-fluid-lg mt-2"
                style={{ marginBottom: "-20px" }}
            >
                <div id="media1" className="row mx-auto w-100">
                    <Slider {...Setting} {...sliderSettings}>
                        {catBySaloon &&
                            catBySaloon.map((indCat, indexCat) => (
                                <div className=" col-lg-2 col-4" key={indexCat}>
                                    <div className="test " onClick={() => handleSaloonChange(indCat)}>
                                        <img
                                            className={
                                                selectedCat.slugUrl === indCat.slugUrl
                                                    ? "img-fluid mx-auto image_container"
                                                    : "img-fluid mx-auto "
                                            }
                                            src={indCat.desktopImage}
                                            alt="Services"
                                        />
                                        <p
                                            className={
                                                selectedCat.slugUrl === indCat.slugUrl
                                                    ? "text-center1 image_container1"
                                                    : "text-center1  "
                                            }
                                        >
                                            {indCat.name}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default SalonSlider;
