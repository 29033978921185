import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetAddressModalOpen,
  currAdress,
  updateAddress,
} from "../redux/authentication/AuthenticationSlice";
import {
  CheckOutlined,
  Close,
  LocationOn,
  PersonRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";

const EditAddressModel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginData, isAuth, currentAddress, isAddressModalOpen } = useSelector(
    (store) => store.Authentication
  );
  const [buildingName, setBuildingName] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setpincode] = useState("");
  const [state, setstate] = useState("");
  const [streetDetails, setStreetdeatails] = useState("");
  const [landmark, setlandmark] = useState("");

  const handleAddress = async () => {
    const formData = {
      AName: loginData.Name,
      Number: loginData.Mobile,
      Pincode: pincode,
      City: location,
      State: state,
      HNo: buildingName,
      StreetDet: streetDetails,
      LandMark: landmark,
      AreaDet: "",
      Address: address,
      Type: "",
      Mobile: loginData.Mobile,
      Tag: "",
      ClientId: loginData._id,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    try {
      const addAddress = await axios.put(
        `${Baseurl}/api/v1/client/address`,
        formData,
        config
      );
      if (addAddress.data.success) {
        const currentAddress = addAddress.data.client.Addresses;
        dispatch(updateAddress(addAddress.data.client.Addresses));
        dispatch(currAdress(addAddress.data.client.Addresses[0]));
        dispatch(SetAddressModalOpen(false));
      }
    } catch (error) {}
  };
  console.log(loginData);
  return (
    <>
      <div className="Adprofile rounded" style={{ width: "100%" }}>
        <div
          style={{ borderBottom: "1px outset #E2E2E2" }}
          className="d-flex  justify-content-between"
        >
          <h6
            style={{
              color: "#CE665B",
              marginTop: "8px",
              marginLeft: "20px",
              fontWeight: "700",
            }}
          >
            Add Address
          </h6>
          <span
            style={{ cursor: "pointer", color: "#CE665B" }}
            onClick={() => dispatch(SetAddressModalOpen(false))}
          >
            <Close style={{ color: "#CE665B" }} />
          </span>
        </div>
        <div className="mt-3">
          <div>
            <div className="adressmodal w-100">
              <div className="buildingName d-flex align-items-center w-100">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  Building Name*
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter building name"
                  className="form-control w-75 m-1"
                  onChange={(e) => setBuildingName(e.target.value)}
                />
              </div>
              <div className="address d-flex align-items-center mt-2 w-100">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  Address*
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  className="form-control w-75 m-1"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="landmark w-100 d-flex align-items-center mt-2">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  landmark*
                </label>
                <input
                  type="text"
                  name="landmark"
                  placeholder="Enter landmark"
                  className="form-control w-75 m-1"
                  onChange={(e) => setlandmark(e.target.value)}
                />
              </div>
              <div className="street d-flex align-items-center w-100 mt-2">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  Street Details*
                </label>
                <input
                  type="text"
                  name="streetdetails"
                  placeholder="Enter street"
                  className="form-control w-75 m-1"
                  onChange={(e) => setStreetdeatails(e.target.value)}
                />
              </div>

              <div className="location d-flex align-items-center w-100 mt-2">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  Location*
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Search Location"
                  className="form-control w-75 m-1"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="pincode d-flex align-items-center w-100 mt-2">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  Pincode*
                </label>
                <input
                  type="text"
                  name="pincode"
                  placeholder="Enter Pincode"
                  className="form-control m-1 w-75"
                  onChange={(e) => setpincode(e.target.value)}
                />
              </div>
              <div className="state d-flex align-items-center mt-2 w-100">
                <label
                  htmlFor="nameBuilding"
                  style={{ textAlign: "start" }}
                  className="w-25"
                >
                  State*
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Enter your State"
                  className="form-control w-75 m-1"
                  onChange={(e) => setstate(e.target.value)}
                />
              </div>
            </div>

            <div className="save mt-3">
              <button
                style={{ backgroundColor: "#FF2E61" }}
                className="btn text-white w-75 m-2"
                onClick={() => handleAddress()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddressModel;
