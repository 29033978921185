import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  tagtotal: [],
  productByCatTag:'',
  productByCatTagLoading:'',
  isTagAvailable: localStorage.getItem("tagtotal") ? true : false,
  tagLoading: true,
};

export const getTag = createAsyncThunk("Tag/getTag", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/categorytag/all`;
    const resp = await axios(url);
    return resp.data.ctegoryTags;
  } catch (error) {
    return thunkAPI.rejectWithValue("Tags Not Found");
  }
});

const TagSlice = createSlice({
  name: "Tag",
  initialState,
  reducers: {
    setProductByCatTag: (state, action) => {
      state.productByCatTag = action.payload;
      state.productByCatTagLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTag.pending, (state) => {
        state.tagLoading = true;
      })
      .addCase(getTag.fulfilled, (state, action) => {
        state.tagtotal = action.payload;
        localStorage.setItem("tagtotal", JSON.stringify(state.tagtotal));
        state.isTagAvailable = true;
        state.tagLoading = false;
      })
      .addCase(getTag.rejected, (state) => {
        state.tagLoading = true;
      });
  },
});
export const {setProductByCatTag} = TagSlice.actions;
export default TagSlice.reducer;
