import React from "react";
import { IoClose } from "react-icons/io5";

function FailurePayment() {
  return (
    <div className="fail-container">
      <div>
        <div className="fail">
          <h1>
            <IoClose style={{ fontSize: "100px" }} />
          </h1>
        </div>
        <div className="mt-2">
          <h3 className="text-center">Payment Failed</h3>
        </div>
        <div className="mt-1">
          <p className="text-center">
            Your payment was not successfully processed.Please contact our
            customer support.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FailurePayment;
