import React from "react";
import "../../Desktop.css";
import ServiceList from "./ServiceList";
import CatBySaloon from "./CatBySaloon";
import SearchCatSearch from "./SearchCatSearch";
import SearchMobCat from "./SearchMobCat";

const SaloonSearchPage = () => {

  return (
    <>
      <div className="desktop-home">
        <SearchCatSearch />
      </div>
      <div className="mob-home">
        <SearchMobCat />
      </div>
    </>
  );
};

export default SaloonSearchPage;
