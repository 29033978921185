import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../src/config/BaseUrl";
import { Info } from "@mui/icons-material";
import { Cart2 } from "react-bootstrap-icons";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  removefromCart,
  addtoCart,
  decrementCart,
  getCartTotal,
} from "../redux/cart/CartSlice";
import {
  removefromShopCart,
  addtoShopCart,
  decrementShopCart,
  getShopCartTotal,
} from "../redux/cart/ShopCartSlice";
import {
  removefromHomeSalonCart,
  addtoHomeSalonCart,
  decrementHomeSalonCart,
  getHomeSalonCartTotal,
} from "../redux/cart/HomeSalon";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { Tabs } from "antd";

const CartPage = () => {
  const { CartItems, netPayable } = useSelector((store) => store.Cart);
  const { ShopCartItems, shopCartNetPayable } = useSelector(
    (store) => store.ShopCart
  );
  const { HomeSalonCartItems, homeSalonCartNetPayable } = useSelector(
    (store) => store.HomeSalonCart
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [salonList, setSalonList] = useState([]);

  const RemoveSingle = (item) => {
    dispatch(removefromCart(item));
    dispatch(getCartTotal());
    toast.error("Item removed from your cart");
  };
  const RemoveSingleShopItem = (item) => {
    dispatch(removefromShopCart(item));
    dispatch(getShopCartTotal());
    toast.error("Item removed from your Shop cart");
  };
  const RemoveSingleHomeSalonItem = (item) => {
    dispatch(removefromHomeSalonCart(item));
    dispatch(getHomeSalonCartTotal());
    toast.error("Item removed from your Home Saloon cart");
  };
  const HandleIncrement = (product) => {
    dispatch(addtoCart(product));
    dispatch(getCartTotal());
  };
  const HandleShopCartIncrement = (product) => {
    dispatch(addtoShopCart(product));
    dispatch(getShopCartTotal());
  };
  const HandleHomeSaloonIncrement = (product) => {
    dispatch(addtoShopCart(product));
    dispatch(getHomeSalonCartTotal());
  };
  const HandleDecrement = (product) => {
    dispatch(decrementCart(product));
    dispatch(getCartTotal());
  };
  const HandleShopCartDecrement = (product) => {
    dispatch(decrementShopCart(product));
    dispatch(getShopCartTotal());
  };
  const HandleHomeSaloonDecrement = (product) => {
    dispatch(decrementHomeSalonCart(product));
    dispatch(getHomeSalonCartTotal());
  };
  useEffect(() => {
    const getSaloonList = async () => {
      const url = `${Baseurl}/api/v1/saloon/saloonfor/Female`;
      const response = await axios.get(url);
      if (response.data.success) {
        console.log(response.data.saloon[0]);
        setSalonList(response.data.saloon);
      }
    };
    getSaloonList();
  }, []);
  const { TabPane } = Tabs;
  const handleTabChange = (key) => {
    console.log(`Selected Tab: ${key}`);
  };
  const handleCheckOut = async () => {
    const state = { id: 1, name: "Shop" };
    navigate("/checkout", { state });
  };
  const handleProduct = async () => {
    const state = { id: 1, name: "Product" };
    navigate("/product-checkout");
  };
  const handleHomeSalon = async () => {
    const state = { id: 1, name: "HomeSaloon" };
    navigate("/checkout", { state });
  };
  return (
    <div id="checkout" className="container-fluid-lg">
      <Tabs
        defaultActiveKey="1"
        onChange={handleTabChange}
        className="mainTabs"
        tabBarGutter={440}
        size="large"
        tabBarStyle={{ backgroundColor: "#e6f7ff" }}
      >
        <TabPane tab={`Product Cart (${CartItems.length})`} key="1">
          <div className="container-fluid-lg  bg-white">
            <div id="sam" className="conatainer-fuluid-lg">
              <div className="container-fluid-lg heading shadow w-100  bg-white w-100 d-flex align-items-center">
                <div onClick={() => navigate("/")}>
                  <h2 style={{ color: "#DD638C" }}>
                    <IoIosArrowBack className="fw-bolder" />
                  </h2>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mx-auto">
                  <h4 className="text-center fw-bold">
                    <span style={{ fontSize: "18px" }}>Product</span>{" "}
                    <span style={{ fontSize: "18px" }}>Cart</span>
                  </h4>
                </div> */}
              </div>
            </div>
            <div
              style={{ columnGap: "30px" }}
              className="row justify-content-center w-100 mx-auto"
            >
              <div className="col-lg-4 col-12">
                <div>
                  <h3 id="checkoutpageheading" className="mx-2 mt-2">
                    <span style={{ borderBottom: "2px inset #C93C5B" }}>
                      Product
                    </span>{" "}
                    <span>Cart</span>
                  </h3>
                </div>
                <div></div>
                <div className="d-flex justify-content-center">
                  <div
                    id="totalcheckout"
                    className="card p-2 w-100 h-50 shadow"
                  >
                    <div className="row w-100 align-items-center mx-auto">
                      <div className="col-lg-6 col-6 mx-auto">
                        <div className="container">
                          <p className="text-center">
                            Total Amount: ₹{netPayable}
                            /-
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-6 mx-auto">
                        <div
                          style={{
                            backgroundColor: "#FD8B8C",
                            border: "1px inside white",
                            width: "6rem",
                          }}
                          className="card mx-auto rounded"
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src="https://cdn.yesmadam.com/images/78_1.webp"
                              height="50px"
                              width="50px"
                              alt=""
                            />
                          </div>
                          <p
                            style={{ fontSize: "5.5pt", fontWeight: "500" }}
                            className="text-center text-white"
                          >
                            STANDARD
                          </p>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="mt-2">
                          <p
                            className="text-center justify-content-center d-flex align-items-center"
                            style={{ fontSize: "8pt" }}
                          >
                            <input
                              style={{ backgroundColor: "#B93C5B" }}
                              type="checkbox"
                              name=""
                              id=""
                            />
                            &nbsp; I have read{" "}
                            <span style={{ textDecoration: "underline" }}>
                              &nbsp; Terms and Conditions
                            </span>
                          </p>
                        </div>
                        <div className="mx-auto">
                          <button
                            onClick={() => handleProduct()}
                            style={{ backgroundColor: "#c8386b" }}
                            className="btn text-white"
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="cartchange" className="col-lg-8 col-12 ">
                <div>
                  {CartItems.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <table className="table cart-table mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <div className="cart-empty">
                                <h1 className="text-center">
                                  <Cart2
                                    style={{
                                      fontSize: "250px",
                                      fontWeight: "200",
                                    }}
                                  />
                                </h1>
                                <p className="text-center fw-bold fs-3">
                                  Your Cart is Empty
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    CartItems &&
                    CartItems.map((product, index) => (
                      <div key={index}>
                        <div
                          className={`container-fluid-lg bg-white ${
                            index === CartItems.length - 1 ? "mb-5 pb-2" : ""
                          }`}
                        >
                          <div
                            id="subcard"
                            className="mt-3 d-flex justify-content-center"
                          >
                            <div className="card w-100 d-flex justify-content-between align-items-center p-2 w-100 h-50 shadow">
                              <div
                                style={{ height: "180px" }}
                                className="row w-100 mx-auto"
                              >
                                <div className="col-lg-6 col-6 mx-auto">
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "#c8386b",
                                        fontSize: "24px",
                                      }}
                                    ></p>

                                    <h6 className="checkoutName">
                                      {product.ProductName.length > 20
                                        ? `${product.ProductName.slice(
                                            0,
                                            24
                                          )}...`
                                        : product.ProductName}
                                    </h6>
                                    <div className="mb-5 w-100">
                                      <ul style={{ width: "100%" }}>
                                        {product.Services.slice(0, 2).map(
                                          (servix, indexServix) => (
                                            <li
                                              style={{ width: "400px" }}
                                              className=""
                                              key={indexServix}
                                            >
                                              {servix.service.length > 30
                                                ? `${servix.service.slice(
                                                    0,
                                                    30
                                                  )}...`
                                                : servix.service}
                                            </li>
                                          )
                                        )}
                                      </ul>

                                      <div className="checkoutRupee">
                                        <p className="cardrupee">
                                          ₹ {product.Price}/- &nbsp;{" "}
                                          {product.Discount} off&nbsp;{" "}
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              color: "#99A5A7",
                                            }}
                                          >
                                            ₹{product.Mrp}
                                          </span>{" "}
                                          &nbsp; Time{" "}
                                          {product.Duration.length > 25
                                            ? `${product.Duration.slice(
                                                0,
                                                26
                                              )}...`
                                            : product.Duration}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="remove">
                                    <p
                                      onClick={() => RemoveSingle(product)}
                                      style={{
                                        color: "#B93C5B",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      REMOVE
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-6 ">
                                  <div className="d-flex justify-content-end">
                                    <img
                                      id="subimg"
                                      src={product.ImgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-end w-100">
                                    <div className="">
                                      <div>
                                        <div
                                          className="text-dark mx-auto w-100 rounded d-flex justify-content-between align-items-center p-1"
                                          style={{ backgroundColor: "" }}
                                        >
                                          <h6>
                                            Price: ₹{product.TotalAmount}/
                                          </h6>
                                        </div>
                                      </div>
                                      <div
                                        id="incdec"
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#c8386b",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            HandleDecrement(product)
                                          }
                                        >
                                          <FaMinus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                        <span className="">{product.Qty}</span>
                                        <span
                                          onClick={() =>
                                            HandleIncrement(product)
                                          }
                                        >
                                          <FaPlus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={`Shop Cart (${ShopCartItems.length})`} key="2">
          <div className="container-fluid-lg  bg-white">
            <div id="sam" className="conatainer-fuluid-lg">
              <div className="container-fluid-lg heading shadow w-100  bg-white w-100 d-flex align-items-center">
                <div onClick={() => navigate("/")}>
                  <h2 style={{ color: "#DD638C" }}>
                    <IoIosArrowBack className="fw-bolder" />
                  </h2>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mx-auto">
                  <h4 className="text-center fw-bold">
                    <span style={{ fontSize: "18px" }}>Service</span>{" "}
                    <span style={{ fontSize: "18px" }}>Cart</span>
                  </h4>
                </div> */}
              </div>
            </div>
            <div
              style={{ columnGap: "30px" }}
              className="row justify-content-center w-100 mx-auto"
            >
              <div className="col-lg-4 col-12">
                <div>
                  <h3 id="checkoutpageheading" className="mx-2 mt-2">
                    <span style={{ borderBottom: "2px inset #C93C5B" }}>
                      Shop
                    </span>{" "}
                    <span>Cart</span>
                  </h3>
                </div>
                <div></div>
                <div className="d-flex justify-content-center">
                  <div
                    id="totalcheckout"
                    className="card p-2 w-100 h-50 shadow"
                  >
                    <div className="row w-100 align-items-center mx-auto">
                      <div className="col-lg-6 col-6 mx-auto">
                        <div className="container">
                          <p className="text-center">
                            Total Amount: ₹{shopCartNetPayable}
                            /-
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-6 mx-auto">
                        <div
                          style={{
                            backgroundColor: "#FD8B8C",
                            border: "1px inside white",
                            width: "6rem",
                          }}
                          className="card mx-auto rounded"
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src="https://cdn.yesmadam.com/images/78_1.webp"
                              height="50px"
                              width="50px"
                              alt=""
                            />
                          </div>
                          <p
                            style={{ fontSize: "5.5pt", fontWeight: "500" }}
                            className="text-center text-white"
                          >
                            STANDARD
                          </p>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="mt-2">
                          <p
                            className="text-center justify-content-center d-flex align-items-center"
                            style={{ fontSize: "8pt" }}
                          >
                            <input
                              style={{ backgroundColor: "#B93C5B" }}
                              type="checkbox"
                              name=""
                              id=""
                            />
                            &nbsp; I have read{" "}
                            <span style={{ textDecoration: "underline" }}>
                              &nbsp; Terms and Conditions
                            </span>
                          </p>
                        </div>
                        <div className="mx-auto">
                          <button
                            onClick={() => handleCheckOut()}
                            style={{ backgroundColor: "#c8386b" }}
                            className="btn text-white"
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="cartchange" className="col-lg-8 col-12 ">
                <div>
                  {ShopCartItems.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <table className="table cart-table mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <div className="cart-empty">
                                <h1 className="text-center">
                                  <Cart2
                                    style={{
                                      fontSize: "250px",
                                      fontWeight: "200",
                                    }}
                                  />
                                </h1>
                                <p className="text-center fw-bold fs-3">
                                  Your Cart is Empty
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ShopCartItems &&
                    ShopCartItems.map((product, index) => (
                      <div key={index}>
                        <div
                          className={`container-fluid-lg bg-white ${
                            index === ShopCartItems.length - 1
                              ? "mb-5 pb-2"
                              : ""
                          }`}
                        >
                          <div
                            id="subcard"
                            className="mt-3 d-flex justify-content-center"
                          >
                            <div className="card w-100 d-flex justify-content-between align-items-center p-2 w-100 h-50 shadow">
                              <div
                                style={{ height: "180px" }}
                                className="row w-100 mx-auto"
                              >
                                <div className="col-lg-6 col-6 mx-auto">
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "#c8386b",
                                        fontSize: "24px",
                                      }}
                                    ></p>

                                    <h6 className="checkoutName">
                                      {product.ProductName.length > 30
                                        ? `${product.ProductName.slice(
                                            0,
                                            26
                                          )}...`
                                        : product.ProductName}
                                    </h6>
                                    <div className="mb-5 w-100">
                                      <ul style={{ width: "100%" }}>
                                        {product.Services.slice(0, 2).map(
                                          (servix, indexServix) => (
                                            <li
                                              style={{ width: "400px" }}
                                              className=""
                                              key={indexServix}
                                            >
                                              {servix.service.length > 30
                                                ? `${servix.service.slice(
                                                    0,
                                                    30
                                                  )}...`
                                                : servix.service}
                                            </li>
                                          )
                                        )}
                                      </ul>

                                      <div className="checkoutRupee">
                                        <p className="cardrupee">
                                          ₹ {product.Price}/- &nbsp;{" "}
                                          {product.Discount} off&nbsp;{" "}
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              color: "#99A5A7",
                                            }}
                                          >
                                            ₹{product.Mrp}
                                          </span>{" "}
                                          &nbsp; Time {product.Duration}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="remove">
                                    <p
                                      onClick={() =>
                                        RemoveSingleShopItem(product)
                                      }
                                      style={{
                                        color: "#B93C5B",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      REMOVE
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-6 ">
                                  <div className="d-flex justify-content-end">
                                    <img
                                      id="subimg"
                                      src={product.ImgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-end w-100">
                                    <div className="">
                                      <div>
                                        <div
                                          className="text-dark mx-auto w-100 rounded d-flex justify-content-between align-items-center p-1"
                                          style={{ backgroundColor: "" }}
                                        >
                                          <h6>
                                            Price: ₹{product.TotalAmount}/
                                          </h6>
                                        </div>
                                      </div>
                                      <div
                                        id="incdec"
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#c8386b",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            HandleShopCartDecrement(product)
                                          }
                                        >
                                          <FaMinus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                        <span className="">{product.Qty}</span>
                                        <span
                                          onClick={() =>
                                            HandleShopCartIncrement(product)
                                          }
                                        >
                                          <FaPlus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={`Home Salon Cart (${HomeSalonCartItems.length})`} key="3">
          <div className="container-fluid-lg  bg-white">
            <div id="sam" className="conatainer-fuluid-lg">
              <div className="container-fluid-lg heading shadow w-100  bg-white w-100 d-flex align-items-center">
                <div onClick={() => navigate("/")}>
                  <h2 style={{ color: "#DD638C" }}>
                    <IoIosArrowBack className="fw-bolder" />
                  </h2>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mx-auto">
                  <h4 className="text-center fw-bold">
                    <span style={{ fontSize: "18px" }}>Home Saloon</span>{" "}
                    <span style={{ fontSize: "18px" }}>Cart</span>
                  </h4>
                </div> */}
              </div>
            </div>
            <div
              style={{ columnGap: "30px" }}
              className="row justify-content-center w-100 mx-auto"
            >
              <div className="col-lg-4 col-12">
                <div>
                  <h3 id="checkoutpageheading" className="mx-2 mt-2">
                    <span style={{ borderBottom: "2px inset #C93C5B" }}>
                      Home Saloon
                    </span>{" "}
                    <span>Cart</span>
                  </h3>
                </div>
                <div></div>
                <div className="d-flex justify-content-center">
                  <div
                    id="totalcheckout"
                    className="card p-2 w-100 h-50 shadow"
                  >
                    <div className="row w-100 align-items-center mx-auto">
                      <div className="col-lg-6 col-6 mx-auto">
                        <div className="container">
                          <p className="text-center">
                            Total Amount: ₹{homeSalonCartNetPayable}
                            /-
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-6 mx-auto">
                        <div
                          style={{
                            backgroundColor: "#FD8B8C",
                            border: "1px inside white",
                            width: "6rem",
                          }}
                          className="card mx-auto rounded"
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src="https://cdn.yesmadam.com/images/78_1.webp"
                              height="50px"
                              width="50px"
                              alt=""
                            />
                          </div>
                          <p
                            style={{ fontSize: "5.5pt", fontWeight: "500" }}
                            className="text-center text-white"
                          >
                            STANDARD
                          </p>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="mt-2">
                          <p
                            className="text-center justify-content-center d-flex align-items-center"
                            style={{ fontSize: "8pt" }}
                          >
                            <input
                              style={{ backgroundColor: "#B93C5B" }}
                              type="checkbox"
                              name=""
                              id=""
                            />
                            &nbsp; I have read{" "}
                            <span style={{ textDecoration: "underline" }}>
                              &nbsp; Terms and Conditions
                            </span>
                          </p>
                        </div>
                        <div className="mx-auto">
                          <button
                            onClick={() => handleHomeSalon()}
                            style={{ backgroundColor: "#c8386b" }}
                            className="btn text-white"
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="cartchange" className="col-lg-8 col-12 ">
                <div>
                  {HomeSalonCartItems.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <table className="table cart-table mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <div className="cart-empty">
                                <h1 className="text-center">
                                  <Cart2
                                    style={{
                                      fontSize: "250px",
                                      fontWeight: "200",
                                    }}
                                  />
                                </h1>
                                <p className="text-center fw-bold fs-3">
                                  Your Cart is Empty
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    HomeSalonCartItems &&
                    HomeSalonCartItems.map((product, index) => (
                      <div key={index}>
                        <div
                          className={`container-fluid-lg bg-white ${
                            index === HomeSalonCartItems.length - 1
                              ? "mb-5 pb-2"
                              : ""
                          }`}
                        >
                          <div
                            id="subcard"
                            className="mt-3 d-flex justify-content-center"
                          >
                            <div className="card w-100 d-flex justify-content-between align-items-center p-2 w-100 h-50 shadow">
                              <div
                                style={{ height: "180px" }}
                                className="row w-100 mx-auto"
                              >
                                <div className="col-lg-6 col-6 mx-auto">
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        color: "#c8386b",
                                        fontSize: "24px",
                                      }}
                                    ></p>

                                    <h6 className="checkoutName">
                                      {product.ProductName.length > 25
                                        ? `${product.ProductName.slice(
                                            0,
                                            24
                                          )}...`
                                        : product.ProductName}
                                    </h6>
                                    <div className="mb-5 w-100">
                                      <ul style={{ width: "100%" }}>
                                        {product.Services.slice(0, 2).map(
                                          (servix, indexServix) => (
                                            <li
                                              style={{ width: "400px" }}
                                              className=""
                                              key={indexServix}
                                            >
                                              {servix.service.length > 30
                                                ? `${servix.service.slice(
                                                    0,
                                                    30
                                                  )}...`
                                                : servix.service}
                                            </li>
                                          )
                                        )}
                                      </ul>

                                      <div className="checkoutRupee">
                                        <p className="cardrupee">
                                          ₹ {product.Price}/- &nbsp;{" "}
                                          {product.Discount} off&nbsp;{" "}
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              color: "#99A5A7",
                                            }}
                                          >
                                            ₹{product.Mrp}
                                          </span>{" "}
                                          &nbsp; Time {product.Duration}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="remove">
                                    <p
                                      onClick={() =>
                                        RemoveSingleHomeSalonItem(product)
                                      }
                                      style={{
                                        color: "#B93C5B",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      REMOVE
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-6 ">
                                  <div className="d-flex justify-content-end">
                                    <img
                                      id="subimg"
                                      src={product.ImgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-end w-100">
                                    <div className="">
                                      <div>
                                        <div
                                          className="text-dark mx-auto w-100 rounded d-flex justify-content-between align-items-center p-1"
                                          style={{ backgroundColor: "" }}
                                        >
                                          <h6>
                                            Price: ₹{product.TotalAmount}/
                                          </h6>
                                        </div>
                                      </div>
                                      <div
                                        id="incdec"
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#c8386b",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            HandleHomeSaloonDecrement(product)
                                          }
                                        >
                                          <FaMinus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                        <span className="">{product.Qty}</span>
                                        <span
                                          onClick={() =>
                                            HandleHomeSaloonIncrement(product)
                                          }
                                        >
                                          <FaPlus
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CartPage;
