import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Homebanner from "./saloonhome/Homebanner";
import AffordableHome from "./saloonhome/AffordableHome";
import WhyChooseSaloon from "./saloonhome/WhyChooseSaloon";
import BeautySlider from "./saloonhome/BeautySlider.jsx";
import DownloadViaSMS from "./saloonhome/DownloadViaSMS";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "react-bootstrap";
import Description from "./saloonhome/Description";
import BeautySlider1 from "./saloonhome/BeautySlider1.jsx";
import MobileHeader from "../components/layout/MobileHeader.jsx";
import MobileFooter from "../components/layout/MobileFooter.jsx";
import AnimalCatsection from "./saloonhome/AnimalCatsection.jsx";
import CardComponent from "./saloonhome/CardComponent.jsx";
import AvailableSalon from "./saloonhome/AvailableSalonMale.jsx";
import AvailableSalonMale from "./saloonhome/AvailableSalonMale.jsx";
import AvailableSalonFemale from "./saloonhome/AvailableSalonFemale.jsx";
import AvailableSalonPet from "./saloonhome/AffordableSalonPet.jsx";
import AvailableSalonUnisex from "./saloonhome/AvailableSalonUnisex.jsx";

export function Saloonhome() {
  const [modal, setmodal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <MobileHeader />
      <div id="saloonHome" className="container-fluid-lg">
        <Homebanner />
        <div className="container mt-3">
          <AvailableSalonFemale />
          <AvailableSalonMale />
          {/* <AvailableSalonUnisex /> */}
          <AvailableSalonPet />
          <AffordableHome />
          <BeautySlider1 />
          <WhyChooseSaloon />
          <AnimalCatsection />
          <CardComponent />
          {/* <div id="add" className="">
            <div>
              <img
                src="https://www.yesmadam.com/static/images/banner.webp"
                alt=""
                className="img-fluid"
              />
            </div>
          </div> */}
          {/* <BeautySlider /> */}
        </div>
        {/* <DownloadViaSMS /> */}
        {/* <Description /> */}
      </div>
      {/* <MobileFooter /> */}
    </>
  );
}
