import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { act } from "react";

const initialState = {
  productTotal: [],
  productLoading: true,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.productTotal = action.payload;
      state.productLoading = false;
    },
  },
});
export const {setProductList} = productSlice.actions;
export default productSlice.reducer;
