import React from "react";

const RefundPage = () => {
  return (
    <>
      <div className="about-heading">
        <h4 className="text-center">Refund Policy For Salontreat:</h4>
      </div>
      <div className="about-write container-fluid">
        <p>
          We know that you're going to love your purchase, but we understand
          that sometimes things come up. That's why we want to make sure you
          have the information you need to make an informed decision when it
          comes to returns.
        </p>
        <p className="mt-2">
          The products you purchase through our website are non-refundable. We
          do not offer refunds, so please be sure to double-check the
          requirement of the product you are purchasing before making your
          decision.
        </p>
        <p className="mt-2">
          We are dedicated to providing the best products possible, and we
          believe that once you have purchased one of our products, you will be
          happy with it.
        </p>
      </div>
    </>
  );
};

export default RefundPage;
