import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  ShopCartItems: localStorage.getItem("ShopCartItems")
    ? JSON.parse(localStorage.getItem("ShopCartItems"))
    : [],
  shopSelectedCategory: localStorage.getItem("shopSelectedCategory")
    ? JSON.parse(localStorage.getItem("shopSelectedCategory"))
    : "",
  ShopProductTotal: [],
  shopCartTotalQuantity: 0,
  shopCartTotalAmount: 0,
  shopCartNetPayable: 0,
  shopCartTotalMrp: 0,
  shopCartTotalPrice: 0,
  shopCartPackQuantity: 0,
  ShopCouponDiscountAmount: 0,
  shopLogisticsAmount: 0,
  ShopCartloading: true,
  shopproductUpdated: false,
  shopCheckoutData: "",
};
const ShopCartSlice = createSlice({
  name: "ShopCart",
  initialState,
  reducers: {
    allShopProductUpdate(state, action) {
      state.ShopProductTotal = action.payload;
      state.shopproductUpdated = true;
      localStorage.setItem(
        "ShopProductTotal",
        JSON.stringify(state.ShopProductTotal)
      );
    },
    SetShopSelectedCategory(state, action) {
      state.shopSelectedCategory = action.payload;
      localStorage.setItem(
        "shopSelectedCategory",
        JSON.stringify(state.shopSelectedCategory)
      );
    },
    setShopCheckoutAllData(state, action) {
      state.shopCheckoutData = action.payload;
    },
    shopCcartReplaceClick(state, action) {
      // state.shopCartNetPayable = action.payload;
      const tempShopProducts = action.payload;
      state.ShopCartItems = [action.payload];
      state.shopCartTotalQuantity++;
      state.shopCartPackQuantity++;

      state.shopCartTotalAmount =
        Number(state.shopCartTotalAmount) + Number(action.payload.Price);

      if (state.shopCartTotalAmount >= 400) {
        state.shopLogisticsAmount = 0;
      } else {
        state.shopLogisticsAmount = 40;
      }
      state.shopCartNetPayable =
        Number(state.shopCartTotalAmount) + Number(state.shopLogisticsAmount);

      state.ShopCartloading = !state.ShopCartloading;
      localStorage.setItem(
        "ShopCartItems",
        JSON.stringify(state.ShopCartItems)
      );
    },
    addtoShopCart(state, action) {
      console.log(action.payload,'action.payload');
      // Find the index of the item in the cart
      const itemIndex = state.ShopCartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );
      // If the item already exists in the cart, update its quantity and totals
      if (itemIndex >= 0) {
        state.shopCartTotalQuantity++;
        const item = state.ShopCartItems[itemIndex];
        item.Qty += 1;
        item.TotalMrp = item.Mrp * item.Qty;
        item.TotalPrice = item.Price * item.Qty;
        item.TotalAmount = item.Price * item.Qty;
        state.shopCartTotalQuantity++;
        state.shopCartPackQuantity++;
        state.shopCartTotalAmount += item.Price;
        state.shopCartNetPayable = state.shopCartTotalAmount;
      } else {
        // If the item does not exist in the cart, add it
        const tempProduct = action.payload;
        state.ShopCartItems.push(tempProduct);
        state.shopCartTotalQuantity++;
        state.shopCartPackQuantity++;
        state.shopCartTotalAmount += tempProduct.Price;
        state.shopCartNetPayable = state.shopCartTotalAmount;
      }

      // Toggle loading state and save cart items to local storage
      state.ShopCartloading = !state.ShopCartloading;
      localStorage.setItem(
        "ShopCartItems",
        JSON.stringify(state.ShopCartItems)
      );
    },

    decrementShopCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.ShopCartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );

      if (itemIndex >= 0) {
        if (state.ShopCartItems[itemIndex].Qty > 1) {
          let quant = state.ShopCartItems[itemIndex].Qty - 1;
          state.ShopCartItems[itemIndex].Qty =
            state.ShopCartItems[itemIndex].Qty - 1;
          state.ShopCartItems[itemIndex].TotalMrp =
            Number(state.ShopCartItems[itemIndex].Mrp) * Number(quant);

          state.ShopCartItems[itemIndex].TotalPrice =
            Number(state.ShopCartItems[itemIndex].Price) * Number(quant);
          state.ShopCartItems[itemIndex].TotalAmount =
            Number(state.ShopCartItems[itemIndex].Price) * Number(quant);
        } else if (state.ShopCartItems[itemIndex].Qty === 1) {
          const nextCartItems = state.ShopCartItems.filter(
            (cartItem) => cartItem.ProductId !== action.payload.ProductId
          );
          state.shopCartPackQuantity--;
          state.ShopCartItems = nextCartItems;
        }

        state.shopCartTotalAmount =
          Number(state.shopCartTotalAmount) + Number(action.payload.Price);

        // if (state.shopCartTotalAmount >= 500) {
        //   state.shopLogisticsAmount = 0;
        // } else {
        //   state.shopLogisticsAmount = 40;
        // }

        state.shopCartNetPayable = Number(state.shopCartTotalAmount);
        state.ShopCartloading = !state.ShopCartloading;
      }

      localStorage.setItem(
        "ShopCartItems",
        JSON.stringify(state.ShopCartItems)
      );
    },
    removefromShopCart(state, action) {
      const nextCartItems = state.ShopCartItems.filter(
        (cartItem) => cartItem.ProductId !== action.payload.ProductId
      );
      state.shopCartPackQuantity--;
      state.ShopCartItems = nextCartItems;
      state.ShopCartloading = !state.ShopCartloading;
      localStorage.setItem(
        "ShopCartItems",
        JSON.stringify(state.ShopCartItems)
      );
    },
    getShopCartTotal(state, action) {
      state.shopCartTotalAmount = 0;
      state.shopCartNetPayable = 0;
      state.shopCartTotalMrp = 0;
      state.shopCartTotalPrice = 0;
      const totalitem = state.ShopCartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.shopCartTotalAmount =
          Number(state.shopCartTotalAmount) +
          Number(state.ShopCartItems[i].TotalAmount);

        state.shopCartTotalMrp =
          Number(state.shopCartTotalMrp) +
          Number(state.ShopCartItems[i].TotalMrp);
        state.shopCartTotalPrice =
          Number(state.shopCartTotalPrice) +
          Number(state.ShopCartItems[i].TotalPrice);
      }
      state.shopCartNetPayable = Number(state.shopCartTotalAmount);
    },
    clearShopCart(state, action) {
      state.ShopCartItems = [];
      state.shopCartTotalQuantity = 0;
      state.shopCartTotalAmount = 0;
      state.shopCartNetPayable = 0;
      state.shopCartTotalMrp = 0;
      state.shopCartPackQuantity = 0;
      state.ShopCartloading = !state.ShopCartloading;
      localStorage.setItem(
        "ShopCartItems",
        JSON.stringify(state.ShopCartItems)
      );
    },
  },
});

export const {
  allShopProductUpdate,
  addtoShopCart,
  decrementShopCart,
  removefromShopCart,
  getShopCartTotal,
  clearShopCart,
  setShopCheckoutAllData,
  SetShopSelectedCategory,
  shopCcartReplaceClick,
} = ShopCartSlice.actions;
export default ShopCartSlice.reducer;
