import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  OrderTotal: [].slice().reverse(),
  currentOrder: '',
  orderLoading: true,
};

const OrderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    Orderget(state, action) {
      state.OrderTotal = action.payload;
      state.orderLoading = false;
    },
    clearorder(state, action) {
      state.OrderTotal = [];
    },
    clearCurrentorder(state, action) {
      state.currentOrder = '';
    },
    newOrder(state, action) {
      if (action.payload.success) {
        state.OrderTotal = [action.payload.order, ...state.OrderTotal];
        state.currentOrder = action.payload.order;
      }
    },
  },
});

export const {Orderget, clearorder, clearCurrentorder, newOrder} =
  OrderSlice.actions;
export default OrderSlice.reducer;
