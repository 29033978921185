import { Book, Home, Share } from "@mui/icons-material";
import React from "react";
import { FaFemale } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Medianav() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid-lg d-block">
      <div id="topnav" className="w-100">
        <div className="nav-top d-flex bg-light justify-content-around p-2 h-100 align-items-center mt-1 w-100">
          <div onClick={() => navigate("/")} style={{ color: "#CD4960" }}>
            <span className="d-flex justify-content-center">
              {/* <Home /> */}
              <img
                src="/assets/stlogo1.png"
                alt=""
                style={{ height: "26px" }}
              />
            </span>
            <p className="text-center text-dark book">Home</p>
          </div>
          <div>
            <span
              style={{ color: "#000" }}
              className="d-flex justify-content-center"
            >
              <FaFemale className="fs-3" />
            </span>
            <p className="text-center book">Beauty</p>
          </div>
          <div>
            <span
              style={{ color: "#000" }}
              className="d-flex justify-content-center"
            >
              <Book />
            </span>
            <p className="text-center book">Booking</p>
          </div>
          <div>
            <span
              style={{ color: "#000" }}
              className="d-flex justify-content-center"
            >
              <Share />
            </span>
            <p className="text-center book">Refer</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medianav;
