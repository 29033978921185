import React, { useEffect, useState } from "react";
import { GoClock } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import {
  SetSelectedCategory,
  addtoCart,
  clearCart,
  decrementCart,
  getCartTotal,
} from "../../redux/cart/CartSlice";
import { FaMinus, FaPlus } from "react-icons/fa";

function CardComponent() {
  const { CartItems, selectedCategory } = useSelector((store) => store.Cart);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [showProducts, setShowProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);
  const [supercat, setSupercat] = useState("666fc7ff9a484c95552b75fe");

  console.log(showProducts.length, "showProducts");
  console.log(showProducts[0], "showProducts");

  useEffect(() => {
    if (temploading) {
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/product/productbycatid/${supercat}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const prod = fetchproducts.data.product.filter(
                (data) => data.outOfStock === false
              );
              let productbySubcat = prod.map(
                (product) => (product = { ...product, cartQuantity: 0 })
              );
              CartItems.forEach((cartitem) => {
                let itemIndex = -1;
                itemIndex = productbySubcat.findIndex(
                  (product) => product._id === cartitem.ProductId
                );
                if (itemIndex >= 0) {
                  productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
                }
              });
              setShowProducts(productbySubcat);
              setTempLoading(false);
            }
          } catch (error) {}
        };
        fetchData();
      } catch (error) {}
    }
  }, [CartItems]);

  const addToCartPress = (product, index) => {
    if (selectedCategory === product.category || selectedCategory === "") {
      if (selectedCategory === "") {
        dispatch(SetSelectedCategory(product.category));
      }

      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) {}
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
      };
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    } else {
      dispatch(SetSelectedCategory(product.category));
      dispatch(clearCart());
      dispatch(getCartTotal());
      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) {}
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
      };
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    }
  };

  const decrementCartPress = (product, index) => {
    try {
      let products = showProducts;
      products[index].cartQuantity = products[index].cartQuantity - 1;
      setShowProducts(products);
    } catch (error) {}
    const formData = {
      ProductId: product._id,
      ProductName: product.name,
      CatId: product.categoryId,
      CatName: product.category,
      Brand: product.brand,
      Services: product.services,
      Duration: product.duration,
      ImgUrl: product.ImgUrlMbl,
      Price: product.sellingPrice,
      Qty: 1,
      TotalAmount: product.sellingPrice * 1,
      Mrp: product.mrp,
      TotalPrice: product.sellingPrice * 1,
      TotalMrp: product.mrp * 1,
      Discount: product.mrp * 1 - product.sellingPrice * 1,

      productCostPrice: product.productCostPrice,
    };
    dispatch(decrementCart(formData));
    dispatch(getCartTotal());
  };

  return (
    <div>
      <div
        id="heading3"
        className="mt-3 px-2 mt-2 d-flex justify-content-start"
      >
        <h4>
          <span>Healthy Diets for Pets</span>
        </h4>
      </div>
      <div>
        <div className="product-grid mt-1">
          {showProducts.map((product, index) => (
            <div className="cardcomponentcontainer" key={index}>
              <div
                className="card border-0  p-2"
                style={{
                  height: "auto",
                  width: "100%",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      height: "auto",
                      padding: "3px",
                    }}
                  >
                    <img
                      src={product.ImgUrlDesk}
                      className="img-fluid"
                      alt=""
                      style={{ maxHeight: "150px", minHeight: "120px" }}
                    />
                  </div>
                </div>
                {/* <span className="service_time">
            <GoClock className="text-dark" />{" "}
            <label className="mt-2" htmlFor="time">
              {product.duration}
            </label>
          </span>  */}

                {product.newLaunch ? (
                  <>
                    <span
                      className="service_time "
                      style={{ background: "#388e3c", color: "#fff" }}
                    >
                      <label className="mt-2" htmlFor="time">
                        BESTSELLER
                      </label>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {product.mustTry ? (
                  <>
                    <span
                      className="service_time "
                      style={{ background: "#388e3c", color: "#fff" }}
                    >
                      <label className="mt-2" htmlFor="time">
                        MustTry
                      </label>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {product.outOfStock ? (
                  <>
                    <span
                      className="service_time "
                      style={{ background: "#388e3c", color: "#fff" }}
                    >
                      <label className="mt-2" htmlFor="time">
                        OutOfSTock
                      </label>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {product.inDemand ? (
                  <>
                    <span
                      className="service_time "
                      style={{ background: "#388e3c", color: "#fff" }}
                    >
                      <label className="mt-2" htmlFor="time">
                        InDemand
                      </label>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {product.hotDeal ? (
                  <>
                    <span
                      className="service_time "
                      style={{ background: "#388e3c", color: "#fff" }}
                    >
                      <label className="mt-2" htmlFor="time">
                        HotDeal
                      </label>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <div className="card-text">
                  <p id="product-nam" className="text-start">
                    {product.name}
                  </p>

                  <div className="">
                    <ul style={{ height: "28px" }}>
                      {product.services
                        .slice(0, 2)
                        .map((indService, serviceIndex) => (
                          <li
                            id="details"
                            className="fw-lighter"
                            key={serviceIndex}
                            style={{
                              //   display: "-webkit-box",
                              //  WebkitLineClamp: 1,
                              //  WebkitBoxOrient: "vertical",
                              //  overflow: "hidden",
                              fontWeight: 600,
                              fontFamily: "MyfontPoppines",
                              fontSize: "12px",
                              listStyle: "outside",
                              lineHeight: "1.4em",
                              maxHeight: "2.8em",
                              maxWidth: "100%",
                            }}
                          >
                            {indService.service.length > 20
                              ? `${indService.service.slice(0, 20) + "..."}`
                              : indService.service}
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div
                    id="cardinfor"
                    style={{ width: "100%", justifyContent: "space-between" }}
                    className="d-flex align-items-center flex-wrap"
                  >
                    <div className="p-1">
                      <span
                        style={{ fontSize: "16px", color: "##013220" }}
                        className=" fw-bold mrp"
                      >
                        <span>₹{product.sellingPrice}/-</span>
                      </span>{" "}
                      <span
                        style={{
                          textDecoration: "line-through",
                          fontSize: "14px",
                        }}
                        className="text-secondary mt-1"
                      >
                        ₹{product.mrp}
                      </span>
                    </div>
                  </div>
                  <div className="mrp text-success" style={{textAlign:"start"}}>
                    {product.discount}% off{" "}
                  </div>
                  {/* {product.cartQuantity > 0 ? (
                <>
                  <div className=" d-flex rounded justify-content-center">
                    <button
                      id="btn-indec"
                      className="btn text-white mt-2 w-100 d-flex  justify-content-between"
                    >
                      <span onClick={() => decrementCartPress(product, index)}>
                        <FaMinus style={{ cursor: "pointer" }} />
                      </span>
                      <span className="">{product.cartQuantity}</span>
                      <span onClick={() => addToCartPress(product, index)}>
                        <FaPlus style={{ cursor: "pointer" }} />
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div
                  className="d-flex justify-content-center"
                    onClick={() => addToCartPress(product, index)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <button id="addto" className="btn text-white mt-2  w-100">
                      ADD TO CART
                    </button>
                  </div>
                </>
              )} */}

                  {product.cartQuantity > 0 ? (
                    <>
                      <div className=" d-flex rounded justify-content-center">
                        <button
                          id="btn-indec"
                          style={{ width: "99.5%" }}
                          className="btn text-white mt-2  d-flex  justify-content-between"
                        >
                          <span
                            onClick={() => decrementCartPress(product, index)}
                          >
                            <FaMinus style={{ cursor: "pointer" }} />
                          </span>
                          <span className="">{product.cartQuantity}</span>
                          <span onClick={() => addToCartPress(product, index)}>
                            <FaPlus style={{ cursor: "pointer" }} />
                          </span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex justify-content-center"
                        onClick={() => addToCartPress(product, index)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <button
                          id="addto"
                          className="btn text-white mt-2  w-100"
                        >
                          ADD TO CART
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div id="card-component" className="row h-100 mt-3">
        {showProducts.map((product, index) => (
          <div className="col-lg-3 col-md-2 col-sm-6 col-6 mb-4" key={index}>
            <div
              className="card shadow-sm  p-2"
              style={{ height: "358px", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    height: "auto",
                    padding: "3px",
                  }}
                >
                  <img
                    src={product.ImgUrlDesk}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "170px", minHeight: "120px" }}
                  />
                </div>
              </div>
              {/* <span className="service_time">
                <GoClock className="text-dark" />{" "}
                <label className="mt-2" htmlFor="time">
                  {product.duration}
                </label>
              </span> 

              {product.newLaunch ? (
                <>
                  <span
                    className="service_time "
                    style={{ background: "#388e3c", color: "#fff" }}
                  >
                    <label className="mt-2" htmlFor="time">
                      BESTSELLER
                    </label>
                  </span>
                </>
              ) : (
                <></>
              )}
              {product.mustTry ? (
                <>
                  <span
                    className="service_time "
                    style={{ background: "#388e3c", color: "#fff" }}
                  >
                    <label className="mt-2" htmlFor="time">
                      MustTry
                    </label>
                  </span>
                </>
              ) : (
                <></>
              )}
              {product.outOfStock ? (
                <>
                  <span
                    className="service_time "
                    style={{ background: "#388e3c", color: "#fff" }}
                  >
                    <label className="mt-2" htmlFor="time">
                      OutOfSTock
                    </label>
                  </span>
                </>
              ) : (
                <></>
              )}
              {product.inDemand ? (
                <>
                  <span
                    className="service_time "
                    style={{ background: "#388e3c", color: "#fff" }}
                  >
                    <label className="mt-2" htmlFor="time">
                      InDemand
                    </label>
                  </span>
                </>
              ) : (
                <></>
              )}
              {product.hotDeal ? (
                <>
                  <span
                    className="service_time "
                    style={{ background: "#388e3c", color: "#fff" }}
                  >
                    <label className="mt-2" htmlFor="time">
                      HotDeal
                    </label>
                  </span>
                </>
              ) : (
                <></>
              )}

              <div className="card-text">
                <p
                  id="product-nam"
                  className="text-start"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    lineHeight: "1.4em",
                    maxHeight: "2.8em",
                  }}
                >
                  {product.name}
                </p>

                <div className="mt-1">
                  <ul style={{ height: "auto" }}>
                    {product.services
                      .slice(0, 2)
                      .map((indService, serviceIndex) => (
                        <li
                          className="fw-lighter"
                          style={{ fontFamily: "sans-serif" }}
                          key={serviceIndex}
                        >
                          {indService.service.length > 20
                            ? `${indService.service.slice(0, 20) + "..."}`
                            : indService.service}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="d-flex px-3">
                    <p
                      style={{ fontSize: "16px" }}
                      className="text-success fw-bold"
                    >
                      <span>₹{product.sellingPrice}/-</span>
                    </p>{" "}
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontSize: "14px",
                      }}
                      className="text-secondary mt-1"
                    >
                      ₹{product.mrp}
                    </span>
                  </p>
                  {product.discount}% off{" "}
                </div>
                {product.cartQuantity > 0 ? (
                  <>
                    <div className=" d-flex rounded justify-content-center">
                      <button
                        id="btn-indec"
                        className="btn text-white mt-2 w-75 d-flex rounded justify-content-between"
                      >
                        <span
                          onClick={() => decrementCartPress(product, index)}
                        >
                          <FaMinus style={{ cursor: "pointer" }} />
                        </span>
                        <span className="">{product.cartQuantity}</span>
                        <span onClick={() => addToCartPress(product, index)}>
                          <FaPlus style={{ cursor: "pointer" }} />
                        </span>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => addToCartPress(product, index)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <button id="addto" className="btn text-white mt-2 w-75">
                        ADD TO CART
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
}

export default CardComponent;
