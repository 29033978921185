import React from "react";
import "./TermsNdConditions.css";
import { FaLessThan } from "react-icons/fa";
// import { FaLessThan } from "react-icons/fa";
const TermsNdConditions = () => {
  return (
    <>
      <div className="Terms_Main">
        {/* <nav>
          <p className="lessthen">
            <FaLessThan />
          </p>
          <div className="head">Terms & Conditions</div>
        </nav> */}
        <div className="Term_container">
          <div class="sub-Container">
            <div className="heading">
              <h2>Terms & Conditions</h2>
              <div className="undline"></div>
            </div>
            <div class="term_data">
              <p>
                <b>DISCLAIMER: </b> Please take note that accessing, browsing or
                otherwise using the website
                <b class="color"> www.saloonTreat.com</b> mobile application or
                purchases made otherwise from Sonu SaloonTreat Private Limited
                indicates your agreement to these user terms and conditions of
                use. So please read this agreement carefully before proceeding.
              </p>
            </div>
            <div class="term_data">
              <h4>1. Electronic Record</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    <b>1.1</b> The present document shall constitute and be
                    deemed to be electronic record as defined and in accordance
                    to the provisions of the Information Technology Act, 2000
                    and the rules made there under, applicable to electronic
                    record, as well as any amended provisions including but not
                    limited to legislations stipulated in various statues
                    relating to electronic records. This electronic record is
                    generated by a computer system and does not require any
                    physical or digital signatures.
                  </p>
                  <p>
                    <b>1.2</b> This document is being published on
                    www.SaloonTreat.com and its Mobile Application in accordance
                    with the provisions of Rule 3 (1) of the Information
                    Technology (Intermediaries guidelines) Rules, 2011.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>2. Introduction</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    <b> 2.1 </b>We thank You (Customer/User/You/Your) for
                    registering with SONU SALONTREAT PRIVATE LIMITEDand/or
                    Saloon Treat as its brand, (We/Us/Our/Saloon Treat) on{" "}
                    <b className="color"> www.SaloonTreat.com</b> (Website) as
                    well as on our Mobile Application (App). Your agreement with
                    us includes these User Terms and Conditions of Use (Terms)
                    and our Privacy Policy (together the Policies). If You don't
                    agree with these Terms, then please don't use our services
                    (defined later). Please note that You will not be able to
                    book services offered by Saloon Treat, if You have not
                    clicked and accepted these Terms.
                  </p>
                  <p>
                    <b>2.2</b> By agreeing to these Terms, You hereby agree to
                    the following: (i) That You are at least 18 years of age or
                    older to consent to these Terms. (ii) That You have the
                    power to enter a binding contract with Us and are not barred
                    from doing so under any applicable laws. (iii) That any
                    registration information that You submit to Us is true,
                    accurate and complete, and You agree to keep it that way at
                    all times.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>3. Membership Eligibility</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    Use of the Website and/or the App as well as other services
                    provided by Saloon Treat are available only to persons who
                    can enter into a legally binding contract under Indian
                    Contract Act, 1872. Persons who are "incompetent to
                    contract" within the meaning of the Indian Contract Act,
                    1872 including minors, un-discharged insolvents etc. are not
                    eligible to use the Website/App or place orders
                    telephonically. If You are a minor i.e. under the age of 18
                    years, You shall not register as a member of the Website/App
                    and shall not transact or use the Website/App or avail any
                    Services. It is however clarified that whilst registering as
                    a member of the website or placing any order telephonically,
                    Saloon Treat shall solely rely upon the information provided
                    by You and representations made. In the event the
                    information provided is found to be incorrect, You undertake
                    to indemnify Saloon Treat and hold Saloon Treat harmless
                    against any third party civil or criminal claim.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>4. User Account, Password and Security</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    For any purchases to be made through the Website/App
                    directly it is mandatory for You to become a member. Upon
                    registering Yourself, You will receive a One Time Password
                    (OTP) and account designation upon completing the
                    Website/App's registration process. Every time You log in,
                    You will require Your handheld telephonic device to complete
                    the log in. Each time You log in, You will be assigned an
                    unique OTP. You are responsible for maintaining the
                    confidentiality of the OTP and account, and are fully
                    responsible for all activities that occur under Your
                    account. You agree to (a) immediately notify Saloon Treat in
                    case of any unauthorized use of Your account or any other
                    breach of security, and (b) ensure that You exit from Your
                    account at the end of each session. Saloon Treat cannot and
                    will not be liable for any loss or damage arising from Your
                    failure to comply with this clause.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>5. Use of Our Services</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    <b>5.1</b> You understand and agree that by registering with
                    Yes Madam, You will be able to opt for wellness, grooming
                    and beauty services (Service) within the premise and
                    comforts of Your home. Saloon Treat is only an
                    aggregator/marketplace for technicians of different grades
                    and caliber.
                  </p>
                  <p>
                    <b>5.2</b> The rates that apply to our technicians are basis
                    their skill and training, depending on which our technicians
                    charge up to Rs 12/- per minute for their services. However,
                    the ratings for all our technicians are dynamic and driven
                    by customer feedback and are therefore subject to change
                    from time to time.
                  </p>
                  <p>
                    <b>5.3</b> At Saloon Treat, all appointments are booked
                    online through our Website/App or telephonically and are
                    subject to availability of technicians and service slots. In
                    the event You have booked an appointment, and our
                    technicians fail to reach You on time, every attempt will be
                    made to inform You of such delay. However, our maximum
                    liability under any and all circumstances shall be limited
                    to rescheduling the appointment to the next convenient
                    available time slot.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>6. User Account</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    6.1 By choosing to opt for a home service a service You
                    understand and agree that while our aim is to deliver a
                    great service experience every time, there are certain
                    obligations on You as Customer as well. i. You shall be
                    liable to make complete payment for all Services availed as
                    per the invoice presented to You after use of Services which
                    shall include service fees, product costs and statutory
                    taxes. ii. You shall ensure that there is no misuse, soiling
                    and/or damaging of any of the devices/products (technical
                    and/or non-technical) used by the technicians. iii. You
                    shall not at anytime ask the technician to commit/omit doing
                    any act that is contrary to the model adopted by the
                    technicians in course of rendering Services. Our technicians
                    have the right to refuse a service request from the Customer
                    for any reason whatsoever. In case of any grievance in this
                    regard, You are advised to kindly contact our customer care
                    for complaints and assistance. iv. You or any member within
                    Your home premise, shall not at any point misbehave with our
                    technicians, harass or use abusive language or use physical
                    violence and/or behave in any manner that is unlawful. In
                    the event You are unhappy with the Services, You may ask our
                    technicians to leave, followed by lodging a
                    complaint/feedback on our Website/App. We will get back to
                    You with specialized assistance within 24 hours of receipt
                    of the message.
                  </p>
                  <p>
                    <b>6.2</b> Saloon Treat endeavors to provide a comfortable
                    and flawless experience to all its customers. However, in
                    case of any loss or damage suffered by the Customer arising
                    directly out of the technician's fault and solely
                    attributable to the negligence of the technician in course
                    of rendering Services, the total liability of Saloon Treat
                    shall in all cases be limited to an amount not exceeding Rs
                    15,000/- (Rupees Fifteen Thousand Only) subject to a
                    detailed enquiry and inspection by Saloon Treat. Saloon
                    Treat shall not be liable for any damages of any kind
                    arising from the use of the Services in case such damage is
                    incidental, punitive, and consequential.
                  </p>
                  <p>
                    <b>6.3</b> Saloon Treat encourages all its Customers to take
                    full responsibility of their belongings. In case any items
                    or belongings of the Customer is found to be missing post
                    availing Services, the Customer shall have to notify Saloon
                    Treat within twenty four (24) hours from the time of
                    availing such Services. Saloon Treat shall try to locate the
                    items on a "best-effort" basis but is not responsible for
                    the same in case of loss or damage to the same.
                  </p>
                  <p>
                    <b>6.4</b> If the Customer suspects any wrongdoing (legal or
                    illegal) or has any complaint in respect of the Services or
                    regarding the technicians, the Customer is encouraged to
                    inform Saloon Treat of the same in writing within 24 hours
                    of using the Services of Saloon Treat. Saloon Treat shall
                    not be liable for the conduct of its technicians. The
                    company reserves the right to communicate with you via
                    various channels, including but not limited to phone calls,
                    emails, text messages, and other modes of communication.
                  </p>
                  <p>
                    <b>6.5</b> Cancellation of Services: In the event the
                    Customer has booked a service on the Website/App and made an
                    online payment for same, Saloon Treat shall send a message
                    using Short Messaging Service (SMS)/Whatsapp/Call to the
                    designated contact number of such Customer, confirming such
                    Services and acknowledging such payment. In the event the
                    Customer cancels the appointment, once the payment for the
                    same is made, the Customer shall have the following options
                    for refund: i. Refund of entire amount paid, in to the
                    designated bank account of such customer. This may take up
                    to 14 working days depending on the bank of the customer.
                    ii. Refund of entire amount paid, into the Saloon Treat
                    Wallet of the Customer. The amount shall remain in the
                    Customer's wallet, and may be redeemed by the Customer on
                    their next purchase.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>7. Intellectual Property Rights</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    <b>7.1</b> Unless otherwise indicated or anything contained
                    to the contrary or any proprietary material owned by a third
                    party and so expressly mentioned, Saloon Treat owns all
                    Intellectual Property Rights to and in the Website/App,
                    including, without limitation, any and all rights, title and
                    interest in and to copyright, related rights, patents,
                    utility models, trademarks, trade names, service marks,
                    designs, know-how, trade secrets and inventions (whether
                    patentable or not), goodwill, source code, meta tags,
                    databases, text, content, graphics, icons, and hyperlinks.
                    You acknowledge and agree that You shall not use, reproduce
                    or distribute any content from the Website/App belonging to
                    Saloon Treat without obtaining an express authorization from
                    Saloon Treat.
                  </p>
                  <p>
                    <b> 7.2</b> Notwithstanding the foregoing, it is expressly
                    clarified that You will retain ownership and shall solely be
                    responsible for any content that You provide or upload when
                    using any Service, including any text, data, information,
                    images, photographs, music, sound, video or any other
                    material which You may upload, transmit or store when making
                    use of our Services. However, with regard to the product
                    customization Service (as against other Services like blogs
                    and forums) You expressly agree that by uploading and
                    posting content on to the Website/App for public viewing and
                    reproduction/use of Your content by third party users, You
                    accept as the User whereby You grant a non-exclusive license
                    for the use of the same.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>8. Indemnification</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    You agree to indemnify, defend and hold harmless Saloon
                    Treat from and against any and You shall indemnify and hold
                    harmless Yes Madam, its owner, licensee, affiliates,
                    subsidiaries, group companies (as applicable) and their
                    respective officers, directors, agents, and employees, from
                    any claim or demand, or actions including reasonable
                    attorneys' fees, made by any third party or penalty imposed
                    due to or arising out of Your breach of this Terms, Privacy
                    Policy and other Policies, or Your violation of any law,
                    rules or regulations or the rights of a third party.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>9. Pricing</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    Prices for products and Services are described on our
                    Website/App and are incorporated into these Terms by
                    reference. All prices are in Indian rupees and are subject
                    to change at the sole discretion of Saloon Treat without
                    prior notice to the Customer.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>10. Termination</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    <b>10.1</b> Saloon Treat may de-register You from its
                    platform and/or terminate Your use of the Website/App or its
                    Services if it believes, in its sole and absolute discretion
                    that You have breached, violated, abused, or unethically
                    manipulated or exploited any of these Terms.
                  </p>
                  <p>
                    <b>10.2</b> Notwithstanding anything mentioned in 9.1 above,
                    these Terms will survive indefinitely unless and until
                    Saloon Treat chooses to terminate them.
                  </p>
                  <p>
                    <b>10.3</b> If You or Saloon Treat terminates Your use of
                    the Website/App or any Service, Saloon Treat may delete any
                    content or other materials relating to Your use of the
                    Service and Yes Madam will have no liability to You or any
                    third party for doing so.
                  </p>
                  <p>
                    <b>10.4</b> You shall be liable to pay for any Service or
                    product that You have already ordered / used till the time
                    of Termination by either party whatsoever.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>11. Governing Law</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    This Agreement shall be governed by and interpreted and
                    construed in accordance with the laws of India. In the event
                    of dispute between the parties pertaining to these Terms as
                    well as for any Service/product sold by Saloon Treat through
                    the Website/App or for bookings made telephonically, the
                    same shall be subject to the exclusive jurisdiction of the
                    of the courts at Delhi
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>12. Severability</h4>
              <div className="term_para">
                <div className="passage">
                  <p>
                    If any provision of the Terms is determined to be invalid or
                    unenforceable in whole or in part, such invalidity or
                    unenforceability shall attach only to such provision or part
                    of such provision and the remaining part of such provision
                    and all other provisions of these Terms shall continue to be
                    in full force and effect.
                  </p>
                </div>
              </div>
            </div>
            <div class="term_data">
              <h4>13. Amendment</h4>
              <div class="term_para">
                <div className="passage">
                  <p>
                    We reserve the right, at our sole discretion, to change,
                    modify, add or remove portions of these Terms at any time
                    without any prior notification to user(s) and the amended
                    and revised Terms shall be effective forthwith. Your
                    continued use of our Services will signify Your deemed
                    acceptance of the revised Terms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsNdConditions;
