import React, { useEffect, useState, useRef } from "react";
import { VscThreeBars } from "react-icons/vsc";
import ReactModal from "react-modal";
import {
  CheckOutlined,
  Close,
  LocationOn,
  PersonRounded,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Cart4 } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginOtp,
  SetLoginModalOpen,
  authActions,
  loginAuthentication,
  setLoginMobile,
  signout,
} from "../../redux/authentication/AuthenticationSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";
import { IoSearchOutline } from "react-icons/io5";
import { Select } from "antd";
import {
  setSearchCategory,
  setSelectedSaloon,
} from "../../redux/saloon/SaloonSlice";

const Header = () => {
  const {
    isAuth,
    loginData,
    loginMobile,
    isLoginModalOpen,
    currentAddress,
    address,
  } = useSelector((store) => store.Authentication);
  const { categorytotal } = useSelector((store) => store.category);
  const { subCategorytotal } = useSelector((store) => store.subCategory);
  const { saloonList, allCategoriesFromSaloon } = useSelector(
    (store) => store.saloon
  );
  const ref = useRef();
  const { CartItems } = useSelector((store) => store.Cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [login, setlogin] = useState(false);
  const [Otp, setOtp] = useState(false);
  const [register, setRegister] = useState(false);

  const [number, setNumber] = useState("");
  const [otpsave, setotpsave] = useState("");
  const [otpvalue1, setOtpvalue1] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [termCheckbox, setTermCheckbox] = useState(false);

  const [errorMassage, setErrorMassage] = useState("");
  const [loginbtnLoad, setLoginbtnLoad] = useState(true);
  const [otpbtnLoad, setOtpbtnLoad] = useState(true);
  const [regdbtnLoad, setRegdbtnLoad] = useState(true);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [area, setArea] = useState("");
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [typing, setTyping] = useState(false);

  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [saloonSearchResult, setSaloonSearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [categories, setCategories] = useState([]);
  const [saloon, setSaloons] = useState([]);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(saloon[0]);
  const placeholderInterval = 3000;
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (loginMobile) {
      setNumber(loginMobile);
    }
  }, [loginMobile]);
  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  useEffect(() => {
    if (saloonList) {
      const saloonNames = saloonList.map((indSaloon) => indSaloon.name);
      setSaloons(saloonNames);
    }
  }, [saloonList]);
  useEffect(() => {
    if (allCategoriesFromSaloon) {
      const categoryNames = allCategoriesFromSaloon.map(
        (indCategory) => indCategory.name
      );
      setCategories(categoryNames);
    }
  }, [allCategoriesFromSaloon]);

  useEffect(() => {
    if (saloon.length > 0) {
      let index = 0;
      setCurrentPlaceholder(saloon[index]);
      setAnimationClass("slide-down");
      const interval = setInterval(() => {
        setAnimationClass("slide-up");
        setTimeout(() => {
          index = (index + 1) % saloon.length;
          setCurrentPlaceholder(saloon[index]);
          setAnimationClass("slide-down");
        }, 500); // Duration of the slide-up animation
      }, placeholderInterval);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [saloon]);
  useEffect(() => {
    if (categories.length > 0) {
      let index = 0;
      setCurrentPlaceholder(categories[index]);
      setAnimationClass("slide-down");
      const interval = setInterval(() => {
        setAnimationClass("slide-up");
        setTimeout(() => {
          index = (index + 1) % categories.length;
          setCurrentPlaceholder(categories[index]);
          setAnimationClass("slide-down");
        }, 500); // Duration of the slide-up animation
      }, placeholderInterval);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [categories]);

  const searchchange = (e) => {
    setTyping(true);
    const result = e.target.value.replace(/^\s+/, "");
    setSearchBoxValue(result);
    if (result.length >= 2) {
      const filter_saloon = saloonList
        .filter((indSaloon) =>
          indSaloon.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category = allCategoriesFromSaloon.filter((indCategory) =>
        indCategory.name.toLowerCase().match(result.toLowerCase())
      );
      setCategorySearchResult(filter_category);
      setSaloonSearchResult(filter_saloon);
      setShowResults(true);
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const handleSelectedSaloon = async (indSaloon) => {
    await dispatch(setSelectedSaloon(indSaloon._id));
    navigate(`/saloonNearMe`);
    setShowResults(false);
    setTyping(false);
    setSearchBoxValue("");
  };
  const handleSelectedCategory = async (indCategory) => {
    await dispatch(setSelectedSaloon(indCategory.superCategoryId));
    await dispatch(setSearchCategory(indCategory._id));
    navigate(`/search`);
    setShowResults(false);
    setTyping(false);
    setSearchBoxValue("");
  };
  const handleSearch = () => {
    if (searchBoxValue !== "") {
      if (
        searchBoxValue === "salon" ||
        searchBoxValue === "saloon" ||
        searchBoxValue === "saloon near me" ||
        searchBoxValue === "salon near me"
      ) {
        navigate("/salonlist");
        setSearchBoxValue("");
        setTyping(false);
        setShowResults("");
      }
    }
  };

  const setNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setErrorMassage("");
    setNumber(value);
    if (value.length === 10) {
      setErrorMassage("");
      setLoginbtnLoad(false);
    } else {
      setErrorMassage("Please Enter Valid Number");
      setLoginbtnLoad(true);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }

      const formdata = {
        number: number,
        OTP: OTP,
      };
      dispatch(setLoginMobile(number));
      setotpsave(formdata);
      dispatch(LoginOtp(formdata));
      dispatch(loginAuthentication(formdata));
      setOtp(true);
      setlogin(false);
    } else {
      setErrorMassage(" Please enter valid number");
    }
  };

  const resendClick = () => {
    setOtpvalue1("");
    setSeconds(30);
    setIsActive(true);
    dispatch(loginAuthentication(otpsave));
  };

  const setOtpChange = (e) => {
    const value = e.target.value;
    setOtpvalue1(value);
    if (value.length === 6) {
      setOtpbtnLoad(false);
    } else {
      setOtpbtnLoad(true);
    }
  };

  const verifyClicks = async () => {
    let optvalue = String(otpvalue1);
    if (optvalue.length === 6) {
      setErrorMassage("");

      if (optvalue === String(otpsave.OTP)) {
        try {
          const config = {
            Headers: { "Content-Type": "application/json" },
          };
          const formData = {
            Mobile: number,
          };
          const userDetails = await axios.post(
            `${Baseurl}/api/v1/client/loginclient`,
            formData,
            config
          );

          if (userDetails.data.success) {
            const loginDetails = {
              ...userDetails.data.client,
              isAuth: true,
            };
            dispatch(authActions.signin(loginDetails));
            // navigate("/");
            setOtp(false);
            toast.success("Logged in Successfully");
            dispatch(SetLoginModalOpen(false));
            dispatch(setLoginMobile(""));
          } else {
            // resgistation
            setRegister(true);
            setOtp(false);
          }
        } catch (error) {
          setRegister(true);
          setOtp(false);
        }
      } else {
        setErrorMassage("please enter correct OTP");
      }
    }
  };

  const onSubmitRegister = async (e) => {
    e.preventDefault();
    if (name !== "" && email !== "") {
      try {
        const formData = {
          Name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
          Email: email.trim(),
          Mobile: number,
          Gender: gender,
          readCheckbox: termCheckbox,
        };

        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/client/new`;
        const ClientDetails = await axios.post(url, formData, config);

        if (ClientDetails.data.success) {
          setRegister(false);
          const loginDeatails = {
            ...ClientDetails.data.client,
            isAuth: true,
          };
          dispatch(authActions.signin(loginDeatails));
          navigate("/");
          setName("");
          setEmail("");
          setNumber("");
          setGender("");
        } else {
        }
      } catch (error) {}
    } else if (name === "") {
      setNameError("Please provide name");
    } else if (email === "") {
      setEmailError("Please provide email");
    }
  };

  return (
    <div className="container-fluid-lg desktopVersion">
      <div id="nav" className="container-fluid border">
        <div style={{ position: "relative", right: "20px" }}>
          <span className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <img
                src="/assets/st.png"
                id="logo"
                className="img-fluid"
                alt=""
              />
            </Link>
            <div
              id="logoText"
              className="fs-5 text-secondary fw-lighter"
              onClick={() => navigate("/")}
            >
              <span style={{ position: "relative", top: "3px" }}>|</span>{" "}
              <span
                style={{
                  position: "relative",
                  top: "3.4px",
                  fontWeight: "200",
                  fontSize: "16px",
                }}
              >
                Home Saloon
              </span>
            </div>
          </span>
        </div>
        <div
          style={{ width: "45%", position: "relative" }}
          className="search mt-1"
          ref={ref}
        >
          <div
            style={{ position: "relative", overflow: "hidden" }}
            className="d-flex align-items-center border boder-1 border-dark rounded-3"
          >
            <input
              className="form-control border-0 rounded-0"
              type="search"
              placeholder="Search for"
              value={searchBoxValue}
              style={{ outline: "none", width: "100%", color: "#000" }}
              onChange={(e) => searchchange(e)}
            />
            {typing ? (
              <></>
            ) : (
              <div
                className={`placeholder-text ${animationClass}`}
                style={{ position: "absolute", top: 8, left: 95 }}
              >
                {`'${currentPlaceholder}'`}
              </div>
            )}
            <span
              className="p-2"
              style={{
                backgroundColor: "#000",
                overflow: "hidden",
                width: "8.5%",
                height: "100%",
              }}
              onClick={() => handleSearch()}
            >
              <IoSearchOutline style={{ fontSize: "20px", color: "#fff" }} />
            </span>
          </div>
          {showResults && (
            <div
              style={{
                width: "100%",
                position: "absolute",
                backgroundColor: "#fff",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                padding: "10px 20px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                top: "45px",
              }}
              className="search mt-1"
            >
              {saloonSearchResult.map((indItem, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                    margin: "10px",
                  }}
                  onClick={() => handleSelectedSaloon(indItem)}
                >
                  <img
                    src={indItem.desktopImage}
                    alt="desktopImage"
                    height={"60px"}
                    width={"60px"}
                  />
                  <div>{indItem.name}</div>
                </div>
              ))}
              {categorySearchResult.map((indItem, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                    margin: "10px",
                  }}
                  onClick={() => handleSelectedCategory(indItem)}
                >
                  <img
                    src={indItem.desktopImage}
                    alt="desktopImage"
                    height={"60px"}
                    width={"60px"}
                  />
                  <div>
                    {indItem.name}{" "}
                    <span style={{ fontWeight: "bold" }}>In</span>{" "}
                    {indItem.superCategory.length > 30
                      ? `${indItem.superCategory.slice(0, 30)}...`
                      : indItem.superCategory}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          id="sub-nav"
          className="d-flex mt-2 justify-content-around align-items-center"
        >
          {/* <h6 style={{ margin: "0 10px" }}>Blog</h6> | */}

          {!isAuth ? (
            <>
              <div
                onClick={() => dispatch(SetLoginModalOpen(true))}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 style={{ margin: "0px 0px 2px" }}>
                  <BsPersonCircle size={22} color="#000" />
                </h6>
                <h6 title="Login & Signup" style={{ margin: "0 10px" }}>
                  Login / Sign Up
                </h6>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => setmodal(true)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6>
                  <BsPersonCircle size={22} color="#000" />
                </h6>
                <h6 style={{ margin: "0 8px", marginBottom: "2px" }}>
                  {loginData.Name}
                </h6>
              </div>
            </>
          )}

          {isAuth ? (
            <>
              <div>
                {/* <span
                  onClick={() => setmodal(true)}
                  style={{ color: "#F6BE00" }}
                >
                  <VscThreeBars  className="fs-2 mb-2" />
                </span> */}
              </div>
            </>
          ) : (
            <></>
          )}

          {isAuth ? (
            <>
              <div className="mx-2 ">
                <div className="d-flex mb-2 justify-content-between">
                  <span
                    onClick={() => navigate("/cart")}
                    style={{ color: "#000", cursor: "pointer" }}
                  >
                    <Cart4 className="fs-4" />
                  </span>

                  <span
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                    className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
                  >
                    {CartItems.length}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mx-2 ">
                <div className="d-flex mb-2 justify-content-between">
                  <span
                    onClick={() => dispatch(SetLoginModalOpen(true))}
                    style={{ color: "#000", cursor: "pointer" }}
                  >
                    <Cart4 className="fs-4" />
                  </span>

                  <span
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                    className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
                  >
                    {CartItems.length}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        <ReactModal
          className="rounded border-0 mt-5"
          isOpen={modal}
          onRequestClose={() => setmodal(false)}
          style={{
            content: {
              width: "200px",
              height: "auto",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              marginLeft: "82%",
              backgroundColor: "#FFFFFF",
              border: "none",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0)",
              zIndex: "96",
              border: "none",
            },
          }}
        >
          <table id="modal" className="responsive table table-hover">
            <tbody>
              {/* <tr style={{ cursor: "pointer" }}>
                <td>Contribute</td>
              </tr>
              <tr style={{ cursor: "pointer" }}>
                <td>Franchise</td>
              </tr>
              <tr style={{ cursor: "pointer" }}>
                <td>Spa at Events</td>
              </tr> */}

              {isAuth ? (
                <>
                  <tr style={{ position: "" }}>
                    <td
                      className="d-flex justify-content-end"
                      onClick={() => setmodal(false)}
                    >
                      <IoClose />
                    </td>
                  </tr>
                  <tr
                    onClick={() => navigate("/profile") || setmodal(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Profile</td>
                  </tr>
                  <tr
                    onClick={() => navigate("/checkout") || setmodal(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Checkout</td>
                  </tr>
                  <tr
                    onClick={() =>
                      navigate("/registersaloon") || setmodal(false)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>Saloon Partner</td>
                  </tr>
                  <tr
                    onClick={() => dispatch(signout())}
                    style={{ cursor: "pointer" }}
                  >
                    <td>Log Out</td>
                  </tr>
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </ReactModal>
      </div>

      <ReactModal
        isOpen={isLoginModalOpen}
        onRequestClose={() => dispatch(SetLoginModalOpen(false))}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "68%"
              : "26%",
            height: "285px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            background: "rgba(0,0,0,0.6)",
            filter: `blur('5px')`,
            zIndex: "96",
          },
        }}
      >
        <div className="rounded">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>Login</h5>
            <span
              style={{ cursor: "pointer", color: "#CE665B" }}
              onClick={() => dispatch(SetLoginModalOpen(false))}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-3 mx-1">
            <p style={{ fontSize: "10pt", color: "#373B3E" }}>
              Enter Your Mobile Number *
            </p>
          </div>
          <div className="d-flex rounded border mx-1">
            <span
              className="d-flex justify-content-center align-items-center "
              style={{
                borderRight: "1px solid #C4C4C4",
                height: "35px",
                width: "35px",
                paddingTop: "2px",
              }}
            >
              +91
            </span>
            <input
              type="text"
              style={errorMassage ? { color: "#000" } : {}}
              placeholder="Mobile Number"
              className="form-control  border-0"
              name="number"
              id=""
              value={number}
              minLength={10}
              maxLength={10}
              pattern="[6-9]{1}[0-9]{9}"
              onChange={(e) => setNumberChange(e)}
            />
          </div>
          <div id="btn" className="mt-4 mx-1">
            {loginbtnLoad ? (
              <>
                <div
                  style={{ backgroundColor: "#e09faf", transition: ".2s" }}
                  className="btn text-white w-100"
                >
                  Login
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={(e) => onSubmitHandler(e)}
                  style={{ backgroundColor: "#B93C5B", transition: ".2s" }}
                  className="btn text-white w-100"
                >
                  Login
                </div>
              </>
            )}
          </div>

          <div className="mt-3">
            <p
              className="text-center justify-content-center d-flex align-items-center"
              style={{ fontSize: "8pt" }}
            >
              <input
                style={{ backgroundColor: "#B93C5B" }}
                type="checkbox"
                name=""
                id=""
              />
              &nbsp; I have read{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                &nbsp; Terms and Conditions
              </span>
            </p>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        id="OTP"
        isOpen={Otp}
        onRequestClose={() => setOtp(false)}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "70%"
              : "26%",
            height: "300px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            background: "rgba(0,0,0,0.6)",
            filter: `blur('5px')`,
            zIndex: "96",
          },
        }}
      >
        <div className="rounded">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>OTP</h5>
            <span
              style={{ cursor: "pointer", color: "##CE665B" }}
              onClick={() => setOtp(false)}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-2 mx-1">
            <p>Please Enter OTP</p>
          </div>
          <div className="mt-1 mx-1">
            <p style={{ fontSize: "8pt", color: "#51475E" }}>
              One Time Password(OTP) has been sent to your mobile number{" "}
              {number}, Please the same here to login.
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                className="text-primary"
                onClick={() => {
                  setOtp(false);
                  setlogin(true);
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div style={{ marginBottom: "4px" }} className="mt-2 mx-2">
            <input
              className="form-control"
              type="text"
              name=""
              id=""
              maxLength={6}
              placeholder="One Time Password"
              value={otpvalue1}
              onChange={(e) => setOtpChange(e)}
            />
            {seconds === 0 ? (
              <span style={{ fontSize: "9pt" }}>
                Did not received the OTP? Retry Via:
              </span>
            ) : (
              <span style={{ fontSize: "9pt" }}>
                Did not received the OTP? Retry In 00:{seconds}s
              </span>
            )}
            <br />
            {seconds === 0 && (
              <span
                id="resend"
                style={{ color: "#B93C5B", cursor: "pointer" }}
                onClick={() => resendClick()}
              >
                Resend OTP
              </span>
            )}
          </div>
          {otpbtnLoad ? (
            <>
              <div className="mx-auto d-flex justify-content-center align-items-center ">
                <button
                  className="btn text-white mx-auto w-50"
                  style={{ backgroundColor: "#e09faf" }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                className="mx-auto d-flex justify-content-center align-items-center "
                onClick={() => verifyClicks()}
              >
                <button
                  className="btn text-white mx-auto w-50"
                  style={{ backgroundColor: "#B93C5B" }}
                >
                  Submit
                </button>
              </div>
            </>
          )}

          {/* <div className="mt-3">
            <p id="changeMobNum" style={{ fontSize: "9pt" }}>
              Change Mobile Number
            </p>
          </div> */}
        </div>
      </ReactModal>

      <ReactModal
        isOpen={register}
        onRequestClose={() => setRegister(false)}
        style={{
          content: {
            width: window.matchMedia("(max-width:768px)").matches
              ? "75%"
              : "26%",
            height: "380px",
            overflow: "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
            padding: "1%",
          },
          overlay: {
            background: "rgba(0,0,0,0.6)",
            filter: `blur('5px')`,
            zIndex: "96",
          },
        }}
      >
        <div className="">
          <div
            style={{ borderBottom: "1px outset #E2E2E2" }}
            className="d-flex justify-content-between"
          >
            <h5 style={{ color: "#CE665B" }}>Register</h5>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setRegister(false)}
            >
              <Close style={{ color: "#CE665B" }} />
            </span>
          </div>
          <div className="mt-3">
            <p style={{ fontWeight: "500" }} className=" mx-1">
              Please Enter your Details
            </p>
          </div>
          <div className="input2 mt-3 mx-1">
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              name="text"
              id=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input3 mt-3 mx-1">
            <input
              type="email"
              placeholder="Enter Email"
              className="form-control"
              name="text"
              id=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <div className="gender px-2 d-flex align-items-center justify-content-between mt-3">
            <span>Gender:</span>
            <span>
              <input
                name="gender"
                type="radio"
                onClick={() => {
                  setGender("Male");
                }}
                checked={gender==="Male"}
              />{" "}
              Male
            </span>
            <span>
              <input
                name="gender"
                type="radio"
                onClick={() => {
                  setGender("Female");
                }}
              />{" "}
              Female
            </span>
          </div> */}
          {/* <div className="input3 mt-3 mx-1">
            <input
              type="text"
              placeholder="Refercode(optional)"
              className="form-control"
              name="text"
              id=""
            />
          </div> */}
          <div className="mt-3">
            <button
              // onClick={() => setOtp(true) || setRegister(false)}
              onClick={(e) => onSubmitRegister(e)}
              style={{ backgroundColor: "#B93C5B" }}
              className="btn w-100 text-white"
            >
              Register
            </button>
          </div>

          <div className="mt-3">
            <p
              className="text-center justify-content-center d-flex align-items-center"
              style={{ fontSize: "8pt" }}
            >
              <input
                style={{ backgroundColor: "#B93C5B" }}
                type="checkbox"
                name=""
                id=""
                onClick={() => setTermCheckbox(!termCheckbox)}
              />
              &nbsp; I have read &nbsp;
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                Terms and Conditions
              </span>
            </p>
          </div>
        </div>
      </ReactModal>

      {/*mobile navbar*/}
      <div id="mobnav" className="container-fluid bg-white ">
        <div className="row mx-auto justify-content-center align-items-center">
          <div className="col-2 col-sm-2 col-md-2">
            <span onClick={() => setmodal2(true)} style={{ color: "#000" }}>
              <VscThreeBars className="fs-2" />
            </span>
          </div>
          <div className="col-7 col-sm-8 col-md-8 d-flex justify-content-center mt-3">
            {/* <LocationOn style={{ color: "#DF356F" }} />
                <p id="location" class="search_location">
                  25, Shivaji Marg, Block..
                </p> */}
          </div>

          <div className="col-3 d-flex justify-content-around col-sm-1 col-md-2  mt-3">
            {!isAuth ? (
              <>
                <p
                  onClick={() => dispatch(SetLoginModalOpen(true))}
                  style={{ color: "#000", fontWeight: "lighter" }}
                >
                  <PersonRounded />
                </p>
              </>
            ) : (
              <>
                <p
                  onClick={() => navigate("/profile")}
                  style={{ color: "#000", fontWeight: "lighter" }}
                >
                  <PersonRounded />
                </p>
              </>
            )}

            <div className="">
              {isAuth ? (
                <>
                  <p
                    onClick={() => navigate("/cart")}
                    style={{ color: "#000", fontWeight: "lighter" }}
                  >
                    <Cart4 />
                  </p>
                </>
              ) : (
                <>
                  <p
                    onClick={() => dispatch(SetLoginModalOpen(true))}
                    style={{ color: "#000", fontWeight: "lighter" }}
                  >
                    <Cart4 />
                  </p>
                </>
              )}
            </div>
            <span
              style={{
                backgroundColor: "#000",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
              }}
              className="text-white d-inline-block d-flex justify-content-center align-items-center text-align-center"
            >
              {CartItems.length}
            </span>
          </div>
        </div>

        <div id="modal" style={{ position: "relative" }}>
          <ReactModal
            className="p-2"
            isOpen={modal2}
            onRequestClose={() => setmodal2(false)}
            style={{
              content: {
                width: "80%",
                height: "100%",
                overflow: "auto",
                backgroundColor: "#ffffff",
              },
              overlay: { backgroundColor: "rgba(0,0,0,0)", zIndex: "96" },
            }}
          >
            <div
              style={{
                backgroundColor: "#AA2B57",
                height: "132px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderBottom: "1px",
              }}
            >
              <div
                className="row h-100 text-white w-100 align-items-center mx-auto"
                style={{ backgroundColor: "" }}
              >
                <div className="col-4">
                  <img
                    width="75"
                    style={{ borderRadius: "50%" }}
                    height="75"
                    src="/assets/stlogo1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                {!isAuth ? (
                  <>
                    <div
                      className="col-8"
                      onClick={() =>
                        dispatch(SetLoginModalOpen(true)) && setmodal2(false)
                      }
                    >
                      <h4>Login</h4>
                      <h6>to your Account</h6>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-8">
                      <h4>{loginData.Name}</h4>
                    </div>
                  </>
                )}
              </div>
              <div
                className="mt-3 p-2  "
                style={{ backgroundColor: "#F8F8F8" }}
              >
                <span
                  onClick={() => navigate("/") || setmodal2(false)}
                  className="h6 m-2 bg-white p-2 align-items-center d-flex"
                >
                  <img
                    src="	https://www.yesmadam.com/static/images/home-icon.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="fw-bold mt-3 mx-1 ">Home</p>
                </span>
                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/policies.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Privacy Policy</p>
                </span>
                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/faq.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">FAQ</p>
                </span>

                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/rate.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Rate Us</p>
                </span>
                {/* <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="https://www.yesmadam.com/static/images/blog.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Blog</p>
                </span> */}
                <span className="h6 m-2 align-items-center bg-white p-2 d-flex">
                  <img
                    src="	https://www.yesmadam.com/static/images/help.svg"
                    style={{
                      color: "#AA2B57",
                      width: "20px",
                      height: "20px",
                      fontWeight: "bolder",
                    }}
                    alt=""
                    className=" h3"
                  />
                  <p className="mx-1 mt-3 fw-bold">Help & Support</p>
                </span>

                {isAuth ? (
                  <>
                    <span
                      onClick={() => navigate("/profile") || setmodal2(false)}
                      className="h6 m-2 align-items-center bg-white p-2 d-flex"
                    >
                      <span>
                        <PersonRounded style={{ color: "#AA2B57]" }} />
                      </span>
                      <p className="mx-1 mt-3 fw-bold">Profile</p>
                    </span>
                    <span
                      onClick={() => navigate("/checkout") || setmodal2(false)}
                      className="h6 m-2 align-items-center bg-white p-2 d-flex"
                    >
                      <span>
                        <CheckOutlined style={{ color: "#AA2B57" }} />
                      </span>
                      <p className="mx-1 mt-3 fw-bold">Checkout</p>
                    </span>
                    <span
                      onClick={() => dispatch(signout()) || setmodal2(false)}
                      className="h6 m-2 align-items-center bg-white p-2 d-flex"
                    >
                      <img
                        src="https://www.yesmadam.com/static/images/logout.svg"
                        style={{
                          color: "#AA2B57",
                          width: "20px",
                          height: "20px",
                          fontWeight: "bolder",
                        }}
                        alt=""
                        className=" h3"
                      />
                      <p className="mx-1 mt-3 fw-bold">Log Out</p>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default Header;
