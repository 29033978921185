import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSelectedCategory } from "../../redux/cart/CartSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { setSelectedSaloon } from "../../redux/saloon/SaloonSlice";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";
import { Card } from "antd";

const AvailableSalonFemale = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menAndWomenCategory } = useSelector((store) => store.category);
  const { subCategorytotal } = useSelector((store) => store.subCategory);

  const [subCategories, setSubCategories] = useState("");
  const [salonList, setSalonList] = useState([]);

  useEffect(() => {
    const getSaloonList = async () => {
      const url = `${Baseurl}/api/v1/saloon/saloonfor/Female`;
      const response = await axios.get(url);
     
      if (response.data.success) {
        console.log(response.data.saloon[0]);
        setSalonList(response.data.saloon);
      }
    };
    getSaloonList();
  }, []);

  const handleSelectedSaloon = async (indSaloon) => {
    await dispatch(setSelectedSaloon(indSaloon._id));
    navigate(`/saloonNearMe`);
  };

  return (
    <>
      <div className="affordable">
        <div id="heading" className="px-2 d-flex justify-content-start">
          <h4>
            <div>
              Book Appointment For Female Saloons
            </div>
          </h4>
        </div>
        <div className="mt-1">
          <Swiper
            slidesPerView={5}
            spaceBetween={10}
            freeMode={true}
            // pagination={{
            //   clickable: false,
            // }}
            modules={[Pagination, Mousewheel, Autoplay]}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              460: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              720: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1080: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
            className="mySwiper"
            style={{ backgroundColor: "#fff" }}
          >
            {salonList.map((salon, index) => (
              <SwiperSlide
                key={index}
                onClick={() => handleSelectedSaloon(salon)}
                style={{cursor:"pointer"}}
              >
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                  className="card border-0 w-100 p-2  mx-auto "
                >
                  <img
                    style={{
                      height: "150px",
                      width: "100%",
                      borderRadius: "10%",
                    }}
                    src={salon.desktopImage}
                    alt=""
                    className="img-fluid mx-auto ps-2 pe-2"
                  />
                  <div className="salon-footer">
                    <p className="text-center">
                      {salon.name.length > 17
                        ? `${salon.name.slice(0, 17)}...`
                        : salon.name}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AvailableSalonFemale;
