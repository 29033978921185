import React, { useEffect, useState } from "react";
import { FaGooglePlay } from "react-icons/fa";
import {
  Apple,
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export function SalonAthome() {
  const { categorytotal } = useSelector((store) => store.category);
  const { subCategorytotal, subCategoryLoading } = useSelector(
    (store) => store.subCategory
  );
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [category, setCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (params.slugurl && categorytotal.length > 0) {
      const category = categorytotal.find(
        (cat) => cat.slugUrl === params.slugurl
      );
      if (category) {
        setCategory(category);
        const getSubCategory = subCategorytotal.filter(
          (cat) => cat.categoryId === category._id
        );
        setSubCategories(getSubCategory);
      }
    }
  }, [params.slugurl, categorytotal, subCategorytotal]);

  return (
    <div id="salonAthome" className="container-fluid-lg">
      <div id="sam" className="conatainer shadow-sm">
        <div
          // style={{ borderTop: "1px inset black" }}
          className="container-fluid-lg heading shadow bg-white w-100 d-flex align-items-center"
        >
          <div onClick={() => navigate("/")}>
            <h2 style={{ color: "#DD638C" }}>
              <IoIosArrowBack className="fw-bolder" />
            </h2>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-auto mobileVersion">
            <h4
              style={{ color: "#000", textTransform: "uppercase" }}
              className="text-center fw-bold"
            >
              {category.name}
            </h4>
          </div>
        </div>
      </div>
      <div id="samadd" className="container p-2">
        <div className="row mx-auto justify-content-between h-100 w-100 ">
          <div className="col-lg-8 col-12 ">
            {/* <h4 className="desktopVersion">
              <span style={{ borderBottom: "3px solid #E15866" }}>Salon</span>{" "}
              At Home
               {category.name}
            </h4> */}
            {/* <hr className="mt-3" /> */}
            <div id="subcat" className="mt-3">
              <h5>Select your services</h5>
              <div className="container-fluid mt-4">
                <div className="row mx-auto w-100">
                  {subCategories.map((indCat, index) => (
                    <div
                      className="col-lg-2 col-4"
                      key={index}
                      onClick={() => {
                        navigate(`/${category.slugUrl}/${indCat.name}`);
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={indCat.desktopImage}
                        alt=""
                      />
                      <p className="text-center">{indCat.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div id="abc"  className="col-lg-4 col-12">
            {/* <div className="card bg-white w-100 rounded ">
              <img
                src="https://cdn.yesmadam.com/images/live/app/banner/Beauty-category-banner-23-3-23.jpg"
                alt=""
                className="img-fluid h-100 w-100 mx-auto m-2"
              />
            </div>
            <div>
              <div>
                <img
                  src="https://cdn.yesmadam.com/images/live/app/banner/price-beauty-category-footer-banner-15-10-22.jpg"
                  alt=""
                  className="img-fluid mx-auto w-100"
                />
              </div>
              <div className="mt-3 w-100" id="scroll">
                <img
                  src="https://cdn.yesmadam.com/images/live/app/banner/beautician-type-beauty-category-footer-banner-15-10-22.jpg"
                  alt=""
                  className="img-fluid w-100 h-100"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
