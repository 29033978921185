import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { Saloonhome } from "./pages/Saloonhome";
import { useEffect } from "react";
import { SalonAthome } from "./pages/salonAthome";
import CheckoutPage from "./pages/checkout";
import Profile from "./pages/profile";
import Waxing from "./pages/waxing";
import Facial from "./pages/facial";
import Notfound from "./pages/notfound";
import { Toaster } from "react-hot-toast";
import Medianav from "./pages/medianav";
import MainLayout from "./components/MainLayout";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "./redux/category/categorySlice";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import CartPage from "./pages/CartPage";
import Payments from "./pages/Payments";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import SuccessfulPayment from "./pages/SuccessfulPayment";
import FailurePayment from "./pages/FailurePayment";
import Privacy from "./pages/Privacy";
import TermsNdConditions from "./pages/TermsNdConditions";
import Faqs from "./pages/Faqs";
import { getTag } from "./redux/tags/TagSlice";
import CancellationPage from "./pages/CancellationPage";
import RefundPage from "./pages/RefundPage";
import ShippingPolicy from "./pages/ShippingPolicy";
import SubcategoryPage from "./pages/SubcategoryPage";
import SalonPage from "./pages/Saloon/SaloonPage";
import {
  getAllCategoriesBySaloon,
  getSaloonList,
} from "./redux/saloon/SaloonSlice";
import SalonList from "./pages/SalonList";
import SaloonSearchPage from "./pages/Saloon/SaloonSearchPage";
import EditAddressModel from "./pages/EditAddressModel";
import AnimalSubCategory from "./pages/SubcategoryPage/AnimalSubCategory";
import ProductCheckout from "./pages/ProductCheckout";
import AddSaloon from "./pages/AddSaloon";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { isAuth } = useSelector((store) => store.Authentication);
  const { superCategorytotal, isSuperCategoryAvailable, superCategoryLoading } =
    useSelector((store) => store.superCategory);

  const { categorytotal, categoryLoading, isCategoryAvailable } = useSelector(
    (store) => store.category
  );

  const { subCategorytotal, isSubCategoryAvailable, subCategoryLoading } =
    useSelector((store) => store.subCategory);

  const { tagtotal, isTagAvailable, tagLoading } = useSelector(
    (store) => store.Tag
  );
  const { saloonCategoryLoading, allCategoriesFromSaloon } = useSelector(
    (store) => store.saloon
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSuperCategoryAvailable || superCategorytotal.length === 0) {
      dispatch(getSuperCategory());
    }
    if (!isCategoryAvailable || categorytotal.length === 0) {
      dispatch(getCategory());
    }
    if (!isSubCategoryAvailable || subCategorytotal.length === 0) {
      dispatch(getSubCategory());
    }

    if (!isTagAvailable || tagtotal.length === 0) {
      dispatch(getTag());
    }
    if (!saloonCategoryLoading || allCategoriesFromSaloon.length === 0) {
      dispatch(getAllCategoriesBySaloon());
    }
    dispatch(getSaloonList());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (superCategoryLoading) {
        dispatch(getSuperCategory());
      }
      if (categoryLoading) {
        dispatch(getCategory());
      }
      if (subCategoryLoading) {
        dispatch(getSubCategory());
      }
      if (tagLoading) {
        dispatch(getTag());
      }
    }, 15000);
  }, []);

  useEffect(() => {
    try {
      //
    } catch (error) {}
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Toaster />
      <div class="nav-sticky container-fluid">{/* <Header /> */}</div>
      <Routes>
        <Route path="/" exact element={<MainLayout />}>
          <Route path="/" index element={<Saloonhome />} />
          <Route path="/saloonhome/:slugurl" element={<SalonAthome />} />
          <Route
            path="/pet/:caturl/:subcaturl"
            element={<AnimalSubCategory />}
          />
          <Route path="/:caturl/:subcaturl" element={<SubcategoryPage />} />
          {/* <Route path="/:caturl/:subcaturl" element={<Waxing />} /> */}
          <Route path="/cart" element={<CartPage />} />
          <Route path="/registersaloon" element={<AddSaloon />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/product-checkout" element={<ProductCheckout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/success" element={<SuccessfulPayment />} />
          <Route path="/failure" element={<FailurePayment />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/facial" element={<Facial />} />

          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/terms-conditions" element={<TermsNdConditions />} />
          <Route path="/cancellation-policy" element={<CancellationPage />} />
          <Route path="/refundPage" element={<RefundPage />} />
          <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
          {/* Saloon Pages Starts from here */}
          <Route path="/saloonNearMe" element={<SalonPage />} />
          <Route path="/search" element={<SaloonSearchPage />} />
          <Route path="/salonlist" element={<SalonList />} />
          <Route path="/editAddress/:id" element={<EditAddressModel />} />
          {/* <Route path="*" element={<Notfound />} /> */}
        </Route>
      </Routes>
      {/* <Footer /> */}
      <div class="container-fluid-lg d-block">
        <Medianav />
      </div>
    </BrowserRouter>
  );
}

export default App;
{
  /* <Route path="/" index element={<HomePage />} /> */
}
