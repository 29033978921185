import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  OrderShopTotal: [].slice().reverse(),
  currentShopOrder: '',
  orderShopLoading: true,
};

const OrderSlice = createSlice({
  name: 'OrderShop',
  initialState,
  reducers: {
    ShopOrderget(state, action) {
      state.OrderShopTotal = action.payload;
      state.orderShopLoading = false;
    },
    ShopClearorder(state, action) {
      state.OrderShopTotal = [];
    },
    ShopClearCurrentorder(state, action) {
      state.currentShopOrder = '';
    },
    ShopNewOrder(state, action) {
      if (action.payload.success) {
        state.OrderShopTotal = [
          action.payload.orderShop,
          ...state.OrderShopTotal,
        ];
        state.currentShopOrder = action.payload.orderShop;
      }
    },
  },
});

export const {ShopOrderget, ShopClearorder, ShopClearCurrentorder, ShopNewOrder} =
  OrderSlice.actions;
export default OrderSlice.reducer;
