import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import AddressModal from "./AddressModal";
import { SetAddressModalOpen } from "../redux/authentication/AuthenticationSlice";
import moment from "moment/moment";
import { setCheckoutAllData } from "../redux/cart/CartSlice";
import toast from "react-hot-toast";
import { IoIosArrowBack } from "react-icons/io";
import { setShopCheckoutAllData } from "../redux/cart/ShopCartSlice";
import { setHomeSalonCartCheckoutAllData } from "../redux/cart/HomeSalon";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuth, loginData, currentAddress, isAddressModalOpen, address } =
    useSelector((store) => store.Authentication);
  const { CartItems } = useSelector((state) => state.Cart);
  const { ShopCartItems } = useSelector((state) => state.ShopCart);
  const { HomeSalonCartItems } = useSelector((state) => state.HomeSalonCart);
  const [dateslist, setdateslist] = useState([]);
  const [morningSlot, setmorningSlot] = useState([]);
  const [afternoonslot, setafternoonslot] = useState([]);
  const [eveningslot, seteveningslot] = useState([]);
  const [availabileSlots, setavailabileSlots] = useState(0);
  const [currentslots, setcurrentslots] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [paymentType, setpaymentType] = useState("CashOnDelivery");
  const [type, setType] = useState("");
  const [diffCartItems, setDiffCartItems] = useState("");
  useEffect(() => {
    const { id, name } = location.state || {};
    if (name) {
      setType(name);
    }
    if (name === "Shop") {
      setDiffCartItems(ShopCartItems);
    } else if (name === "HomeSaloon") {
      setDiffCartItems(HomeSalonCartItems);
    } else if (name === "Product") {
      setDiffCartItems(CartItems);
    }
  }, [location.state]);
  const morSlot = [
    { name: "10-11 AM", time: "10" },
    { name: "11-12 AM", time: "11" },
  ];
  const maftSlot = [
    { name: "10-11 AM", time: "10" },
    { name: "11-12 AM", time: "11" },
    { name: "12-1 PM", time: "12" },
    { name: "1-2 PM", time: "13" },
    { name: "2-3 PM", time: "14" },
    { name: "3-4 PM", time: "15" },
    { name: "4-5 PM", time: "16" },
    { name: "5-6 PM", time: "17" },
  ];

  const eveSlot = [
    { name: "4-5 PM", time: "16" },
    { name: "5-6 PM", time: "17" },
  ];
  useEffect(() => {
    let day = new Date();
    setSelectedDate(day);

    let datearray = [];

    for (let index = 0; index < 7; index++) {
      let todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + Number(index));
      datearray = [...datearray, todayDate];
    }
    datearray = [...datearray];
    setdateslist(datearray);
  }, []);

  useEffect(() => {
    let day = new Date();
    if (moment(selectedDate).format("DD") === moment(day).format("DD")) {
      if (
        moment(selectedDate).format("H").toString() >= "0" ||
        moment(selectedDate).format("H").toString() >= "1" ||
        moment(selectedDate).format("H").toString() <= "9"
      ) {
        const filtermorslot = morSlot;
        const filteraftslot = maftSlot;
        const filtereveslot = eveSlot;
        const availslot = [
          ...filtermorslot,
          ...filteraftslot,
          ...filtereveslot,
        ];
        if (availslot.length > 0) {
          const curr = availslot[0].name;
          setcurrentslots(curr);
        }

        setavailabileSlots(availslot.length);
        setmorningSlot([...filtermorslot]);
        setafternoonslot([...filteraftslot]);
        seteveningslot([...filtereveslot]);
      }
      if (
        moment(selectedDate).format("H").toString() >= "10" ||
        moment(selectedDate).format("H").toString() <= "14"
      ) {
        const filtermorslot = morSlot.filter(
          (data) => data.time > moment(selectedDate).format("H").toString()
        );
        const filteraftslot = maftSlot.filter(
          (data) => data.time > moment(selectedDate).format("H").toString()
        );
        const filtereveslot = eveSlot.filter(
          (data) => data.time > moment(selectedDate).format("H").toString()
        );
        const availslot = [
          ...filtermorslot,
          ...filteraftslot,
          ...filtereveslot,
        ];
        if (availslot.length > 0) {
          const curr = availslot[0].name;
          setcurrentslots(curr);
        }
        setavailabileSlots(availslot.length);
        setmorningSlot([...filtermorslot]);
        setafternoonslot([...filteraftslot]);
        seteveningslot([...filtereveslot]);
      }
      if (moment(selectedDate).format("H").toString() >= "15") {
        const filtermorslot = morSlot;
        const filteraftslot = maftSlot;
        const filtereveslot = eveSlot;
        const availslot = [
          ...filtermorslot,
          ...filteraftslot,
          ...filtereveslot,
        ];
        if (availslot.length > 0) {
          const curr = availslot[0].name;
          setcurrentslots(curr);
        }
        setavailabileSlots(availslot.length);
        setmorningSlot([...filtermorslot]);
        setafternoonslot([...filteraftslot]);
        seteveningslot([...filtereveslot]);
        const removedate = dateslist.filter(
          (data) => moment(data).format("DD") !== moment(day).format("DD")
        );
        setdateslist(removedate);
        let adddate = new Date();
        adddate.setDate(adddate.getDate() + Number(1));
        setSelectedDate(adddate);
      }
    } else {
      const filtermorslot = morSlot;
      const filteraftslot = maftSlot;
      const filtereveslot = eveSlot;
      const availslot = [...filtermorslot, ...filteraftslot, ...filtereveslot];

      if (availslot.length > 0) {
        const curr = availslot[0].name;
        setcurrentslots(curr);
      }

      setavailabileSlots(availslot.length);
      setmorningSlot([...filtermorslot]);
      setafternoonslot([...filteraftslot]);
      seteveningslot([...filtereveslot]);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (type === "Shop" && ShopCartItems.length === 0) {
      navigate("/");
    } else if (type === "HomeSaloon" && HomeSalonCartItems.length === 0) {
      navigate("/");
    } else if (type === "Product" && CartItems.length === 0) {
      navigate("/");
    }
  }, [type]);

  const proceedClick = (e) => {
    const selectedDateString = moment(selectedDate).format("DD-MM-YYYY");
    if (address.length > 0) {
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;
      let concatenatedNames = "";
      for (let index = 0; index < diffCartItems.length; index++) {
        concatenatedNames += diffCartItems[index].ProductName + ",";
      }

      const formData = {
        Address: addressString,
        AreaName: currentAddress.AName,
        Mobile: loginData.Mobile,
        ServicePreferedDate: selectedDateString,
        ServicePreferedTime: currentslots,

        OrderProducts: diffCartItems,
        orderDisplayName: concatenatedNames,
      };
      dispatch(setShopCheckoutAllData(formData));
      const state = { name: type };
      navigate("/payments", { state });
    } else {
      dispatch(SetAddressModalOpen(true));
      toast.error("Please Add Address");
    }
  };
  const homeSaloonClick = (e) => {
    const selectedDateString = moment(selectedDate).format("DD-MM-YYYY");
    if (address.length > 0) {
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;
      let concatenatedNames = "";
      for (let index = 0; index < diffCartItems.length; index++) {
        concatenatedNames += diffCartItems[index].ProductName + ",";
      }

      const formData = {
        Address: addressString,
        AreaName: currentAddress.AName,
        Mobile: loginData.Mobile,
        ServicePreferedDate: selectedDateString,
        ServicePreferedTime: currentslots,

        OrderProducts: diffCartItems,
        orderDisplayName: concatenatedNames,
      };
      dispatch(setHomeSalonCartCheckoutAllData(formData));
      const state = { name: type };
      navigate("/payments", { state });
    } else {
      dispatch(SetAddressModalOpen(true));
      toast.error("Please Add Address");
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="container-fluid-lg">
      <div className="container-fluid-lg shadow bg-white w-100 d-flex align-items-center mobileVersion">
        <div onClick={goBack}>
          <h2 style={{ color: "#DD638C" }}>
            <IoIosArrowBack className="fw-bolder" />
          </h2>
        </div>
        <div className="d-flex justify-content-center align-items-center mx-auto">
          <h4
            style={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "lighter",
              marginTop: "6px",
            }}
            className="text-center fw-bold"
          >
            Checkout
          </h4>
        </div>
      </div>
      <div
        className="addressTimeOrDate"
        style={{
          marginBottom: "4rem",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <div style={{ marginTop: "unset" }} className="timeslot">
          <h6 style={{ textAlign: "start" }}>Address</h6>
          {currentAddress ? (
            <>
              <div className="addressdesc">
                <p>
                  {/* ,{currentAddress.City}, */}
                  {currentAddress.HNo}
                  {currentAddress.Address}
                  {/* <span className="orderBooked ps-1">
                    Order booked for:{currentAddress.AreaDet}
                  </span> */}
                </p>
                <Link to="/profile">
                  <div>
                    <button className="btn text-primary">CHANGE ADDRESS</button>
                  </div>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="addressdesc">
                <div>
                  <button
                    className="btn"
                    onClick={() => dispatch(SetAddressModalOpen(true))}
                  >
                    ADD ADDRESS
                  </button>
                </div>
              </div>
            </>
          )}
          <hr />
        </div>
        <ReactModal
          isOpen={isAddressModalOpen}
          onRequestClose={() => dispatch(SetAddressModalOpen(false))}
          className="rounded "
          style={{
            content: {
              // width: "520px",
              maxWidth: "520px",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: "10px",
              padding: "10px",
              margin: "auto",
              marginTop: "14vh",
            },
            overlay: { backgroundColor: "rgba(0,0,0,.5)", zIndex: "969" },
          }}
        >
          <AddressModal />
        </ReactModal>

        <div className="dateOrtime">
          <div className="timeslot">
            <h6 style={{ textAlign: "start" }}>Select Date</h6>
          </div>
          <div className="date">
            {dateslist.map((date, dateIndex) => (
              <div
                // className="date1"
                className={
                  moment(selectedDate).format("DD") ===
                  moment(date).format("DD")
                    ? "date1 selectDate"
                    : "date1"
                }
                key={dateIndex}
                onClick={() => {
                  setSelectedDate(date);
                  setcurrentslots("");
                }}
              >
                <div>
                  <p>{moment(date).format("dddd")}</p>
                  <p
                    className="day"
                    style={{ fontWeight: "900", fontSize: "20px" }}
                  >
                    {moment(date).format("DD")}
                  </p>
                  <p>{moment(date).format("MMM")}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="timeslot border">
          <div>
            <h6 style={{ textAlign: "start" }}>Select Time</h6>
          </div>

          <div className="time">
            {afternoonslot.map((data, slotIndex) => (
              <span
                key={slotIndex}
                style={{ cursor: "pointer" }}
                onClick={() => setcurrentslots(data.name)}
                className={currentslots === data.name ? "selectSlot" : ""}
              >
                {data.name}
              </span>
            ))}
          </div>
        </div>
        {
         type === "HomeSaloon" &&  
         <div
         className="d-flex justify-content-center mt-3"
         onClick={() => homeSaloonClick()}
       >
         <button
           style={{ backgroundColor: "#c8386b", height: "35px" }}
           className="btn text-white w-75"
         >
           PROCEED
         </button>
       </div>
        }
        {
         type === "Shop" &&  
         <div
         className="d-flex justify-content-center mt-3"
         onClick={() => proceedClick()}
       >
         <button
           style={{ backgroundColor: "#c8386b", height: "35px" }}
           className="btn text-white w-75"
         >
           PROCEED
         </button>
       </div>
        }
      </div>
    </div>
  );
};

export default Checkout;
