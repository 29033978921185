import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSelectedCategory } from "../../redux/cart/CartSlice";

const AffordableHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menAndWomenCategory } = useSelector((store) => store.category);
  const { subCategorytotal } = useSelector((store) => store.subCategory);

  const [subCategories, setSubCategories] = useState("");

  const navigationClick = (cat) => {
    if (cat !== "") {
      const getSubCategory = subCategorytotal.filter(
        (subcat) => subcat.categoryId === cat._id
      );
      setSubCategories(getSubCategory[0]);
      navigate(`/${cat.slugUrl}/${getSubCategory[0].name}`);
    }
  };
  return (
    <>
      <div className="affordable">
        <div id="heading" className="px-2 d-flex justify-content-start">
          <h4 >
            India 's Most Affordable Home Salon
          </h4>
        </div>
        <div id="catogory" className="row mx-auto align-items-center w-100 mt-1">
          {menAndWomenCategory.map((indSupCat, index) => (
            <div className="col-lg-2 col-6 col-md-4" key={index}>
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  cursor:"pointer"
                }}
                // onClick={() => {
                //   navigate(`/saloonhome/${indSupCat.slugUrl}`);
                // }}
                onClick={() => {
                  navigationClick(indSupCat);
                }}
                className="card border-0 w-100 p-2  mx-auto "
              >
                <img
                  style={{ height: "150px", width: "100%", borderRadius: "5%" }}
                  src={indSupCat.desktopImage}
                  alt=""
                  className="img-fluid mx-auto"
                />
                <div className="card-text">
                  <p className="text-center">{indSupCat.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AffordableHome;
