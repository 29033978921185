import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  HomeSalonCartItems: localStorage.getItem("HomeSalonCartItems")
    ? JSON.parse(localStorage.getItem("HomeSalonCartItems"))
    : [],
  selectedHomeSalonCartCategory: localStorage.getItem("selectedHomeSalonCartCategory")
    ? JSON.parse(localStorage.getItem("selectedHomeSalonCartCategory"))
    : "",
  HomeSalonCartProductTotal: [],
  homeSalonCartTotalQuantity: 0,
  homeSalonCartTotalAmount: 0,
  homeSalonCartNetPayable: 0,
  homeSalonCartTotalMrp: 0,
  homeSalonCartTotalPrice: 0,
  homeSalonCartPackQuantity: 0,
  homeSalonCartCouponDiscountAmount: 0,
  homeSalonCartLogisticsAmount: 0,
  homeSalonCartLoading: true,
  homeSalonCartProductUpdated: false,
  homeSalonCartCheckoutData: "",
};
const HomeSalonCartSlice = createSlice({
  name: "HomeSalonCart",
  initialState,
  reducers: {
    allHomeSalonCartProductUpdate(state, action) {
      state.HomeSalonCartProductTotal = action.payload;
      state.homeSalonCartProductUpdated = true;
      localStorage.setItem("HomeSalonCartProductTotal", JSON.stringify(state.HomeSalonCartProductTotal));
    },
    SetHomeSalonCartSelectedCategory(state, action) {
      state.selectedHomeSalonCartCategory = action.payload;
      localStorage.setItem(
        "selectedHomeSalonCartCategory",
        JSON.stringify(state.selectedHomeSalonCartCategory)
      );
    },
    setHomeSalonCartCheckoutAllData(state, action) {
      state.homeSalonCartCheckoutData = action.payload;
    },
    homeSalonCartReplaceClick(state, action) {
      // state.homeSalonCartNetPayable = action.payload;
      const tempProducts = action.payload;
      state.HomeSalonCartItems = [action.payload];
      state.homeSalonCartTotalQuantity++;
      state.homeSalonCartPackQuantity++;

      state.homeSalonCartTotalAmount =
        Number(state.homeSalonCartTotalAmount) + Number(action.payload.Price);

      if (state.homeSalonCartTotalAmount >= 400) {
        state.homeSalonCartLogisticsAmount = 0;
      } else {
        state.homeSalonCartLogisticsAmount = 40;
      }
      state.homeSalonCartNetPayable =
        Number(state.homeSalonCartTotalAmount) + Number(state.homeSalonCartLogisticsAmount);

      state.homeSalonCartLoading = !state.homeSalonCartLoading;
      localStorage.setItem("HomeSalonCartItems", JSON.stringify(state.HomeSalonCartItems));
    },
    addtoHomeSalonCart(state, action) {
      // Check if the cart should be cleared based on the 'for' value condition
      if (state.HomeSalonCartItems.length !== 0) {
        const cartForValue = state.HomeSalonCartItems[0].for;
        const actionForValue = action.payload.for;

        if (cartForValue !== actionForValue) {
          state.HomeSalonCartItems = [];
        } else if (
          cartForValue === "Saloon" &&
          state.HomeSalonCartItems[0].salonId !== action.payload.salonId
        ) {
          state.HomeSalonCartItems = [];
        }
      }
      // Find the index of the item in the cart
      const itemIndex = state.HomeSalonCartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );
      // If the item already exists in the cart, update its quantity and totals
      if (itemIndex >= 0) {
        state.homeSalonCartTotalQuantity++;
        const item = state.HomeSalonCartItems[itemIndex];
        item.Qty += 1;
        item.TotalMrp = item.Mrp * item.Qty;
        item.TotalPrice = item.Price * item.Qty;
        item.TotalAmount = item.Price * item.Qty;
        state.homeSalonCartTotalAmount += item.Price;
        state.homeSalonCartNetPayable = state.homeSalonCartTotalAmount;
      } else {
        // If the item does not exist in the cart, add it
        const tempProduct = action.payload;
        state.HomeSalonCartItems.push(tempProduct);
        state.homeSalonCartTotalQuantity++;
        state.homeSalonCartPackQuantity++;
        state.homeSalonCartTotalAmount += tempProduct.Price;
        state.homeSalonCartNetPayable = state.homeSalonCartTotalAmount;
      }

      // Toggle loading state and save cart items to local storage
      state.homeSalonCartLoading = !state.homeSalonCartLoading;
      localStorage.setItem("HomeSalonCartItems", JSON.stringify(state.HomeSalonCartItems));
    },

    decrementHomeSalonCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.HomeSalonCartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );

      if (itemIndex >= 0) {
        if (state.HomeSalonCartItems[itemIndex].Qty > 1) {
          let quant = state.HomeSalonCartItems[itemIndex].Qty - 1;
          state.HomeSalonCartItems[itemIndex].Qty = state.HomeSalonCartItems[itemIndex].Qty - 1;
          state.HomeSalonCartItems[itemIndex].TotalMrp =
            Number(state.HomeSalonCartItems[itemIndex].Mrp) * Number(quant);

          state.HomeSalonCartItems[itemIndex].TotalPrice =
            Number(state.HomeSalonCartItems[itemIndex].Price) * Number(quant);
          state.HomeSalonCartItems[itemIndex].TotalAmount =
            Number(state.HomeSalonCartItems[itemIndex].Price) * Number(quant);
        } else if (state.HomeSalonCartItems[itemIndex].Qty === 1) {
          const nextCartItems = state.HomeSalonCartItems.filter(
            (cartItem) => cartItem.ProductId !== action.payload.ProductId
          );
          state.homeSalonCartPackQuantity--;
          state.HomeSalonCartItems = nextCartItems;
        }

        state.homeSalonCartTotalAmount =
          Number(state.homeSalonCartTotalAmount) + Number(action.payload.Price);

        // if (state.homeSalonCartTotalAmount >= 500) {
        //   state.homeSalonCartLogisticsAmount = 0;
        // } else {
        //   state.homeSalonCartLogisticsAmount = 40;
        // }

        state.homeSalonCartNetPayable = Number(state.homeSalonCartTotalAmount);
        state.homeSalonCartLoading = !state.homeSalonCartLoading;
      }

      localStorage.setItem("HomeSalonCartItems", JSON.stringify(state.HomeSalonCartItems));
    },
    removefromHomeSalonCart(state, action) {
      const nextCartItems = state.HomeSalonCartItems.filter(
        (cartItem) => cartItem.ProductId !== action.payload.ProductId
      );
      state.homeSalonCartPackQuantity--;
      state.HomeSalonCartItems = nextCartItems;
      state.homeSalonCartLoading = !state.homeSalonCartLoading;
      localStorage.setItem("HomeSalonCartItems", JSON.stringify(state.HomeSalonCartItems));
    },
    getHomeSalonCartTotal(state, action) {
      state.homeSalonCartTotalAmount = 0;
      state.homeSalonCartNetPayable = 0;
      state.homeSalonCartTotalMrp = 0;
      state.homeSalonCartTotalPrice = 0;
      const totalitem = state.HomeSalonCartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.homeSalonCartTotalAmount =
          Number(state.homeSalonCartTotalAmount) +
          Number(state.HomeSalonCartItems[i].TotalAmount);

        state.homeSalonCartTotalMrp =
          Number(state.homeSalonCartTotalMrp) + Number(state.HomeSalonCartItems[i].TotalMrp);
        state.homeSalonCartTotalPrice =
          Number(state.homeSalonCartTotalPrice) + Number(state.HomeSalonCartItems[i].TotalPrice);
      }

      // if (state.homeSalonCartTotalAmount >= 500) {
      //   state.homeSalonCartLogisticsAmount = 0;
      // } else {
      //   state.homeSalonCartLogisticsAmount = 40;
      // }
      state.homeSalonCartNetPayable = Number(state.homeSalonCartTotalAmount);
    },
    clearHomeSalonCart(state, action) {
      state.HomeSalonCartItems = [];
      state.homeSalonCartTotalQuantity = 0;
      state.homeSalonCartTotalAmount = 0;
      state.homeSalonCartNetPayable = 0;
      state.homeSalonCartTotalMrp = 0;
      state.homeSalonCartPackQuantity = 0;
      state.homeSalonCartLoading = !state.homeSalonCartLoading;
      localStorage.setItem("HomeSalonCartItems", JSON.stringify(state.HomeSalonCartItems));
    },
  },
});

export const {
  allHomeSalonCartProductUpdate,
  addtoHomeSalonCart,
  decrementHomeSalonCart,
  removefromHomeSalonCart,
  getHomeSalonCartTotal,
  clearHomeSalonCart,
  setHomeSalonCartCheckoutAllData,
  SetHomeSalonCartSelectedCategory,
  homeSalonCartReplaceClick,
} = HomeSalonCartSlice.actions;
export default HomeSalonCartSlice.reducer;
