import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { setProductBySubcat } from "../redux/subCategory/subCategorySlice";

const Media1 = (props) => {
  const { subCategorytotal, subCategoryLoading } = useSelector(
    (store) => store.subCategory
  );
  const { tagtotal, tagLoading } = useSelector((store) => store.Tag);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [defaultSubcat, setDefaultSubcat] = useState("");
  const [selectedTag, setselectedTag] = useState("");

  useEffect(() => {
    setSubCategories(props.subcat);
    setTags(props.cattag);
    setSubCategoryName(props.subCategoryName);
  }, [
    props.subcat,
    props.cattag,
    props.subCategoryName,
    props.defaultSubcat,
    props.selectedTag,
  ]);
  const changeCatClick = (indSubCat) => {
    setDefaultSubcat(indSubCat);
    props.getSelectedSubcat(indSubCat);
  };
  const changeProduct = (indSubCat) => {
    setselectedTag(indSubCat);
    props.getSelectedTag(indSubCat);
  };

  const Setting = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    autoplay: false,
  };
  const mobileSettings = {
    speed: 500,
    slidesToShow: 6,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
  };
  let sliderSettings = Setting;

  if (window.matchMedia("(min-width:768px)").matches) {
    sliderSettings = mobileSettings;
  }

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="container-fluid-lg h-100">
      <div className="container-fluid-lg shadow bg-white w-100 d-flex align-items-center">
        <div onClick={goBack}>
          <h2 style={{ color: "#DD638C" }}>
            <IoIosArrowBack className="fw-bolder" />
          </h2>
        </div>
        <div className="d-flex justify-content-center align-items-center mx-auto">
          <h4
            style={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "lighter",
              fontFamily: "cursive",
              marginTop: "6px",
            }}
            className="text-center fw-bold"
          >
            {subCategoryName}
          </h4>
        </div>
      </div>

      <div
        className="container-fluid-lg mt-2"
        style={{ marginBottom: "-20px" }}
      >
        <div id="media1" className="row mx-auto w-100">
          <Slider {...Setting} {...sliderSettings}>
            {subCategories &&
              subCategories.map((indCat, indexCat) => (
                <div className=" col-lg-2 col-4" key={indexCat}>
                  <div className="test " onClick={() => changeCatClick(indCat)}>
                    <img
                      className={
                        defaultSubcat.name === indCat.name
                          ? "img-fluid mx-auto image_container"
                          : "img-fluid mx-auto "
                      }
                      src={indCat.desktopImage}
                      alt=""
                    />
                    <p
                      className={
                        defaultSubcat.name === indCat.name
                          ? "text-center1 image_container1"
                          : "text-center1  "
                      }
                    >
                      {indCat.name}
                    </p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>

      <div id="whitespace" className="w-100 p-1">
        <ul className="d-flex justify-content-between align-items-center mx-auto">
          {tags &&
            tags.map((tag, indexSubCat) => (
              <li
                className="topselling"
                key={indexSubCat}
                onClick={() => changeProduct(tag)}
              >
                <a
                  style={{ textDecoration: "none" }}
                  className={
                    selectedTag.name === tag.name
                      ? "image_container1"
                      : "text-dark "
                  }
                >
                  {tag.name}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Media1;
