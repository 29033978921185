import React from "react";

const CancellationPage = () => {
  return (
    <>
      <div className="about-heading">
        <h4 className="text-center">
          Cancellation Policy For SONU SALONTREAT PRIVATE LIMITED:
        </h4>
      </div>
      <div className="about-write container-fluid">
        <p>
          While there is no option to cancel the order as we process the orders
          within 24 hours post order confirmation, you can get in touch with
          customer support team for a cancellation request. We will cancel the
          order if the same is not shipped from our warehouse. In such a case we
          will refund any payments already made by you.
        </p>
      </div>
    </>
  );
};

export default CancellationPage;
