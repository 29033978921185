import React, { useState } from "react";
import ReactModal from "react-modal";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  SetAddressModalOpen,
  currAdress,
} from "../redux/authentication/AuthenticationSlice";
import AddressModal from "./AddressModal";

function Profile() {
  const dispatch = useDispatch();
  const { loginData, address, currentAddress, isAddressModalOpen } =
    useSelector((store) => store.Authentication);

  const handleAddress = async (indAddress) => {
    const address = await dispatch(currAdress(indAddress));
  };
  console.log(loginData, "loginData");
  return (
    <div id="profilreal" className="container-fluid-lg">
      <div className="container mt-3">
        <div className="row w-100 mx-auto">
          <div className="col-lg-6 col-12">
            <div className="bg-white p-3">
              <div className="heading">
                <h4>User Details</h4>
              </div>
              <div id="profile" className="mt-1 p-5">
                <div>
                  <div id="profileName">
                    <input
                      type="text"
                      className="form-control w-100"
                      name="name"
                      value={loginData.Name}
                    />
                    {loginData.Name ? (
                      <></>
                    ) : (
                      <label id="label1" htmlFor="name">
                        Name
                      </label>
                    )}
                  </div>
                  <div id="mobNumber" className="mt-4">
                    <input
                      type="text"
                      className="form-control w-100"
                      name="name"
                      value={loginData.Mobile}
                    />
                    {loginData.Mobile ? (
                      <></>
                    ) : (
                      <label id="label2" htmlFor="name">
                        Mobile Number
                      </label>
                    )}
                  </div>
                  <div id="email" className="mt-4">
                    <input
                      type="text"
                      className="form-control w-100"
                      name="name"
                      value={loginData.Email}
                    />
                    {loginData.Email ? (
                      <></>
                    ) : (
                      <label id="label3" htmlFor="name">
                        Email ID
                      </label>
                    )}
                  </div>
                  {/* <div
                    id="gender"
                    className="border p-1 rounded d-flex flex-wrap justify-content-between align-items-center mt-4"
                  >
                    <span className="text-secondary px-2">Gender</span>

                    <div className="d-flex w-50 justify-content-around">
                      <span className="d-flex justify-content-between">
                        <span>
                          <input
                            type="radio"
                            name="gender"
                            id="gen"
                            checked={loginData.Gender === "Male"}
                          />{" "}
                        </span>

                        <span className="px-3 ">Male</span>
                      </span>
                      <span className="">
                        <input
                          type="radio"
                          name="gender"
                          id="gen"
                          checked={loginData.Gender === "Female"}
                        />{" "}
                        <span className="px-3 mt-1">Female</span>
                      </span>
                    </div>
                  </div> */}
                  <div className="d-flex mt-4">
                    <button
                      style={{ backgroundColor: "#AA2B55" }}
                      className="btn text-white rounded w-100"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="p-3 d-flex justify-content-between">
              <h4>My Addresses</h4>
              <button
                onClick={() => dispatch(SetAddressModalOpen(true))}
                style={{ backgroundColor: "#AC2B57" }}
                className="btn text-white"
              >
                Add Address
              </button>
            </div>
            <div
              style={{
                height: "60vh",
                overflow: "scroll",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {address.map((indAddress) => (
                <div className="mt-3" style={{ flexBasis: "50%" }}>
                  <div
                    style={{
                      backgroundColor: "#FAE9EF",
                      border: "1px solid #ac2b57",
                      color: "#ac2b57",
                      height: "180px",
                      width: "262px",
                    }}
                    className="card p-3"
                  >
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="d-flex w-100  justify-content-between">
                        <div>
                          <b>{indAddress.AName}</b>
                        </div>
                        <div>
                          <div>
                            <input
                              type="radio"
                              name="address"
                              onChange={() => handleAddress(indAddress)}
                              checked={
                                indAddress._id === currentAddress._id
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <p
                          style={{
                            fontSize: "9.5pt",
                            textAlign: "start",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          House: {indAddress.HNo}
                          Address: {indAddress.Address}
                        </p>
                      </div>
                      <div className="mt-2">
                        <button
                          style={{ color: "#BD2B57" }}
                          className="btn btn-light"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="address">
            <ReactModal
              className="rounded "
              isOpen={isAddressModalOpen}
              onRequestClose={() => dispatch(SetAddressModalOpen(false))}
              style={{
                content: {
                  // width: "520px",
                  maxWidth: "520px",
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #000",
                  borderRadius: "10px",
                  padding: "10px",
                  margin: "auto",
                  marginTop: "14vh",
                },
                overlay: { backgroundColor: "rgba(0,0,0,.5)", zIndex: "969" },
              }}
            >
              <AddressModal />
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
