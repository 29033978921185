import { configureStore } from "@reduxjs/toolkit";
import CartSlice from "./cart/CartSlice";
import AuthenticationReducer from "./authentication/AuthenticationSlice";
import categoryReducer from "./category/categorySlice";
import superCategoryReducer from "./superCategory/superCategorySlice";
import subCategoryReducer from "./subCategory/subCategorySlice";
import productReducer from "./product/ProductSlice";
import OrderReducer from "./order/OrderSlice";
import OrderShopReducer from "./order/OrderShopSlice";
import OrderSalonReducer from "./order/OrderSalonHomeSlice";
import TagReducer from "./tags/TagSlice";
import SaloonReducer from "./saloon/SaloonSlice";
import ShopCartSlice from "./cart/ShopCartSlice";
import HomeSalonCartSlice from "./cart/HomeSalon";

export const store = configureStore({
  reducer: {
    Authentication: AuthenticationReducer,
    superCategory: superCategoryReducer,
    category: categoryReducer,
    Cart: CartSlice,
    ShopCart: ShopCartSlice,
    HomeSalonCart: HomeSalonCartSlice,
    subCategory: subCategoryReducer,
    products: productReducer,
    Order: OrderReducer,
    OrderShop: OrderShopReducer,
    OrderSalon: OrderSalonReducer,
    Tag: TagReducer,
    saloon: SaloonReducer,
  },
});
export default store;
