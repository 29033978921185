import React from "react";

const ShippingPolicy = () => {
  return (
    <>
      <div className="about-heading">
        <h4 className="text-center">Shipping & Delivery Policy:</h4>
      </div>
      <div className="about-write container-fluid">
        <p>
          Orders will be shipped within minimum 3 to maximum 7 working days or
          as per the delivery date agreed at the time of order confirmation &
          delivering of the shipment subject to Courier Company/post office
          norms. The product shall be dispatched by the company on the Address
          provided by you at the time of placing the order. In case of the wrong
          delivery address is mentioned then company shall charge extra for the
          delivery charges. Also note that shipment time might vary from product
          to product and incase of the product being out of stock will be
          intimated to the Direct Seller. Shipment or delivery time for the out
          of stock product might be more. For any issues in utilization of our
          services you may contact our customer care info@salontreat.com.
        </p>
        {/* <p className="mt-2">
          Shipment charges may vary based on the order's
          destination, shipping method, or a combination of factors.
        </p> */}
      </div>
    </>
  );
};

export default ShippingPolicy;
