import React from 'react'

function Notfound() {
  return (
    <div className='container-fluid'>

         <h2>Page Not Foud</h2>
    </div>
  )
}

export default Notfound;
