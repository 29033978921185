import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";

import { FaMinus, FaPlus } from "react-icons/fa";
import { SetLoginModalOpen } from "../../redux/authentication/AuthenticationSlice";
import { setProductBySubcat } from "../../redux/subCategory/subCategorySlice";
import SelectCarDetails from "../SelectCarDetails";
import {
  SetSelectedCategory,
  addtoCart,
  clearCart,
  decrementCart,
  getCartTotal,
} from "../../redux/cart/CartSlice";

const AnimalSubCategory = () => {
  const { categorytotal } = useSelector((store) => store.category);
  const { subCategorytotal, subCategoryLoading } = useSelector(
    (store) => store.subCategory
  );
  const { tagtotal, tagLoading, productByCatTag } = useSelector(
    (store) => store.Tag
  );
  const { CartItems, selectedCategory } = useSelector((store) => store.Cart);
  const { isAuth } = useSelector((store) => store.Authentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const targetDivRef = useRef(null);

  const [superCategory, setSuperCategory] = useState("");
  const [categories, setCategories] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [defaultSubcat, setDefaultSubcat] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [catTag, setCatTag] = useState([]);
  const [selectedTag, setselectedTag] = useState("");
  const [catTagLoading, setCatTagLoading] = useState(true);
  const [showProducts, setShowProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  useEffect(() => {
    const selectedCategory = categorytotal.find(
      (cat) => cat.slugUrl === params.caturl
    );
    if (selectedCategory) {
      setSubCategoryName(selectedCategory.name);
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/subcategory/subcategoryid/${selectedCategory._id}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const filteredSubCategories = fetchproducts.data.subcategory;
              setSubCategory(filteredSubCategories);
              setDefaultSubcat(filteredSubCategories[0]);
              dispatch(setProductBySubcat(filteredSubCategories[0]));
            }
          } catch (error) {}
        };
        fetchData();
      } catch (error) {}
    }
  }, [categorytotal, params.caturl, subCategorytotal]);

  useEffect(() => {
    if (defaultSubcat !== "") {
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/categorytag/tagsbysubcat/${defaultSubcat._id}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const catTag = fetchproducts.data.tags;
              setCatTag(catTag);
              setselectedTag(catTag[0]);
              setCatTagLoading(false);
            }
          } catch (error) {}
        };
        fetchData();
      } catch (error) {}
    }
  }, [defaultSubcat]);

  const subcatChnage = (subcat) => {
    setDefaultSubcat(subcat);
  };

  const getSelectedTag = (value) => {
    setselectedTag(value);
  };

  useEffect(() => {
    if (selectedTag !== "" && selectedTag !== undefined) {
      const getproducts = async () => {
        const url = `${Baseurl}/api/v1/product/productbyCatTagid/${selectedTag._id}`;
        const fetchproducts = await axios(url);
        try {
          if (fetchproducts.data.success === true) {
            const prod = fetchproducts.data.product.filter(
              (data) => data.outOfStock === false
            );
            let productbySubcat = prod.map(
              (product) => (product = { ...product, cartQuantity: 0 })
            );
            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = productbySubcat.findIndex(
                (product) => product._id === cartitem.ProductId
              );
              if (itemIndex >= 0) {
                productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
              }
            });
            setShowProducts(productbySubcat);
            setTempLoading(false);
          }
        } catch (error) {}
      };
      getproducts();
    }
  }, [selectedTag, CartItems]);

  const addToCartPress = (product, index) => {
    if (selectedCategory === product.category || selectedCategory === "") {
      if (selectedCategory === "") {
        dispatch(SetSelectedCategory(product.category));
      }

      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) {}
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
        for: "homeSaloon",
      };
      console.log(formData, "formData");
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    } else {
      dispatch(SetSelectedCategory(product.category));
      dispatch(clearCart());
      dispatch(getCartTotal());
      try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setShowProducts(products);
      } catch (error) {}
      const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,
        productCostPrice: product.productCostPrice,
        for: "homeSaloon",
      };
      dispatch(addtoCart(formData));
      dispatch(getCartTotal());
    }
  };

  const decrementCartPress = (product, index) => {
    try {
      let products = showProducts;
      products[index].cartQuantity = products[index].cartQuantity - 1;
      setShowProducts(products);
    } catch (error) {}
    const formData = {
      ProductId: product._id,
      ProductName: product.name,
      CatId: product.categoryId,
      CatName: product.category,
      Brand: product.brand,
      Services: product.services,
      Duration: product.duration,
      ImgUrl: product.ImgUrlMbl,
      Price: product.sellingPrice,
      Qty: 1,
      TotalAmount: product.sellingPrice * 1,
      Mrp: product.mrp,
      TotalPrice: product.sellingPrice * 1,
      TotalMrp: product.mrp * 1,
      Discount: product.mrp * 1 - product.sellingPrice * 1,

      productCostPrice: product.productCostPrice,
    };
    dispatch(decrementCart(formData));
    dispatch(getCartTotal());
  };

  const handleCheckout = () => {
    if (!isAuth) {
      dispatch(SetLoginModalOpen(true));
    } else {
      navigate("/cart");
    }
  };

  return (
    <>
      {/* <div className="_1QvE5">
        <div className="_2v4CM _2suPq">
          <div
            className="_20R5K _3m3sT _38Njd"
            style={{ position: "unset", gridColumn: "1 / 3" }}
          >
            <div>
              <div className="_1b05U">
                <Link to="/">
                  <img
                    loading="lazy"
                    // src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/WHITE.png"
                    src="/assets/home/maintainic2.png"
                    className="JQsfK"
                    alt="Gomechanic"
                  />
                </Link>
              </div>
              <div className="_2VdmA _26Ew4">
                Hyderabad
                <img
                  loading="lazy"
                  src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/B1A6D933-CB2C-4A34-B9F8-EFEB993DBA05.svg"
                  className="_3fheq"
                  alt="Gomechanic"
                />
              </div>
            </div>

            <div>
              <div className="_26Ew4 _1ajDD">
                <div className="_2rv7W">
                  <div className="_2UQnF">More</div>
                  <div className="ZCWjf">
                    <div className="_2cjQJ" />
                    <Link className="_2K9lJ" to="/profile/faq">
                      <div className="_26Ew4 eB0c1">FAQ</div>
                    </Link>
                    <Link className="_2K9lJ" to="/about-us">
                      <div className="_26Ew4 eB0c1">Contact Us</div>
                    </Link>
                    <Link className="_2K9lJ" to="/terms-and-conditions">
                      <div className="_26Ew4 eB0c1">Terms</div>
                    </Link>
                    <Link className="_2K9lJ" to="/privacy-and-policy">
                      <div className="_26Ew4 eB0c1">Privacy</div>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className="_3g2Ie"
                onClick={() => navigate("/vender-registation")}
                style={{ margin: "0 2vh" }}
              >
                <div className="U82WB">
                  <div className="l4eOt">Partner</div>
                </div>
              </div>

              {isAuth ? (
                <>
                  <div className="_3g2Ie">
                    <div className="U82WB">
                      <div className="l4eOt">Customer</div>
                      <div className="_37OpB">
                        <div className="_38bO5" />

                        <Link className="_2K9lJ" to="/profile/order-history">
                          <div className="_26Ew4 eB0c1">Order History</div>
                        </Link>

                        <Link className="_2K9lJ" onClick={() => logoutPress()}>
                          <div className="_26Ew4 eB0c1">Logout</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="_3g2Ie" onClick={() => navigate("/login")}>
                    <div className="U82WB">
                      <div className="l4eOt">Login</div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* <Modal
        size="md"
        centered
        isOpen={CategoryError}
        toggle={() => setCategoryError(!CategoryError)}
        modalTransition={{ timeout: 500 }}
      >
        <ModalBody>
          <div
            style={{
              width: "100%",
              height: "25vh",
              background: "#fff",
              padding: "3vh",
              borderRadius: "8px",
            }}
          >
            <h2>Replace cart items?</h2>
            <p
              style={{
                fontSize: "20px",
                color: "gray",
              }}
            >
              Your cart contain products from categoty from {selectedCategory}.
              Do you want to discard the selection and add products from{" "}
              {selectedProduct.Category} category
            </p>

            <div
              style={{
                display: "flex",
              }}
            >
              <button
                style={{
                  width: "20vh",
                  height: "7vh",
                  borderRadius: "8px",
                  border: "1px solid #ffeee6",
                  background: "#ffeee6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#f15701",
                  fontWeight: "700",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setCategoryError(false)}
              >
                No
              </button>
              <button
                style={{
                  width: "20vh",
                  height: "7vh",
                  borderRadius: "8px",
                  border: "1px solid #f15701",
                  background: "#f15701",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffeee6",
                  fontWeight: "700",
                  fontSize: "20px",
                  marginLeft: "3vh",
                  cursor: "pointer",
                }}
                onClick={() => replaceClick()}
              >
                Replace
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}

      <div style={{ marginTop: "60px" }}>
        <div className="_3iw1l">
          {/* <div className="_3_K4a"></div> */}
          <div
            className="p-1"
            style={{
              position: "sticky",
              top: "6%",
              zIndex: "5",
              backgroundColor: "#fff",
            }}
          >
            <div className="_3Mcoq" style={{ gridColumn: "1 / 2" }}>
              <div className="_1PI3k" style={{ maxWidth: "unset" }}>
                <div
                  className="_2GkAY OBszR _1bZNH"
                  itemScope
                  itemType="http://schema.org/SiteNavigationElement"
                  id="services-type-container"
                  ref={targetDivRef}
                >
                  {subCategory &&
                    subCategory.map((subcat, index) => (
                      <Link
                        style={{ textDecoration: "none", color: "#FFF" }}
                        itemProp="url"
                        key={index}
                        id={index}
                      >
                        <div onClick={() => subcatChnage(subcat)}>
                          <div
                            className={
                              defaultSubcat.slugUrl === subcat.slugUrl
                                ? "_2GkAY _3O2l6 _37qa5 _17y17 _85XiK"
                                : "_2GkAY _3O2l6 _37qa5 _17y17"
                            }
                          >
                            <img
                              loading="lazy"
                              src={subcat.desktopImage}
                              alt="Car Periodic Services"
                              className={
                                defaultSubcat.slugUrl === subcat.slugUrl
                                  ? "_1z4vM _3BshJ"
                                  : "_1z4vM"
                              }
                            />
                            <div
                              className={
                                defaultSubcat.slugUrl === subcat.slugUrl
                                  ? "_1qpUY d5ttk"
                                  : "_1qpUY"
                              }
                              style={{ fontWeight: "bold" }}
                              itemProp="name"
                            >
                              {subcat.name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="_3Mcoq2" style={{ gridColumn: "1 / 2" }}>
              <div className="_1PI3k" style={{ maxWidth: "unset" }}>
                <div
                  className="_2GkAY OBszR _1bZNH"
                  itemScope
                  itemType="http://schema.org/SiteNavigationElement"
                  id="services-type-container"
                  ref={targetDivRef}
                  style={{ marginBottom: "16" }}
                >
                  {catTag &&
                    catTag.map((tag, index) => (
                      <Link
                        style={{ textDecoration: "none", color: "#FFF" }}
                        itemProp="url"
                        key={index}
                        id={index}
                      >
                        <div onClick={() => getSelectedTag(tag)}>
                          <div
                            className={
                              selectedTag.name === tag.name
                                ? "_2GkAY _3O2l6 _37qa5 _17y17 _85XiK"
                                : "_2GkAY _3O2l6 _37qa5 _17y17"
                            }
                          >
                            <div
                              className={
                                selectedTag.name === tag.name
                                  ? "_1qpUY d5ttktag"
                                  : "_1qpUY"
                              }
                              style={{ fontWeight: "", padding: "5px" }}
                              itemProp="name"
                            >
                              {tag.name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <SelectCarDetails />

          <div style={{ backgroundColor: "#FFFFFF" }}>
            <div className="_1t_e2" style={{ maxWidth: "unset" }}>
              <div>
                <div className="_13CKU">
                  <div className="_5Ze1h" />
                  <div className="_1ZZEG">
                    <div>
                      <div className="jXB2w">{selectedTag.name}</div>

                      {showProducts &&
                        showProducts.map((product, index) => (
                          <div className="_1sR0p" key={index}>
                            {product.newLaunch ? (
                              <>
                                <div className="_1vXOy">
                                  <div>BESTSELLER</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {product.mustTry ? (
                              <>
                                <div className="_1vXOy">
                                  <div>MustTry</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {product.outOfStock ? (
                              <>
                                <div className="_1vXOy">
                                  <div>OutOfSTock</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {product.inDemand ? (
                              <>
                                <div className="_1vXOy">
                                  <div>InDemand</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {product.hotDeal ? (
                              <>
                                <div className="_1vXOy">
                                  <div>HotDeal</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="_34vKl">
                              <div className="_33hIJ">
                                <div className="_2RxcB">
                                  <img
                                    loading="lazy"
                                    className="_2OGze"
                                    src={product.ImgUrlDesk}
                                    alt="Car Comprehensive Service"
                                  />
                                </div>
                                <div className="_3Bc52">
                                  <div
                                    className="_2Rhyt"
                                    style={{ marginBottom: 15 }}
                                  >
                                    <div className="_1JPIu">
                                      <h2
                                        style={{ margin: 0 }}
                                        className="_1d-DS text-start d-flex justify-content-start"
                                      >
                                        {product.ItemName}
                                      </h2>
                                    </div>
                                    {/* <div className="_1CkVx">
                                      <img
                                        loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Services/ServicesListDesktop/76D2D1C8-A85C-4C56-A53D-DE23B9C19F76.svg"
                                        className="_1QJKc"
                                        alt="Time Taken during service"
                                      />
                                      <div className="_2qAPV">
                                        {" "}
                                        {product.duration}
                                      </div>
                                    </div> */}
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 20,
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                    }}
                                    className="_1qUuq"
                                    itemProp="name"
                                  >
                                    {product.name}
                                  </div>
                                  <div
                                    // className="_2UPbx"
                                    itemProp="itemListElement"
                                    itemScope
                                    itemType="http://schema.org/ListItem"
                                  >
                                    {product.services.map(
                                      (indService, index) => (
                                        <div
                                          className="_3qzZ9"
                                          itemProp="item"
                                          key={index}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Services/B267FE43-1120-4815-B81E-6C2AE1EFC4C1.svg"
                                            className="vZyEa"
                                            alt="Included"
                                          />
                                          <div
                                            className="_1qUuq"
                                            itemProp="name"
                                          >
                                            {indService.service}
                                          </div>
                                        </div>
                                      )
                                    )}

                                    {/* <div role="button" className="qpWB5">
                                      +16more View All
                                    </div> */}
                                  </div>
                                </div>
                              </div>

                              {product.cartQuantity > 0 ? (
                                <>
                                  <div
                                    className="_2sr-o"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="I6yQz" />
                                    <div
                                      className="_59ZXH"
                                      style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        onClick={() =>
                                          decrementCartPress(product, index)
                                        }
                                      >
                                        <FaMinus
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                      <span className="">
                                        {product.cartQuantity}
                                      </span>
                                      <span
                                        onClick={() =>
                                          addToCartPress(product, index)
                                        }
                                      >
                                        <FaPlus style={{ cursor: "pointer" }} />
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="_2sr-o"
                                    onClick={() =>
                                      addToCartPress(product, index)
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="I6yQz" />
                                    <div className="_59ZXH">+ ADD TO CART</div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "2.5rem" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimalSubCategory;
