import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { getCartTotal, removefromCart } from "../redux/cart/CartSlice";
import { getShopCartTotal, removefromShopCart } from "../redux/cart/ShopCartSlice";

const SalonCartBox = () => {
  const { ShopCartItems, shopCartNetPayable} = useSelector(
    (store) => store.ShopCart
  );
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="_13SfF _1jgox"
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 4,
          marginBottom: 30,
        }}
      >
        <div className="_3r7DG">
          <div className="_3GSeQ">
            {ShopCartItems.length === 0 ? (
              <div className="_3KZyB">
                <div className="_2ChFW">
                  <img
                    loading="lazy"
                    className="Oh8HT"
                    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/CartViewer/40020D6A-95B8-4C14-B565-72DBF2E4886A.svg"
                    alt="Empty cart"
                  />
                  <span className="_28Bhm">
                    Go ahead and book a service for you.
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="MwLYi">
                  <div className="_2CLCp">
                    {ShopCartItems.map((cart, index) => (
                      <>
                        <div key={index}>
                          <div className="_248On">
                            <div className="WLUxR">
                              <span>{cart.ProductName}</span>
                              <span className="FFpfs">{cart.Duration}</span>
                            </div>
                            <div className="_2Ddc7">
                              <div className="_2H7jx">
                                <div className="_1SWL_">
                                  <div style={{ display: "flex" }}>
                                    <div className="_3BOjJ">
                                      ₹ {cart.TotalMrp}
                                    </div>
                                    <div className="_1dTfs">
                                      ₹ {cart.TotalPrice}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <img
                                loading="lazy"
                                className="Hw9si"
                                src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/576E46D7-2E11-480E-990F-B2795EC55727.svg"
                                alt="Remove"
                                onClick={() => {
                                  dispatch(removefromShopCart(cart));
                                  dispatch(getShopCartTotal());
                                  toast.error("Item removed from your cart");
                                }}
                              />
                            </div>
                          </div>
                          <div className="_5r5lS" />
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                <div className="_2VhkD">
                  <div className="_1Zryi">
                    <div className="_3KUu9">
                      <div className="It7jb">
                        Subtotal ({ShopCartItems.length} Items)
                      </div>
                      <div className="_1ocM5">₹ {shopCartNetPayable}</div>
                    </div>
                    <div className="_3KUu9">
                      <div className="_3wbDV">Extra charges may apply</div>
                    </div>
                    <div className="_1YDvq">
                      <div
                        className="pivf2"
                        style={{ padding: "12px 20px", margin: 0 }}
                      >
                        <div className="_2dXN7">
                          <img
                            loading="lazy"
                            className="_2l48C"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/savings_icon_2.png"
                            alt="Add Coupon"
                          />
                          <div className="_2pyFB">Apply Coupon</div>
                        </div>
                        <img
                          loading="lazy"
                          className="Hw9si _3ZoZ9"
                          src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/CartViewer/0445C40D-14A2-4D73-BA26-E1CB38989C0C.svg"
                          alt="Apply Coupon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="/cart"
                  style={{
                    textDecoration: "none",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  <div className="_33v1d">
                    <div className="_3JbP7 _266cD _1O9SZ _2En4u tODmu">
                      <div className="_1XPkN _2MDUX">
                        <div style={{ color: "#fff" }}>₹ {shopCartNetPayable}</div>
                        <div style={{ color: "#fff" }}>CART</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalonCartBox;
