import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const WhyChooseSaloon = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="why">
        <div id="whyheading" className="px-2 d-flex justify-content-start">
          <h4 >
            Why Choose Saloon Treat
          </h4>
        </div>
        <div
          id="category2"
          className="mt-1 d-flex flex-wrap mx-auto justify-content-center"
        >
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            style={{ height: "auto" }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            className="mySwiper w-100"
          >
            <SwiperSlide>
              <div
                className="grid-1 m-2 slider_dimen"
                onClick={() => navigate(`/pet-food/Dog%20food`)}
                style={{cursor:"pointer"}}
              >
                <img
                  src="/assets/newpic/pet11.jpg"
                  alt="Animal Banner"
                  className="img-fluid w-100 h-100"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="grid-2 m-2 slider_dimen"
                onClick={() => navigate(`/pet-accessories/Pet%20accessories`)}
              >
                <img
                  src="/assets/newpic/pet12.png"
                  alt="Slider 1"
                  className="img-fluid w-100 h-100"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default WhyChooseSaloon;
