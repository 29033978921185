import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  subCategorytotal: localStorage.getItem("subCategorytotal")
    ? JSON.parse(localStorage.getItem("subCategorytotal"))
    : [],
  salonatHome_Sub_cat: localStorage.getItem("subCategorytotal")
    ? JSON.parse(localStorage.getItem("subCategorytotal")).filter(
        (data) => data.category === "Salon at home"
      )
    : [],
  animal_Sub_cat: localStorage.getItem("subCategorytotal")
    ? JSON.parse(localStorage.getItem("subCategorytotal")).filter(
        (data) => data.category === "Pet food"
      )
    : [],
  productBySubcat: "",

  isSubCategoryAvailable: localStorage.getItem("categorytotal") ? true : false,
  subCategoryLoading: true,

  productBySubcatLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "subCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);
      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("Category Not Found");
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    setProductBySubcat: (state, action) => {
      state.productBySubcat = action.payload;
      state.productBySubcatLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSubCategory.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.subCategorytotal = action.payload;
        state.salonatHome_Sub_cat = state.subCategorytotal.filter(
          (data) => data.category === "Salon at home"
        );
        state.animal_Sub_cat = state.subCategorytotal.filter(
          (data) => data.category === "Pet food"
        );
        localStorage.setItem(
          "subCategorytotal",
          JSON.stringify(state.subCategorytotal)
        );
        state.isSubCategoryAvailable = true;
        state.subCategoryLoading = false;
      })
      .addCase(getSubCategory.rejected, (state) => {
        state.subCategoryLoading = true;
      });
  },
});
export const { setProductBySubcat } = subCategorySlice.actions;
export default subCategorySlice.reducer;
