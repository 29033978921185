import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  CartItems: localStorage.getItem("CartItems")
    ? JSON.parse(localStorage.getItem("CartItems"))
    : [],
  selectedCategory: localStorage.getItem("selectedCategory")
    ? JSON.parse(localStorage.getItem("selectedCategory"))
    : "",
  ProductTotal: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  netPayable: 0,
  cartTotalMrp: 0,
  cartTotalPrice: 0,
  cartPackQuantity: 0,
  CouponDiscountAmount: 0,
  logisticsAmount: 0,
  Cartloading: true,
  productUpdated: false,
  checkoutData: "",
};
const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    allProductUpdate(state, action) {
      state.ProductTotal = action.payload;
      state.productUpdated = true;
      localStorage.setItem("ProductTotal", JSON.stringify(state.ProductTotal));
    },
    SetSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
      localStorage.setItem(
        "selectedCategory",
        JSON.stringify(state.selectedCategory)
      );
    },
    setCheckoutAllData(state, action) {
      state.checkoutData = action.payload;
    },
    cartReplaceClick(state, action) {
      // state.netPayable = action.payload;
      const tempProducts = action.payload;
      state.CartItems = [action.payload];
      state.cartTotalQuantity++;
      state.cartPackQuantity++;

      state.cartTotalAmount =
        Number(state.cartTotalAmount) + Number(action.payload.Price);

      if (state.cartTotalAmount >= 400) {
        state.logisticsAmount = 0;
      } else {
        state.logisticsAmount = 40;
      }
      state.netPayable =
        Number(state.cartTotalAmount) + Number(state.logisticsAmount);

      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    addtoCart(state, action) {

      // Check if the cart should be cleared based on the 'for' value condition
      if (state.CartItems.length !== 0) {
        const cartForValue = state.CartItems[0].for;
        const actionForValue = action.payload.for;
        
        if (cartForValue !== actionForValue) {
          state.CartItems = [];
        }else if(cartForValue==="Saloon" && state.CartItems[0].salonId!==action.payload.salonId){
          state.CartItems = [];
        }
      }
      // Find the index of the item in the cart
      const itemIndex = state.CartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );
      // If the item already exists in the cart, update its quantity and totals
      if (itemIndex >= 0) {
        state.cartTotalQuantity++;
        const item = state.CartItems[itemIndex];
        item.Qty += 1;
        item.TotalMrp = item.Mrp * item.Qty;
        item.TotalPrice = item.Price * item.Qty;
        item.TotalAmount = item.Price * item.Qty;
        state.cartTotalAmount += item.Price;
        state.netPayable = state.cartTotalAmount;
      } else {
        // If the item does not exist in the cart, add it
        const tempProduct = action.payload;
        state.CartItems.push(tempProduct);
        state.cartTotalQuantity++;
        state.cartPackQuantity++;
        state.cartTotalAmount += tempProduct.Price;
        state.netPayable = state.cartTotalAmount;
      }

      // Toggle loading state and save cart items to local storage
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },

    decrementCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item.ProductId === action.payload.ProductId
      );

      if (itemIndex >= 0) {
        if (state.CartItems[itemIndex].Qty > 1) {
          let quant = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].TotalMrp =
            Number(state.CartItems[itemIndex].Mrp) * Number(quant);

          state.CartItems[itemIndex].TotalPrice =
            Number(state.CartItems[itemIndex].Price) * Number(quant);
          state.CartItems[itemIndex].TotalAmount =
            Number(state.CartItems[itemIndex].Price) * Number(quant);
        } else if (state.CartItems[itemIndex].Qty === 1) {
          const nextCartItems = state.CartItems.filter(
            (cartItem) => cartItem.ProductId !== action.payload.ProductId
          );
          state.cartPackQuantity--;
          state.CartItems = nextCartItems;
        }

        state.cartTotalAmount =
          Number(state.cartTotalAmount) + Number(action.payload.Price);

        // if (state.cartTotalAmount >= 500) {
        //   state.logisticsAmount = 0;
        // } else {
        //   state.logisticsAmount = 40;
        // }

        state.netPayable = Number(state.cartTotalAmount);
        state.Cartloading = !state.Cartloading;
      }

      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    removefromCart(state, action) {
      const nextCartItems = state.CartItems.filter(
        (cartItem) => cartItem.ProductId !== action.payload.ProductId
      );
      state.cartPackQuantity--;
      state.CartItems = nextCartItems;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    getCartTotal(state, action) {
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartTotalPrice = 0;
      const totalitem = state.CartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.cartTotalAmount =
          Number(state.cartTotalAmount) +
          Number(state.CartItems[i].TotalAmount);

        state.cartTotalMrp =
          Number(state.cartTotalMrp) + Number(state.CartItems[i].TotalMrp);
        state.cartTotalPrice =
          Number(state.cartTotalPrice) + Number(state.CartItems[i].TotalPrice);
      }

      // if (state.cartTotalAmount >= 500) {
      //   state.logisticsAmount = 0;
      // } else {
      //   state.logisticsAmount = 40;
      // }
      state.netPayable = Number(state.cartTotalAmount);
    },
    clearCart(state, action) {
      state.CartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
  },
});

export const {
  allProductUpdate,
  addtoCart,
  decrementCart,
  removefromCart,
  getCartTotal,
  clearCart,
  setCheckoutAllData,
  SetSelectedCategory,
  cartReplaceClick,
} = CartSlice.actions;
export default CartSlice.reducer;
