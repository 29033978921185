import React from "react";
import { MdOutlineMessage } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdWhatsapp } from "react-icons/md";

const ContactUs = () => {
  return (
    <div style={{marginTop:'70px'}} className="contact-container">
      <div className="about-heading">
        <h4 className="text-center">Contact with Us</h4>
      </div>
      <div id="contact-us">
        <div className="customer-care mt-5">
          <img src="/assets/preview.png" alt="asdfg" className="img-fluid" />
        </div>
        <div className="contact">
          <div className="contact-1">
            <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
              <span>
                <MdOutlineMessage /> Talk to us
              </span>
              <p style={{ fontSize: "9pt" }}>support@saloontreat.com</p>
            </div>
          </div>
          <div className="contact-1">
            <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
              <span>
                <FaMobileAlt /> Call us now
              </span>
              <p style={{ fontSize: "9pt" }}>+91 9797 63 9797</p>
            </div>
          </div>
          <div className="contact-1">
            <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
              <span>
                <IoLocationSharp /> office
              </span>
              <p style={{ fontSize: "9pt" }}>
                C, 45, Sector 63 Rd, C Block, Noida, Uttar Pradesh 201301
              </p>
            </div>
          </div>
          <div className="contact-1">
            <div style={{ paddingLeft: "10px" }}>
              <span>
                <MdWhatsapp style={{ fontSize: "24px", marginBottom: "5px" }} />{" "}
                Connect on WhatsApp
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
