import React from "react";
import "../../Desktop.css";
import ServiceList from "./ServiceList";
import CatBySaloon from "./CatBySaloon";

const SalonPage = () => {
  return (
    <>
      <div className="desktop-home">
        <CatBySaloon />
      </div>
      <div className="mob-home">
        <ServiceList />
      </div>
    </>
  );
};

export default SalonPage;
