import React from 'react'

function Privacy() {
    return (
        <div>
            <div  className="privacy-container">
                <div className="heading">
                    <h4 >
                        <span style={{ borderBottom: '3px solid #E85F6B' }}>Privacy </span>Policy
                    </h4>
                </div>

                <div className="first-card-privacy">
                    <h6>Updated on: 20th October, 2024</h6>
                    <div>
                        <p>
                            SONU SALONTREAT PRIVATE LIMITED takes privacy very seriously.
                            Our Privacy Policy (Policy) is designed to provide transparency into our privacy practices and principles,
                            presented in a format that makes it easier for our guests to navigate, read and understand contents of our website,
                            www.saloontreat.com(Website). We are dedicated to treating your personal information with care and respect.
                            We have accordingly developed this Policy to protect your personal information and keep it confidential.
                        </p>
                    </div>


                </div>

                <div className="first-card-privacy">
                    <h6>1. Personal Information</h6>
                    <div>
                        <p>
                            We thank you (Customer/User/You/Your) for registering with SONU SALONTREAT PRIVATE LIMITED on www.saloontreat.com(Website) as well as on our Mobile Application (App).
                            Your agreement with us includes these user terms and conditions of use (Terms) and our privacy policy (together the Policies).
                            If you don't agree with these terms, then please don't use our services (defined later).
                            Please note that you will not be able to book services offered by Saloon Treat, if you have
                            not clicked and accepted these terms.
                        </p>
                    </div>
                    <div>
                        <p> <span>1.1</span>&nbsp; We categorise information about you (collectively referred to as "Personal Information") as follows:</p>
                    </div>
                    <div>
                        <p> <span>A.Profiling Information: </span>&nbsp;
                            Information which you provide when you register for a service,
                            which may include some or all of the following: - information about
                            your personal identity such as gender, name, job title, address,
                            marital status, age, preferences, likes and dislikes etc.; - your financial
                            information such as your banking details and any information relating to your lifestyle levels;
                            and - your contact details such as your physical addresses, postal addresses,
                            telephone and fax numbers and the like.
                            In the case of Facebook aligned services (if so opted by you),
                            publicly available information of your friends, their likes and dislikes, etc.
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>B. Payment and Account Information: </span>&nbsp;
                            Your account history with us including (without limitation) all billing information and communications,
                            payment history etc. We maintain this in encrypted form on secure servers.
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>C. Service Usage:</span>&nbsp;
                            Information about your navigation using our services, for example the URLs of websites,
                            which you visit and from which you request downloads.
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>D. Log information: </span>&nbsp;
                            Information such as your web request, IP address, browser type, browser language, date and time of request.
                            In case of booking via call, Saloon Treat may record calls for quality and training purposes.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>E. Transactional Information:</span>&nbsp;
                            Transactional history (other than banking details) about your e-commerce activities.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>F. Correspondence Information: </span>&nbsp;
                            Content, information about your correspondents,
                            and the destination/origin of communications between you and any
                            other person using our services, which include email communications,
                            blog, chat room and discussion board communications,
                            instant message communications, experts forum communications,
                            fax mail communications, membership of mailing lists etc.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>G. User IDs: </span>&nbsp;
                            Your usernames, passwords, email addresses and other security-related information used by you in relation to our services.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>H. Stored Information: </span>&nbsp;
                            Data either created by you or by a third party and which you wish to store on our servers such as image files, documents etc.
                            We only collect your personal Information to conduct our business and to enable us to deliver and improve our services.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>1.2 </span>&nbsp;
                            We do not and shall never for any reason whatsoever sell your Personal Information
                            to any third party or otherwise trade on it. We will only disclose your Personal
                            Information in accordance with this Policy. If we want to use it for any other purpose,
                            we will obtain your prior written consent. If you decline to submit personal information to us,
                            then we will unfortunately not be in a position to provide the Services to you.
                            All the information provided by you to us is to an open,
                            public environment or forum that may be accessed by third parties.
                            It is possible that third parties may collect and collate and use such information for their own purposes.
                            You should accordingly be careful when deciding to share any of your Personal Information in such public environments.
                            Information, which is disclosed publicly, is also shared with our affiliates,
                            third party service providers, sponsors of competitions etc. unless expressly stated otherwise.
                            We are not liable to you or any third party for any damages that you or any third party may suffer howsoever
                            arising from your disclosure of Personal
                            Information in any public environment. You accordingly disclose information in a public environment at your own risk.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>2. Right to Collect</h6>&nbsp;
                    <div>
                        <p>
                            By accepting the Terms of Service you agree that we may collect
                            and store your Personal Information as long as you use our Services subject to the limitations set out in this Policy.
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>2.1 </span>&nbsp;
                            We collect your Profiling and Account Information for the following reasons:
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>A. </span>
                            We need your identity details, contact details, bank information and account history to manage our relationship with you and provide Services to you. We may use
                            this information for suggesting products that you might like on our Website. We will only disclose this information as provided below.                        </p>
                    </div>

                    <div>
                        <p>
                            <span>B. </span>&nbsp;
                            We use certain of your information in an aggregated form to compile statistical and demographical
                            profiles for our business and marketing activities. We may disclose such information about you, provided that
                            the information is in an aggregated form that is not capable of being used or interpreted in such a manner as to identify you.                       </p>
                    </div>

                    <div>
                        <p>
                            <span>2.2 </span>&nbsp;
                            We collect and store your service usage and transactional information to:
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>A.</span>&nbsp;
                            Determine and verify the service charges payable by you and to administer our relationship with you.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>B.</span>&nbsp;
                            Comply with any statutory or regulatory requirement.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>C.</span>&nbsp;
                            Compile statistical and demographical profiles about you for our business and marketing
                            activities and to customise our services to you. While we are entitled to use such information
                            about you for our own internal business purposes without limitation,
                            we will only disclose it in an aggregated form that is not capable of being used
                            or interpreted in such a manner as to identify you.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>D.</span>&nbsp;
                            Monitor your use of our Services for the purposes of ensuring compliance with our terms of service.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>E. Surveys:</span> &nbsp;
                            We may from time to time conduct voluntary surveys of visitors to the Website to obtain aggregate
                            demographic information about them. Such information may be compiled and shared in the aggregate
                            with advertisers and other third parties.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>2.3 </span> &nbsp;
                            We collect and store your correspondence information and personal identifiers to: A. Comply with our obligations under
                            law. B. Monitor your use of our Services in order to ensure your compliance with our Terms of Service.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>2.4 </span> &nbsp;
                            Any Personal Information which we collect and which we may use in an aggregated format ensuring you are not individually
                            identified is our property. We may use it, in our sole discretion and without any compensation to you, for any legitimate
                            purpose including (without limitation) the commercial sale thereof to third parties. Sometime we use "cookies" so that
                            we can provide you with more customised information when you return to our Website. "Cookies" are used to store user
                            preferences and to track user trends, so as to enhance your interactive experience and generally improve our Services to you.
                            You can set your browser to notify you when you are sent a "cookie", giving you the chance to decide whether or not to accept it.
                            If you do accept a "cookie", you thereby agree to our use of any personal information collected by us using that Cookie.
                            You may update your profiling information at any time on the Website. You acknowledge and agree that in the interests
                            of improving personalisation and service efficiency, we may, under controlled and secure circumstances, share your personal
                            information with our affiliates (an entity which is our subsidiary or holding company
                            or a subsidiary of our holding company or an entity which controls, is controlled by or is under common control with us).
                        </p>
                    </div>


                    <div>
                        <p>
                            <span>2.5 Physical safety / emergency alerts to send SMS:</span> &nbsp;
                            In addition, tracking information will be collected as you navigate through our Services,
                            including, but not limited to geographic areas. In case of emergency your mobile phone will
                            send your GPS coordinates and an SMS,
                            during the service, to our servers. Most GPS enabled mobile devices can define one`s
                            location to within 50 feet.
                        </p>
                    </div>

                    <div>
                        <p>
                            <span>2.6 INFORMATION COLLECTED BY MOBILE APPLICATION:</span> &nbsp;
                            Our Services are primarily provided through the Mobile Application.
                            We may collect and use technical data and related information, including
                            but not limited to, technical information about your device, system and
                            application software, and peripherals, that is gathered periodically to
                            facilitate the provision of software updates, product support and other
                            services to you (if any) related to such Mobile Applications.
                        </p>
                    </div>

                    <div>
                        <p>
                            When you use any of our Mobile Applications, the Mobile Application may
                            automatically collect and store some or all of the following
                            information from your mobile device (“Mobile Device Information”),
                            in addition to the Device Information, including without limitation: <br />
                            <span>A.</span> &nbsp;Your preferred language and country site (if applicable) <br />
                            <span>B.</span> &nbsp;The manufacturer and model of your mobile device <br />
                            <span>C.</span> &nbsp; Your mobile operating system <br />
                            <span>D.</span> &nbsp; The type of mobile internet browsers you are using<br />
                            <span>E.</span> &nbsp; Your geolocation<br />
                            <span>F.</span> &nbsp; Information about how you interact with the Mobile Application
                            and any of our web sites to which the Mobile Application links, such as how many times
                            you use a specific part of the Mobile Application over a given time period, the amount
                            of time you spend using the Mobile Application,
                            how often you use the Mobile Application, actions you take in
                            the Mobile Application and how you engage with the Mobile Application <br />

                            Information to allow us to personalize the services and content available through the Mobile Application

                            Data from SMS/ text messages upon receiving Device permissions for the purposes of (i)
                            issuing and receiving one time passwords and other device verification, and (ii) automatically
                            filling verification details during financial transactions, either through us or a third-party
                            service provider, in accordance with applicable law. We do not share or transfer SMS/ text
                            message data to any third party other than as provided under this Privacy Policy.<br />
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>3. General Exceptions</h6>
                    <div>
                        <p>
                            If we are required to intercept, disclose, monitor and/or store your Personal Information:
                            (a) by law; (b) to conduct our business; (c) to secure our systems; or (d) to enforce our
                            own rights, we will do so in the manner as prescribed by law. Such interception, disclosure,
                            monitoring and storage may take place without your knowledge. In that case, we will not be
                            liable to you or any third party for any damages howsoever arising from such interception,
                            disclosure, monitoring and storage. In order to ensure that all our users comply with the
                            Terms of Service, we may monitor your personal information to the extent that this may be
                            required to determine compliance and/or to identify instances of non-compliance. To ensure
                            that the security and integrity of our Services are safeguarded, we may monitor your personal
                            information. This monitoring may include (without limitation) the filtering of incoming and
                            outgoing electronic data messages to identify, limit and/or prevent the transmission of spam,
                            viruses and/or unlawful, defamatory, obscene or otherwise undesirable material or content.
                            We may under certain circumstances procure an element of the services from a third party
                            service provider. To the extent that it may be necessary, and solely for the purposes of
                            providing the Service to you, you agree that we may
                            disclose to such third party any of your Personal Information that may be necessary
                            for the procurement of services from the third party.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>4. Collection Of Automation Information, Use Of Cookies And Other Tracking Devices</h6>
                    <div>
                        <p>
                            We and our third party service providers, which include ad networks, use cookies,
                            web beacons, and other tracking technologies to collect information about your
                            use of our Website/app and Services, such as your browser type, your ISP or operating system,
                            your domain name, your access time, the URL of the previous website you visited, your page views,
                            your IP address, and the type of device that you use. We also track how frequently you visit
                            our Website/app and use our Services. We use this information (including the information collected
                            by our third party service providers) for Website/app analytics (including to determine which portions
                            of our Website/app are used most frequently, what our users like/do not like), to assist us in determining
                            relevant advertising (both on and off our Website/app), to evaluate the success of our advertising campaigns,
                            and as otherwise described in this policy. Currently, we do not honour browser requests not to be tracked.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>5. Third Party Ad Network</h6>
                    <div>
                        <p>
                            We participate in third party ad networks that may display advertisements
                            on other websites based on your visits to our Site/app as well as other websites.
                            This enables us and these third parties to target advertisements by displaying
                            ads for products and services in which you might be interested. Third party ad
                            network providers, advertisers, sponsors and/or traffic measurement services
                            may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs
                            and other technologies to measure the effectiveness of their ads and to
                            personalize advertising content to you. These third party cookies and other
                            technologies are governed by each third party's specific privacy policy,
                            and not by Saloon Treat`s Policy. We may provide these third-party advertisers
                            with information about your usage of our Site/app and our services,
                            as well as aggregate or non-personally identifiable information
                            about visitors to our Site and users of our service.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>6. What Choices Do I Have Regarding Use of My Personal Information?</h6>
                    <div>
                        <p>
                            We may send periodic promotional or informational emails/SMS to you. You may
                            opt-out of such communications by following the opt-out instructions contained in the email.
                            Please note that it may take up to 10 business days for us to process opt-out requests.
                            If you opt-out of receiving emails/SMS about recommendations or other information we think may interest you,
                            we may still send you emails/SMS about your Account or any Services you have requested or received from us.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>7. Refer To Friend</h6>
                    <div>
                        <p>
                            Our Website/app users can voluntarily choose to electronically forward a link, page,
                            or invite to someone else by clicking "Refer to a friend." To do so, the user can send the link
                            by email, WhatsApp, telegram or any other electronic means. The information is not used for any other purpose.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>8. Collection Of Personal Information From Social Networking Sites</h6>
                    <div>
                        <p>
                            You may log into our Website/app through your Facebook account. If you do so,
                            you must enter the email address and password that you use for your Facebook account.
                            We will ask that you grant us permission to access and collect your Facebook basic
                            information (this includes your name, profile picture, gender, networks,
                            user IDs list of friends, date of birth, email address, and any other information
                            you have set to public on your Facebook account). If you allow us to have access to this information,
                            then we will have access to this information even if you have chosen not to make that information public.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>9. Security</h6>
                    <div>
                        <p>
                            We endeavour to comply with laws of other countries but cannot and do not warrant that we do.
                            We do everything we reasonably can to protect your rights of privacy on systems and the Website
                            controlled by us, but we are not liable for any unauthorised or unlawful disclosures of your personal
                            and confidential information made by third parties who are not subject to our control,
                            for example advertisers and websites that have links to our Website.
                            You should take note that the information and privacy practices of our business partners,
                            advertisers, sponsors or other sites to which we provide hyperlinks,
                            may be different from ours. This policy if effective as on the date mentioned hereinabove.
                            We at our sole discretion may change any or all of the terms of this policy by updating
                            this document from time to time. To make sure you are aware of any changes, please review this policy periodically.
                            If you have any questions regarding this policy, please feel free to drop us a line at support@Saloon Treat.com team@Saloon Treat.com
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>10. Changes to the Privacy Policy</h6>
                    <div>
                        <p>
                            From time to time, we may update this Privacy Policy to reflect changes to our information practices.
                            Any changes will be effective immediately upon the posting of the revised Privacy Policy.
                            We encourage you to periodically review this page for the latest information on our privacy practices.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>11. Background And Key Information</h6>
                    <div>
                        <p>
                            <span>(a) About this Policy:</span> &nbsp;
                            This policy is for the people who access or avail the services.
                            To clear the doubts, in this policy, references to "you" are an end user that uses the platform.
                            When you use this platform, you give your consent to access, assort, stockpile and utilize your
                            information as described by you and gathered by us.
                        </p>
                        <p>
                            <span>(b) Review and Updates:</span> &nbsp;
                            We suggest you keep reviewing this Policy regularly as we continue to update
                            our Privacy Policy as per the needs. To avoid any discrepancies, make sure that all the personal data
                            provided by you is current and accurate.
                            If there is a change in your personal data, please let us know to maintain a
                            smooth and lasting relationship with us.
                        </p>
                        <p>
                            <span>(c) Third-Party Services:</span> &nbsp;
                            This platform may contain links to third-party applications, websites, and services.
                            By clicking on those links, you give your consent to a third party to collect and use your personal data.
                            We don’t endorse or control these Third-Party Services. So, we are neither sure nor accountable for their privacy policy.
                            We suggest you read the priva
                        </p>

                        <p>
                            <span>(d)</span> &nbsp;
                            Our Partner App uses background location information to share the partner’s
                            location to the customer, so that the customer can track the service provider in real time.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>12. Data Retention</h6>
                    <div>
                        <p>
                            In accordance with our privacy policy, you give your consent to collect, use, store and share
                            your personal data with our affiliates or other third-party service providers. We may use information
                            and data pertaining to providing services, analytics, trend identification, and statistics to further
                            enhance our services' efficiency. We may use your personal data for the provision of beneficial schemes,
                            new offers, as well as for user experience enhancement.
                            Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose your personal
                            data in connection with criminal or civil proceedings. Even if you discontinue accessing the services or terminate your account,
                            we may use the data to comply with our legal rights and obligations.
                            Saloon Treat reserves the right to share the data with relevant agencies or bodies when needed. It will not be considered
                            an infringement of the right to Privacy.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>13. Account and Personal Data Deletion:</h6>
                    <div>
                        <p>
                            You have the option to delete your account and associated personal data stored with Saloon Treat.
                            To initiate this process, kindly send an email to privacy@Saloon Treat.com . Please be aware that
                            Saloon Treat may require up to 10 (ten) working days to complete your request. Upon deletion of your account,
                            access to all Services will be terminated.
                            It is important to note that transactional data generated on the Platform will be retained in
                            accordance with applicable laws.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>14. Unauthorized Access</h6>
                    <div>
                        <p>
                            We take security of our users information seriously and strictly prohibit any unauthorized attempts to access,
                            disrupt or interfere with our website, servers or any associated networks. Any attempt to hack, exploit
                            vulnerabilities or engage in any malicious activities that compromise the integrity of our systems will be considered
                            a violation and may result in legal action. We reserve the right to cooperate with law enforcement authorities
                            and pursue legal action against anyone involved in hacking or attempting to compromise the security of our website,
                            systems or data.If you have any concerns or suspect any unauthorized access, please contact to us at privacy@Saloon Treat.com
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>15. Changes to the Privacy Policy</h6>
                    <div>
                        <p>
                            From time to time, we may update this Privacy Policy to reflect changes to our information practices.
                            Any changes will be effective immediately upon the posting of the revised Privacy Policy.
                            We encourage you to periodically review this page for the latest information on our privacy practices.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>16. Device Info Collection</h6>
                    <div>
                        <p>

                            We collect only necessary information about your app usage, such as unique identifiers such as (GAID & UUID) and
                            other device data. This information may be stored by, accessed by and shared with third party vendors, and used for advertising,
                            analytics and notification purposes. We strictly follow our Privacy Policy to access and process your information.
                        </p>
                    </div>
                </div>

                <div className="first-card-privacy">
                    <h6>17. Grievance Redressal</h6>
                    <div>
                        <p>
                            <span>(a) </span>&nbsp;
                            You may contact our designated Grievance Redressal Officer with any c
                            omplaints or queries relating to the Services or these Terms and conditions:
                        </p>

                        <p className='mt-2'>
                            Name: Dan Singh <br />
                            Designation: Vice President, <br />
                            Operations Email Address: privacy@Saloon Treat.com <br />
                        </p>

                        <p>
                            <span>(b)</span> &nbsp;
                            We shall ensure that your complaint is resolved within the timelines advised by applicable laws.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;
