import React, { useEffect, useState } from "react";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { RiDiscountPercentLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegCommentDots } from "react-icons/fa";
// import Paytm from "../../public/assets/paytm3.png";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { SetSelectedCategory, clearCart } from "../redux/cart/CartSlice";
import { newOrder } from "../redux/order/OrderSlice";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { SalonNewOrder } from "../redux/order/OrderSalonHomeSlice";
import {
  clearHomeSalonCart,
  SetHomeSalonCartSelectedCategory,
} from "../redux/cart/HomeSalon";
import { clientUpdateById } from "../redux/authentication/AuthenticationSlice";
import { ShopNewOrder } from "../redux/order/OrderShopSlice";
import { clearShopCart } from "../redux/cart/ShopCartSlice";

function Payments() {
  const {
    CartItems,
    netPayable,
    cartTotalMrp,
    cartTotalAmount,
    cartTotalPrice,
    checkoutData,
    selectedCategory,
  } = useSelector((state) => state.Cart);

  const { loginData, currentAddress } = useSelector(
    (store) => store.Authentication
  );

  const {
    ShopCartItems,
    shopCartNetPayable,
    shopCartTotalMrp,
    shopCartTotalAmount,
    shopCartTotalPrice,
    shopCheckoutData,
    shopSelectedCategory,
  } = useSelector((store) => store.ShopCart);
  const {
    HomeSalonCartItems,
    homeSalonCartNetPayable,
    homeSalonCartTotalMrp,
    homeSalonCartTotalAmount,
    homeSalonCartTotalPrice,
    homeSalonCartCheckoutData,
    selectedHomeSalonCartCategory,
  } = useSelector((store) => store.HomeSalonCart);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ten, setTen] = useState(false);
  const [twenty, setTwenty] = useState(false);
  const [thirty, setThirty] = useState(false);
  const [fourty, setFourty] = useState(false);
  const [type, setType] = useState("");

  // shop
  const [useStCoinsShop, setUseStCoinsShop] = useState(false);
  const [stCoinsShop, setStCoinsShop] = useState(0);
  const [stCoinsValueShop, setStCoinsValueShop] = useState(0);

  //product
  const [useStCoins, setUseStCoins] = useState(false);
  const [stCoins, setStCoins] = useState(0);
  const [stCoinsValue, setStCoinsValue] = useState(0);

  //Home Salon
  const [useStCoinsSalon, setUseStCoinsSalon] = useState(false);
  const [stCoinsSalon, setStCoinsSalon] = useState(0);
  const [stCoinsValueSalon, setStCoinsValueSalon] = useState(0);

  console.log(stCoinsValueSalon, "stCoinsValueSalon");

  const [diffCartItems, setDiffCartItems] = useState("");
  useEffect(() => {
    const { id, name } = location.state || {};
    if (name) {
      setType(name);
    }
    if (name === "Shop") {
      setDiffCartItems(ShopCartItems);
    } else if (name === "HomeSaloon") {
      setDiffCartItems(HomeSalonCartItems);
    } else if (name === "Product") {
      setDiffCartItems(CartItems);
    }
  }, [location.state]);

  //shop
  useEffect(() => {
    if (useStCoinsShop) {
      const maxCoinsValue = Math.min(loginData.StCoins * 0.1);
      const applicableCoinsValue = Math.min(maxCoinsValue, shopCartNetPayable);
      setStCoinsShop(applicableCoinsValue);
      setStCoinsValueShop(Math.trunc(applicableCoinsValue / 10));
    } else {
      setStCoinsShop(0);
      setStCoinsValueShop(0);
    }
  }, [useStCoinsShop, shopCartNetPayable, loginData.StCoins]);

  //product
  useEffect(() => {
    if (useStCoins) {
      const maxCoinsValue = Math.min(loginData.StCoins * 0.1);
      const applicableCoinsValue = Math.min(maxCoinsValue, netPayable);
      setStCoins(applicableCoinsValue);
      setStCoinsValue(Math.trunc(applicableCoinsValue / 10));
    } else {
      setStCoins(0);
      setStCoinsValue(0);
    }
  }, [useStCoins, netPayable, loginData.StCoins]);

  //Home Salon
  useEffect(() => {
    if (useStCoinsSalon) {
      const maxCoinsValue = Math.min(loginData.StCoins * 0.1);
      const applicableCoinsValue = Math.min(
        maxCoinsValue,
        homeSalonCartNetPayable
      );
      setStCoinsSalon(applicableCoinsValue);
      setStCoinsValueSalon(Math.trunc(applicableCoinsValue / 10));
    } else {
      setStCoinsSalon(0);
      setStCoinsValueSalon(0);
    }
  }, [useStCoinsSalon, homeSalonCartNetPayable, loginData.StCoins]);

  const homeSalonPaymentClick = async () => {
    const finalTotal = Math.max(0, homeSalonCartNetPayable - stCoinsValueSalon);
    const formData = {
      ClientId: loginData._id,
      CatName: selectedHomeSalonCartCategory,
      ClientName: loginData.Name,
      TotalAmount: homeSalonCartTotalAmount,
      Email: loginData.Email,
      DeliveryCharge: 0,
      GrandTotal: finalTotal,
      Address: homeSalonCartCheckoutData.Address,
      city: "",

      userLongitude: currentAddress.Longitude,
      userLatitude: currentAddress.Latitude,

      AreaName: currentAddress.AName,
      Mobile: loginData.Mobile,
      PaymentMode: "COD",
      PaymentStatus: "COD",
      TxnId: "NA",
      CurrentStatus: "Not Paid",
      Saving: homeSalonCartTotalMrp - homeSalonCartTotalPrice,
      Cashback: "0",
      ServicePreferedDate: homeSalonCartCheckoutData.ServicePreferedDate,
      ServicePreferedTime: homeSalonCartCheckoutData.ServicePreferedTime,
      orderDisplayName: homeSalonCartCheckoutData.orderDisplayName,
      OrderProducts: HomeSalonCartItems,
      ProductCount: HomeSalonCartItems.length,
    };

    const config = {
      Headers: { "Content-Type": "application/json" },
    };

    const order = await axios.post(
      `${Baseurl}/api/v1/orderhomesalon/new`,
      formData,
      config
    );
    try {
      if (order.data.success === true) {
        dispatch(SalonNewOrder(order.data));
        dispatch(clearHomeSalonCart());
        // dispatch(SetHomeSalonCartSelectedCategory(''));
        toast.success("Order SuccessFully");
        navigate("/success");

        if (useStCoinsSalon) {
          const remainingStCoins = loginData.StCoins - stCoinsSalon;

          const clientUpdate = {
            _id: loginData._id,
            StCoins: remainingStCoins,
          };
          dispatch(clientUpdateById(clientUpdate));
          const updatedLoginData = { ...loginData, StCoins: remainingStCoins };
          localStorage.setItem("loginData", JSON.stringify(updatedLoginData));
        }
      }
    } catch (error) {}
  };

  const shopPaymentClick = async () => {
    const finalTotal = Math.max(0, shopCartNetPayable - stCoinsValueShop);
    const formData = {
      ClientId: loginData._id,
      CatName: shopSelectedCategory,
      ClientName: loginData.Name,
      TotalAmount: shopCartTotalAmount,
      Email: loginData.Email,
      DeliveryCharge: 0,
      GrandTotal: finalTotal,
      Address: shopCheckoutData.Address,
      city: "",
      shopId: diffCartItems[0].shopId,
      shopName: diffCartItems[0].shopName,
      shopNumber: diffCartItems[0].shopNumber,
      shopAddress: diffCartItems[0].address,
      shopLongitude: "",
      shopLatitude: "",
      userLongitude: currentAddress.Longitude,
      userLatitude: currentAddress.Latitude,

      AreaName: currentAddress.AName,
      Mobile: loginData.Mobile,
      PaymentMode: "COD",
      PaymentStatus: "COD",
      TxnId: "NA",
      CurrentStatus: "Not Paid",
      Saving: shopCartTotalMrp - shopCartTotalPrice,
      Cashback: "0",
      ServicePreferedDate: shopCheckoutData.ServicePreferedDate,
      ServicePreferedTime: shopCheckoutData.ServicePreferedTime,
      orderDisplayName: shopCheckoutData.orderDisplayName,
      OrderProducts: diffCartItems,
      ProductCount: diffCartItems.length,
    };

    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const order = await axios.post(
      `${Baseurl}/api/v1/ordershop/new`,
      formData,
      config
    );

    try {
      if (order.data.success === true) {
        dispatch(ShopNewOrder(order.data));
        dispatch(clearShopCart());
        dispatch(SetSelectedCategory(""));
        toast.success("Order SuccessFully");
        navigate("/success");

        if (useStCoinsShop) {
          const remainingStCoins = loginData.StCoins - stCoinsShop;

          const clientUpdate = {
            _id: loginData._id,
            StCoins: remainingStCoins,
          };
          dispatch(clientUpdateById(clientUpdate));
          const updatedLoginData = { ...loginData, StCoins: remainingStCoins };
          localStorage.setItem("loginData", JSON.stringify(updatedLoginData));
        } else {
        }
      }
    } catch (error) {}
  };

  const productPaymentClick = async () => {
    const finalTotal = Math.max(0, netPayable - stCoinsValue);
    const formData = {
      ClientId: loginData._id,
      CatName: selectedCategory,
      ClientName: loginData.Name,
      TotalAmount: cartTotalAmount,
      Email: loginData.Email,
      DeliveryCharge: 0,
      GrandTotal: finalTotal,
      Address: checkoutData.Address,
      city: "",
      AreaName: currentAddress.AName,
      Mobile: loginData.Mobile,
      PaymentMode: "COD",
      PaymentStatus: "COD",
      TxnId: "NA",
      CurrentStatus: "Not Paid",
      ProductCount: CartItems.length,
      Saving: cartTotalMrp - cartTotalPrice,
      Cashback: "0",
      orderDisplayName: checkoutData.orderDisplayName,
      OrderProducts: CartItems,
    };

    const config = {
      Headers: { "Content-Type": "application/json" },
    };

    const order = await axios.post(
      `${Baseurl}/api/v1/order/new`,
      formData,
      config
    );

    try {
      if (order.data.success === true) {
        dispatch(newOrder(order.data));
        dispatch(clearCart());
        dispatch(SetSelectedCategory(""));
        toast.success("Order SuccessFully");
        navigate("/success");

        if (useStCoins) {
          const remainingStCoins = loginData.StCoins - stCoins;

          const clientUpdate = {
            _id: loginData._id,
            StCoins: remainingStCoins,
          };
          dispatch(clientUpdateById(clientUpdate));
          const updatedLoginData = { ...loginData, StCoins: remainingStCoins };
          localStorage.setItem("loginData", JSON.stringify(updatedLoginData));
        } else {
        }
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="payment-container">
        {type === "Shop" && (
          <>
            <div className="row w-100">
              <div className="col-lg-6 col-12 ">
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="payementMode"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-start">Payment Mode</p>
                    <p className="text-start">
                      ST Coins : {loginData.StCoins - stCoinsShop}
                    </p>
                  </div>
                  <div className="payment-type">
                    <input type="radio" name="" id="" checked />
                    <span className="payment-1">
                      <LiaMoneyBillWaveSolid className="text-success fs-2" />{" "}
                      &nbsp; Cash After Service
                    </span>
                  </div>

                  <div className="payment-type">
                    <input
                      type="checkbox"
                      name="useStCoins"
                      id="useStCoins"
                      checked={useStCoinsShop}
                      onChange={(e) => setUseStCoinsShop(e.target.checked)}
                    />
                    <span className="payment-1">Use ST Coins</span>
                  </div>
                </div>
                <div className="mt-3"></div>
              </div>
              <div
                className="col-lg-5 col-12 "
                style={{ marginBottom: "5rem" }}
              >
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="summary"
                >
                  <div>
                    <p className="">Summary (Standard Service)</p>
                    <p
                      style={{ fontWeight: "lighter", fontSize: "9pt" }}
                      className=""
                    >
                      • Waxology - Rica (Full Arms, Full Legs & Underarms)
                    </p>
                    <p style={{ fontWeight: "lighter", fontSize: "9pt" }}>
                      Date & Slot Time {checkoutData.ServicePreferedDate} (
                      {checkoutData.ServicePreferedTime})
                    </p>
                  </div>

                  <div className="mt-5">
                    <p className="d-flex justify-content-between">
                      <span>Total MRP</span>
                      <span>₹ {shopCartTotalMrp}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Sub-Total</span>
                      <span>₹ {shopCartTotalPrice}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Saving Amount</span>
                      <span>- ₹ {shopCartTotalMrp - shopCartTotalPrice}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>ST Coin Used</span>
                      <span>- ₹ {stCoinsValueShop}/-</span>
                    </p>
                    <div className="mt-2">
                      <p className="d-flex justify-content-between">
                        {/* <span>NETPAYABLE</span> */}
                        <span>Grand Total</span>
                        <span>₹ {shopCartNetPayable - stCoinsValueShop}/-</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-2 p-2" onClick={() => shopPaymentClick()}>
                  <span className="btn btn-success w-100">
                    Pay ₹ {shopCartNetPayable - stCoinsValueShop}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "Product" && (
          <>
            <div className="row w-100">
              <div className="col-lg-6 col-12 ">
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="payementMode"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-start">Payment Mode</p>
                    <p className="text-start">
                      ST Coins : {loginData.StCoins - stCoins}
                    </p>
                  </div>
                  <div className="payment-type">
                    <input type="radio" name="" id="" checked />
                    <span className="payment-1">
                      <LiaMoneyBillWaveSolid className="text-success fs-2" />{" "}
                      &nbsp; Cash After Service
                    </span>
                  </div>

                  <div className="payment-type">
                    <input
                      type="checkbox"
                      name="useStCoins"
                      id="useStCoins"
                      checked={useStCoins}
                      onChange={(e) => setUseStCoins(e.target.checked)}
                    />
                    <span className="payment-1">Use ST Coins</span>
                  </div>
                </div>
                <div className="mt-3"></div>
              </div>
              <div
                className="col-lg-5 col-12 "
                style={{ marginBottom: "5rem" }}
              >
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="summary"
                >
                  <div>
                    <p className="">Summary (Standard Service)</p>
                    <p
                      style={{ fontWeight: "lighter", fontSize: "9pt" }}
                      className=""
                    >
                      • Waxology - Rica (Full Arms, Full Legs & Underarms)
                    </p>
                    <p style={{ fontWeight: "lighter", fontSize: "9pt" }}>
                      Date & Slot Time {checkoutData.ServicePreferedDate} (
                      {checkoutData.ServicePreferedTime})
                    </p>
                  </div>

                  <div className="mt-5">
                    <p className="d-flex justify-content-between">
                      <span>Total MRP</span>
                      <span>₹ {cartTotalMrp}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Sub-Total</span>
                      <span>₹ {cartTotalPrice - stCoinsValue}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Saving Amount</span>
                      <span>- ₹ {cartTotalMrp - cartTotalPrice}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>ST Coin Used</span>
                      <span>- ₹ {stCoinsValue}/-</span>
                    </p>
                    {/* <div
                  style={{
                    border: "1px inset black",
                    padding: "5px",
                    height: "80px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{ fontSize: "9pt" }}
                    className="d-flex justify-content-between"
                  >
                    <span>
                      Convenience fee <tt className="text-primary">info</tt>
                    </span>
                    <span>₹99.2/-</span>
                  </p>
                  <p
                    style={{ fontSize: "9pt" }}
                    className="d-flex justify-content-between"
                  >
                    <span>
                      Goods & services tax (Central & state tax){" "}
                      <tt className="text-primary">info</tt>
                    </span>

                    <span className="text-secondary">+ ₹17.8/-</span>
                  </p>
                </div> */}
                  </div>
                </div>

                <div className="mt-2 p-2" onClick={() => productPaymentClick()}>
                  <span className="btn btn-success w-100">
                    Pay ₹ {netPayable - stCoinsValue}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "HomeSaloon" && (
          <>
            <div className="row w-100">
              <div className="col-lg-6 col-12 ">
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="payementMode"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-start">Payment Mode</p>
                    <p className="text-start">
                      ST Coins : {loginData.StCoins - stCoinsSalon}
                    </p>
                  </div>
                  <div className="payment-type">
                    <input type="radio" name="" id="" checked />
                    <span className="payment-1">
                      <LiaMoneyBillWaveSolid className="text-success fs-2" />{" "}
                      &nbsp; Cash After Service
                    </span>
                  </div>

                  <div className="payment-type">
                    <input
                      type="checkbox"
                      name="useStCoins"
                      id="useStCoins"
                      checked={useStCoinsSalon}
                      onChange={(e) => setUseStCoinsSalon(e.target.checked)}
                    />
                    <span className="payment-1">Use ST Coins</span>
                  </div>
                </div>
                <div className="mt-3"></div>
              </div>
              <div
                className="col-lg-5 col-12 "
                style={{ marginBottom: "5rem" }}
              >
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className="summary"
                >
                  <div>
                    <p className="">Summary (Standard Service)</p>
                    <p
                      style={{ fontWeight: "lighter", fontSize: "9pt" }}
                      className=""
                    >
                      • Waxology - Rica (Full Arms, Full Legs & Underarms)
                    </p>
                    <p style={{ fontWeight: "lighter", fontSize: "9pt" }}>
                      Date & Slot Time {checkoutData.ServicePreferedDate} (
                      {checkoutData.ServicePreferedTime})
                    </p>
                  </div>

                  <div className="mt-5">
                    <p className="d-flex justify-content-between">
                      <span>Total MRP</span>
                      <span>₹ {homeSalonCartTotalMrp}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Sub-Total</span>
                      <span>₹ {homeSalonCartTotalPrice}/-</span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>Saving Amount</span>
                      <span>
                        - ₹ {homeSalonCartTotalMrp - homeSalonCartTotalPrice}/-
                      </span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>ST Coin Used</span>
                      <span>- ₹ {stCoinsValueSalon}/-</span>
                    </p>
                    <div className="mt-2">
                      <p className="d-flex justify-content-between">
                        {/* <span>NETPAYABLE</span> */}
                        <span>Grand Total</span>
                        <span>
                          ₹ {homeSalonCartNetPayable - stCoinsValueSalon}/-
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="mt-2 p-2"
                  onClick={() => homeSalonPaymentClick()}
                >
                  <span className="btn btn-success w-100">
                    Pay ₹ {homeSalonCartNetPayable - stCoinsValueSalon}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Payments;
