import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const About = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-heading">
          <h4 className="text-center">About Saloon Treat</h4>
        </div>
        <div className="about-write container-fluid">
          <p>
            SONU SALONTREAT PRIVATE LIMITED is India’s Prominent Saloon at Home,
            launched in 2024 and currently functioning in 50+ cities in India.
            Our commitment is to deliver quality Saloon and wellness services at
            home with top-level professionals & excellent leading products.
            Furthermore, you can also check out our services on Saloon Treat’s
            Website/App. With a user-friendly interface, you can book your
            parlour appointments at your fingertips, and our top-notch
            professionals will get a chance to serve you.
          </p>

          <p className="mt-2">
            With our intuitive stylists, therapists, and top-notch professionals
            we are delivering more than just Saloon Services at Home, including
            Therapies, Massage, Spa, Led Facial Services, Male Grooming, and
            Keratin Hair Spa, and all beauty services at your convenience.
          </p>

          <p className="mt-2">
            In fact, all our services are available at home for your
            convenience. The at home Saloon services are not only for ladies,
            but also men can enjoy the convenience of Grooming and Spa Services
            at home. Moreover, people can also enjoy the Services that we have
            introduced recently.
          </p>

          <p className="mt-2">
            Our beauty services start at Rs. 6/- minute where a beautician will
            come to your home and deliver top-notch Saloon services at
            unbelievable prices. Our goal is to make all our services
            pocket-friendly with utmost client satisfaction.
          </p>

          <p className="mt-2">
            Safety is of utmost importance for us. Therefore, our beauticians
            are tied-up with the 13 Steps Safety Protocol. Moreover, our clients
            are enormously safe due to hygienic services & top leading brand
            products. All our products are sealed and of one-time use (sachet
            packets). We provide 100% transparency in our products. So, if the
            clients want to use their products, they are free to book our
            professionals only for the services.
          </p>

          <div className="benefits mt-3">
            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-1.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>3000+ </span>
                <br />
                Professionals
              </p>
            </div>
            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-2.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>20 Lakh+ </span>
                <br />
                Bookings and <br /> Downloads
              </p>
            </div>
            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-3.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>50+</span> Cities in
                <br />
                India
              </p>
            </div>

            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-4.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>
                  India’s Top <br /> Rated{" "}
                </span>
                <br />
                beauty app
              </p>
            </div>

            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-5.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>
                  Top leading <br /> Rated{" "}
                </span>
                <br />& branded products
              </p>
            </div>

            <div>
              <span className="benefit-1 shadow">
                <img src="/assets/ben-6.png" alt="" className="img-fluid" />
              </span>
              <p className="text-center mt-2">
                <span style={{ color: "#AC2E57" }}>
                  Satisfactory <br /> Rated{" "}
                </span>
                <br />
                Pricing
              </p>
            </div>
          </div>

          <p className=" mt-2">
            Now that we live in the 21st Century, we can not only shop for
            groceries from the comfort of our homes but also book a professional
            to get beauty and salon services at home.
          </p>

          <div className="banner mt-4">
            <p>
              Try Saloon Treat Beauty Services at the doorstep – India’s
              Outclass Salon Platform that turns your lovely home into a
              Professional Salon.
            </p>
          </div>
        </div>
        <div className="milestone-container mt-5">
          <div className="milestone ">
            <div className="m-2">
              <h1 style={{ color: "#AC2B57" }}>Our Milestones</h1>
            </div>
            <div className="m-2">
              <p style={{ fontSize: "9pt" }}>
                We have been active in this field since 2017 and have achieved 1
                million plus bookings and app downloads. Our 3000+ professionals
                are now fueling us with their endless efforts to exceed the
                expectations of our clients. And are dynamic in almost all the
                cities in India named Agra,Aligarh,Amritsar,Bareilly,Bhagalpur,
                Chandigarh,Dehradun, Delhi,Faridabad,Ghaziabad,
                Gorakhpur,Greater Noida,Gurgaon,Haridwar,
                Haldwani,Jaipur,Jammu,Jalandhar,Jhansi,Kanpur,
                Lucknow,Ludhiana,Meerut,Moradabad,Mohali,
                Noida,Patna,Panchkula,Prayagraj,Roorkee,Rudrapur,
                Varanasi,Vijayavada,Zirakpur, Nagpur, Srinagar,
                Ahmedabad,Goa,Nashik,Pune,Mumbai,Bhillai,Bhopal,
                Gwalior,Indore,Jabalpur,Raipur,Ujjain,Bhubaneshwar.
              </p>
            </div>
          </div>
        </div>

        <div className="core mt-5">
          <div className="heading">
            <h3 className="text-center fw-bold">Our Core Beliefs</h3>
          </div>
          <div className="core-beliefs mt-4">
            <div className="belief-1">
              <div>
                <span>
                  <img className="img-fluid" src="/assets/v-1.webp" alt="" />{" "}
                  <h5>Mission</h5>
                </span>
                <div className="desc mt-4">
                  <p style={{ fontWeight: "100" }}>
                    Our mission is to provide each of our clients with the best
                    possible spa and salon services at home in order to
                    establish a long-lasting relationship based on trust and
                    satisfaction. Our expertise and experience in the beauty
                    industry enable us to visualize our mission with utmost
                    safety and satisfaction.
                  </p>
                </div>
              </div>
            </div>

            <div className="belief-1">
              <div>
                <span>
                  <img className="img-fluid" src="/assets/v-2.webp" alt="" />{" "}
                  <h5>Vision</h5>
                </span>
                <div className="desc mt-4">
                  <p style={{ fontWeight: "100" }}>
                    Our vision is to empower women across India and deliver the
                    highest quality salon-like services at home with a mark of
                    excellence. We envision ourselves being a one-stop spa and
                    salon service provider which caters to everyone’s beauty and
                    wellness needs across India.
                  </p>
                </div>
              </div>
            </div>

            <div className="belief-1">
              <div>
                <span style={{ width: "170px" }}>
                  <img className="img-fluid" src="/assets/v-3.webp" alt="" />{" "}
                  <h5>Philosophy</h5>
                </span>
                <div className="desc mt-4">
                  <p style={{ fontWeight: "100" }}>
                    Our primary values are transparency and honesty. We
                    prioritise our clients’ needs by giving them complete
                    flexibility of decision-making in all aspects. Our customers
                    can take a variety of services at their convenience and
                    according to their needs, from products & services to places
                    & time.
                  </p>
                </div>
              </div>
            </div>

            <div className="belief-1">
              <div>
                <span>
                  <img className="img-fluid" src="/assets/v-4.webp" alt="" />{" "}
                  <h5>Values</h5>
                </span>
                <div className="desc mt-4">
                  <p style={{ fontWeight: "100" }}>
                    Our core values are the inclusion of Women Empowerment, and
                    Transparency with our clients and service providers, along
                    with providing superior customer service. Saloon Treat is
                    pleased to have a large family of more than 1 million happy
                    customers and 3000+ service providers all throughout India.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="service-container mt-5">
          <div className="services-offer">
            <div className="heading">
              <h4>The services that we offer</h4>
            </div>
            <div className="swiper mt-3">
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay
              >
                <SwiperSlide>
                  <div className="card p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Waxing</p>
                      <img
                        style={{ height: "55px", width: "55px" }}
                        className="img-fluid"
                        src="https://www.yesmadam.com/static/images/Waxing.webp"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
