import React, { useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { LocationOn, Search } from "@mui/icons-material";
import { MdOutlineArrowDropDown } from "react-icons/md";
import ReactModal from "react-modal";
import { Baseurl } from "../../config/BaseUrl";

const Homebanner = () => {
  const [modal, setmodal] = useState(false);
  const navigate = useNavigate();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          background: "#FFFFFF",
          height: "45px",
          width: "50px",
          right: "-2px",
          justifyContent: "center",
          alignItems: "center",
          borderBottomLeftRadius: "50%",
          borderTopLeftRadius: "50%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          zIndex: 1,
          display: "flex",
          background: "#FFFFFF",
          height: "45px",
          width: "50px",
          left: "-4px",
          justifyContent: "center",
          alignItems: "center",
          borderBottomRightRadius: "50%",
          borderTopRightRadius: "50%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }
  const Setting = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    speed: 500,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const mobileSettings = {
    speed: 500,
    slidesToShow: 1,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  let sliderSettings = Setting;

  if (window.matchMedia("(max-width:768px)").matches) {
    sliderSettings = mobileSettings;
  }

  return (
    <>
      <div className="home-banner">
        <div className="container-fluid-lg  mx-auto w-100 overflow-hidden">
          <div id="Slider" className="conatainer-fluid-lg w-100 mx-auto">
            <Slider
              className="mx-auto w-100 h-100"
              {...Setting}
              {...sliderSettings}
            >
              <div
                className="w-100 mx-auto "
                onClick={() => navigate(`/salon-at-home/Waxing`)}
              >
                <img src="\assets\ban1.png" alt="" className="img-fluid" />
              </div>
              <div
                className="w-100"
                onClick={() => navigate(`/male-spa/Summer%20care`)}
              >
                <img src="\assets\ban4.png" alt="" className="img-fluid" />
              </div>

              <div
                className="w-100 mx-auto "
                onClick={() => navigate(`/pet-food/Dog%20food`)}
              >
                <img src="\assets\ban5.png" alt="" className="img-fluid" />
              </div>
            </Slider>
          </div>
        </div>
        {/* <div
        id="search"
        className="search-bar-container border border w-50 shadow mx-auto bg-white rounded-5"
      >
        <div
          onClick={() => setmodal(true)}
          className="location-container p-1 d-flex align-items-center"
        >
          <LocationOn className="location-icon" />
          <div className="location-text ms-2">
            <p className="location-title mb-0">Delhi</p>
            <p
              id="address"
              className="location-subtitle text-secondary fw-lighter mb-0"
            >
              25, Shivaji Marg, Block A1, Janakp...
            </p>
          </div>
          <MdOutlineArrowDropDown className="dropdown-icon ms-2" />
        </div>
        <div className="divider"></div>
        <div className="search-input-container d-flex align-items-center">
          <Search className="search-icon me-2" />
          <input
            type="search"
            title="search"
            placeholder="Search for Services"
            className="form-control border-0 search-input"
          />
        </div>
      </div> */}
        <div>
          <ReactModal
            isOpen={modal}
            onRequestClose={() => setmodal(false)}
            style={{
              content: {
                width: window.matchMedia("(max-width:768px)").matches
                  ? "65%"
                  : "40%",
                height: "200px",
                overflow: "auto",
              },
              overlay: { backgroundColor: "rgba(0,0,0,0)", zIndex: "96" },
            }}
          >
            <div className="row">
              <div className="col-6">
                <table>
                  <tbody>
                    <tr>
                      <td>hello</td>
                    </tr>
                    <tr>
                      <td>hello2</td>
                    </tr>
                    <tr>
                      <td>hello3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                accusantium provident deserunt velit, perferendis blanditiis
                ipsum. Accusantium, sint distinctio dolor, laboriosam nisi,
                itaque iusto vitae reiciendis aliquam nam quibusdam
                exercitationem.
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </>
  );
};

export default Homebanner;
