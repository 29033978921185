import React, { useEffect } from "react";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const SuccessfulPayment = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate('/', { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);
  return (
    <div className="success-full-container">
      <div>
        <div className="success">
          <h1>
            <FaCheck style={{ fontSize: "100px" ,color:'#fff'}} />
          </h1>
        </div>
        <div className="mt-2">
          <h3 className="text-center">Payment Successful</h3>
        </div>
        <div className="mt-1">
          <p style={{ fontFamily: "Roboto" ,fontSize:'14px'}} className="text-center">
            Thank you for your payment. We will in contact with more details
            shortly
          </p>
        </div>
        <div className="success-btn mt-2">
          <span className="w-100" onClick={() => navigate("/")}>continue shopping</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPayment;
