import React, { useState } from "react";
import "./Faqs.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { FaLessThan } from "react-icons/fa";
// import { F_AccordList } from "./F_AccordList";
// import F_Accordian from "./F_Accordian";
const Faqs = () => {
  let [state, setState] = useState(false);
  let [state1, setState1] = useState(false);
  let [state2, setState2] = useState(false);
  let [state3, setState3] = useState(false);
  let [state4, setState4] = useState(false);
  let [state5, setState5] = useState(false);
  let [state6, setState6] = useState(false);
  let [state7, setState7] = useState(false);
  let [state8, setState8] = useState(false);
  let [state9, setState9] = useState(false);
  let [state10, setState10] = useState(false);
  let [state11, setState11] = useState(false);
  let [state12, setState12] = useState(false);
  let [state13, setState13] = useState(false);
  let [state14, setState14] = useState(false);
  let [state15, setState15] = useState(false);
  let [state16, setState16] = useState(false);
  let [state17, setState17] = useState(false);
  let [state18, setState18] = useState(false);
  let [state19, setState19] = useState(false);
  let [state20, setState20] = useState(false);
  let[close1,setClose1]=useState(false)
  let[close2,setClose2]=useState(false)
  let[close3,setClose3]=useState(false)
  let[close4,setClose4]=useState(false)
  let[close5,setClose5]=useState(false)
  let[close6,setClose6]=useState(false)
  let[close7,setClose7]=useState(false)
  let[close8,setClose8]=useState(false)
  let[close9,setClose9]=useState(false)
  let[close10,setClose10]=useState(false)
  let[close11,setClose11]=useState(false)
  let[close12,setClose12]=useState(false)
  let[close13,setClose13]=useState(false)
  let[close14,setClose14]=useState(false)
  let[close15,setClose15]=useState(false)
  let[close16,setClose16]=useState(false)
  let[close17,setClose17]=useState(false)
  let[close18,setClose18]=useState(false)
  let[close19,setClose19]=useState(false)
  let[close20,setClose20]=useState(false)
  let[close21,setClose21]=useState(false)
  return (
    <>
      <div style={{marginTop:'30px'}} className="Faqs_main">
        <nav>
          <p className="lessthen">
            <FaLessThan />
          </p>
          <div className="head">FAQs</div>
        </nav>
        <div className="faqs_part">
          <div className="FMain_container">
            <div className="Header">
              <h2>FAQs</h2>
            </div>

            {/* <div className="map">
              {
                F_AccordList.map((index,Que,Ans)=>{   
                    return<F_Accordian key={index} Que={Que} Ans={Ans}/>
                })
              }
             </div> */}
            <div className="main_ques">
              <div className="sec-1">
                <div className="Que">
                  <div className="que">
                    <h5 onClick={() => setState(!state)}>
                      Why choose at-home services?{" "}
                     <span onClick={()=>{setClose1(!close1)}}>{close1?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>

                    {state ? (
                      <span className="ans">
                        <p>
                          The lifestyle nowadays demands you to be on the go,
                          fast, and effective. You are juggling between work,
                          family, friends, and your personal goals. A little bit
                          of pampering is all you need to revive, rejuvenate,
                          and restore yourself! Saloon Treat brings salon or
                          home services experts to your doorstep so that you can
                          enjoy a little me time. We fit ourselves into your
                          crazy schedules, and leave you looking good & feeling
                          relaxed!
                        </p>
                        <p>
                          Not to forget that we are amid a pandemic and it`s
                          still not safe to go outside, but don`t worry we take
                          all the possible precautions to keep you and us safe.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState1(!state1);}}>
                      How can I book SALOON TREAT Services?{" "}
                      <span onClick={()=>{setClose2(!close2)}}>{close2?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                </h5>
                
                    {state1 ? (
                      <span className="ans">
                        <p>
                          You can book SALOON TREAT Services from our Android or
                          iOS Application, website (www.saloontreat.com) or
                          contact our Customer Happiness Team at 9797639797. We
                          will be very happy to pamper you.
                        </p>
                        <p>
                          Just download the Saloon Treat App and follow the
                          below steps-
                        </p>
                        <p>
                          When you open the App you would see categories of the
                          services available. Click on the preferred category.
                        </p>
                        <p>
                          You would be presented with the sub-categories now.
                          Choose according to your own preference.
                        </p>
                        <p>
                          After choosing the sub-category, you can choose your
                          desired service and click “Add to cart”.
                        </p>
                        <p>
                          Go to your cart, select a time slot, and book your
                          service.
                        </p>
                        <p>
                          After you have booked the service, wait for an expert
                          to be assigned to you.
                        </p>
                        <p>
                          The assigned professional will reach your address and
                          render the services booked
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState2(!state2);}}>
                      What are your operating hours?{" "}
                      <span onClick={()=>{setClose3(!close3)}}>{close3?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state2 ? (
                      <span className="ans">
                        <p>
                          Our customer care operates daily from 9 AM to 8 PM,
                          dedicated to addressing your inquiries promptly.
                          Contact us within these hours for optimal solutions.
                          Safety of customers and partners is paramount; thus,
                          service booking time slots are restricted from 8 AM to
                          6:30 PM. For early morning services, kindly book 1-2
                          days ahead to secure availability and convenience
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState3(!state3);}}>
                      Saloon Treat provides services in which cities?{" "}
                      <span onClick={()=>{setClose4(!close4)}}>{close4?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state3 ? (
                      <span className="ans">
                        <p>
                          We are currently operating in Pitampura, Geeta Colony,
                          GTB Nagar, Karol Bagh, Munirka, Vasundhara Enclave,
                          Nangloi, Chattarpur, Najafgarh Delhi, Mohan estate -
                          Delhi, Rohini, Dwarka, South Delhi, Subhash Nagar,
                          Govind puri, Shahdara, Faridabad, Ghaziabad,
                          Indirapuram, Rajnagar, Gurugram, Noida Expressway,
                          Greater Noida, Noida, Agra, Bareilly, Bhubaneswar,
                          Chandigarh, Dehradun, Jaipur, Jalandhar, Jammu,
                          Kanpur, Lucknow, Ludhiana, Meerut, Panchkula, Raipur,
                          Varanasi, Patna, and Mumbai.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState4(!state4)}}>
                      What is “Per Minute Pricing?{" "}
                      <span onClick={()=>{setClose5(!close5)}}>{close5?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state4 ? (
                      <span className="ans">
                        <p>
                          Saloon Treat`s Per-Minute Pricing Model is a unique
                          concept of charging for the service according to
                          minutes. It allows the user to separately view the
                          product cost and service charge and see the actual
                          cost of the product used in the service.
                        </p>
                        <p>
                          For example, our beauty services start at ₹6/ min so
                          whichever service you choose the service cost would be
                          calculated according to the total minutes required for
                          the service multiplied by ₹6.
                        </p>
                        <p>
                          Another unique concept introduced by Saloon Treat is
                          that you can choose to pay only for the service and
                          eliminate the product cost. This option is feasible if
                          you wish to use your own product.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{ setState5(!state5);}}>
                      Do I need to provide anything for the service?{" "}
                      <span onClick={()=>{setClose6(!close6)}}>{close6?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state5 ? (
                      <span className="ans">
                        <p>
                          No, we carry everything that is required for the
                          service that you book. You don't need to worry about
                          anything. All you need to do is sit back, relax, and
                          enjoy the best at-home services.
                        </p>
                        <p>
                          In case of a beauty service booking, we request our
                          clients to let us use their bed or any comfortable
                          seating option for the client so that our beauty
                          expert can effectively carry out the services. And our
                          expert might request some water, but that`s all.
                        </p>
                        <p>
                          Our experts carry disposable sheets with them so you
                          don`t have to worry about any mess being created on
                          the mattress. The expert uses disposable towels during
                          the service to maintain hygiene and safety
                          precautions. Also, you will be provided with a
                          disposable gown as well so that you don`t need to
                          worry about the clothing.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState6(!state6);}}>
                      What is the difference between a Premium Beautician and a
                      Standard Beautician?
                      <span onClick={()=>{setClose7(!close7)}}>{close7?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state6 ? (
                      <span className="ans">
                        <p>
                          Saloon Treat's beauticians are highly trained and
                          qualified for the services they render. We offer two
                          types of beautician's services- Standard and Premium.
                          All our beauticians have experience of at least 3
                          years, while our Premium beauticians have experience
                          of at least 5 years.
                        </p>
                        <p>
                          All our beauticians have 3-5 star ratings, while our
                          Premium beauticians have 7-star ratings only and no
                          negative feedback in the past. They are highly
                          experienced professionals who know exactly how to make
                          you feel relaxed & rejuvenated.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState7(!state7);}}>
                      Are your products branded and of good quality?{" "}
                      <span onClick={()=>{setClose8(!close8)}}>{close8?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state7 ? (
                      <span className="ans">
                        <p>
                          We, at Saloon Treat, take pride in serving our
                          customers with the best quality products. To ensure
                          this we have tied up with some of the most renowned
                          and trusted brands in the market like O3+, Vedic line,
                          Rica, Sara, Inatur, Lotus, etc. All our products go
                          through a round of quality checks before being used by
                          our experts.
                        </p>
                        <p>
                          Also, for the cleaning services we use 100% branded
                          and good quality products only that not only clean
                          your place but also disinfect it.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState8(!state8);}}>
                      What are the safety precautions taken by the expert?{" "}
                      <span onClick={()=>{setClose9(!close9)}}>{close9?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state8 ? (
                      <span className="ans">
                        <p>
                          Client security is of utmost importance to Saloon
                          Treat. We run an all-round check on all our Experts
                          before bringing them onboard into our operations and
                          ensure that their details reach you much before the
                          job starts.
                        </p>
                        <p>
                          However, you are requested to take care of your
                          personal belongings or valuables in the presence of
                          our experts. Saloon Treat will not be liable for any
                          damage or theft arising in case of failure to do so.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState9(!state9);}}>
                      When can I book an expert?{" "}
                      <span onClick={()=>{setClose10(!close10)}}>{close10?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state9 ? (
                      <span className="ans">
                        <p>
                          To book a specific expert, check their availability
                          and schedule your appointment accordingly. If you want
                          to rebook a favorite professional, we recommend
                          booking 1-2 days in advance. We offer various time
                          slots to accommodate our customers and ensure prompt
                          service.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState10(!state10);}}>
                      What will your experts do in cleaning service that our
                      maid doesn't
                      <span onClick={()=>{setClose11(!close11)}}>{close11?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state10 ? (
                      <span className="ans">
                        <p>
                          We, at Saloon Treat, take pride in serving our
                          customers with the best quality products. To ensure
                          this we have tied up with some of the most renowned
                          and trusted brands in the market like O3+, Vedic line,
                          Rica, Sara, Inatur, Lotus, etc. All our products go
                          through a round of quality checks before being used by
                          our experts.
                        </p>
                        <p>
                          Also, for the cleaning services we use 100% branded
                          and good quality products only that not only clean
                          your place but also disinfect it.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="sec-2">
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState11(!state11);}}>
                      How qualified are your Experts?{" "}
                      <span onClick={()=>{setClose12(!close12)}}>{close12?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state11 ? (
                      <span className="ans">
                        <p>
                          Saloon Treat prioritizes seamless operations and
                          stress-free experiences. To ensure smooth
                          communication, address instructions, locations,
                          feedback, and cater to diverse clientele, we enlist
                          competent and dedicated experts. Our professionals
                          have at least a High School qualification and possess
                          strong English and Hindi language proficiency.
                          Moreover, our team includes skilled individuals with a
                          minimum of three years of industry experience, backed
                          by certifications from renowned salon brands. At
                          SaloonTreat, they are empowered to enhance their
                          skills and expertise, contributing to their growth and
                          excellence.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState12(!state12);}}>
                      Do you provide Male grooming services also?{" "}
                      <span onClick={()=>{setClose13(!close13)}}>{close13?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state12 ? (
                      <span className="ans">
                        <p>
                          Yes, we do provide grooming services for men. Grooming
                          shouldn`t be defined by genders which is why we
                          included services such as hair cut, facial, shaving,
                          etc., for men. Saloon Treat at home grooming services
                          is a boon to those who have a packed schedule or don`t
                          want to step out of the house and require professional
                          services at their preferred time. We send our experts
                          to carry out the services at the comfort of your home
                          at your chosen time slot. All you have to do is book
                          an appointment via the Saloon Treat App or our websit{" "}
                          <b className="color"> -www.saloontreat.com</b>{" "}
                          -www.saloontreat.com
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState13(!state13);}}>
                      Can I use my own products for a service?{" "}
                      <span onClick={()=>{setClose14(!close14)}}>{close14?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state13 ? (
                      <span className="ans">
                        <p>
                          Yes, you can provide your own products for service.
                          This is one of the unique features of Saloon Treat
                          wherein you can provide your own product while
                          availing of our services. This makes Saloon Treat even
                          more affordable and ensures that no product goes to
                          waste. You can avail this option simply by unchecking
                          the product cost in your cart. <br />
                          Transparency is our best policy, and we practice it
                          throughout. Only with Saloon Treat, you will see a
                          unique feature showing product cost and service charge
                          separately. This enables you to understand the cost of
                          a product and decide if you want to pay for it or use
                          your own products.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState14(!state14);}}>
                      What to do if I am not satisfied with the services of your
                      expert?
                      <span onClick={()=>{setClose15(!close15)}}>{close15?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state14 ? (
                      <span className="ans">
                        <p>
                          Firstly, we apologize for any discomfort caused by the
                          service. If you are not satisfied with our service,
                          please raise a complaint within 5 days of the service
                          done. You can get back to us via the Customer
                          Happiness Team at 9797639797 or mail us at
                          support@saloontreat.com. We will make sure your
                          queries and issues are resolved.The satisfaction of
                          our customers is our topmost priority and it is
                          extremely important to us that you enjoy Saloon
                          Treat`s services. We hope we can resolve your issue
                          and we promise to serve you better the next time.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState15(!state15);}}>
                      Can I request a specific Expert for my services?{" "}
                      <span onClick={()=>{setClose16(!close16)}}>{close16?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state15 ? (
                      <span className="ans">
                        <p>
                          Yes, you can request a specific expert while booking,
                          provided that the expert has served you in the past.
                          In such a case, we request you to kindly book an
                          appointment with the expert at least 1-2 days before
                          making sure the expert is available at your preferred
                          time slot.To choose a specific expert all you have to
                          do is add your desired services in your cart and at
                          the bottom right part of your cart, you will see the
                          names of the experts you have served you in the past.
                          Just click on the desired expert`s name and book away!
                          Your chosen professional will be at your doorstep at
                          the selected time.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState16(!state16);}}>
                      Can I cancel or reschedule the service anytime?{" "}
                      <span onClick={()=>{setClose17(!close17)}}>{close17?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state16 ? (
                      <span className="ans">
                        <p>
                          Yes, you can cancel/reschedule your booking 4 hours
                          before the booked time slot by contacting our Customer
                          Happiness Team at 9797639797. Depending upon the
                          availability of the slot and the expert we will
                          reschedule your booking as per your preferred date &
                          time.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState17(!state17);}}>
                      What if I want to add on service during the time of
                      service being provided or after making the booking?
                      <span onClick={()=>{setClose18(!close18)}}>{close18?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state17 ? (
                      <span className="ans">
                        <p>
                          At SaloonTreat, we aim to ensure your satisfaction,
                          spread love, and save you time. To add extra services
                          during or after your booking, directly inform the
                          service provider. They'll assess feasibility and share
                          details. For post-booking additions, contact our
                          customer support. They'll help add services, if
                          possible. Note that availability depends on provider's
                          schedule and service type. Early communication ensures
                          seamless arrangements. Additional services during
                          ongoing services are possible when the expert's next
                          slot is vacant.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState18(!state18);}}>
                      How can I pay for the services?{" "}
                      <span onClick={()=>{setClose19(!close19)}}>{close19?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state18 ? (
                      <span className="ans">
                        <p>
                          You can pay for the services opted in advance via the
                          online payment modes, such as Paytm, Google Pay,
                          debit/credit card, or you can pay after the service as
                          well via cash, UPI, or PayTm.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState19(!state19);}}>
                      Will there be any mess created during the service?{" "}
                      <span onClick={()=>{setClose20(!close20)}}>{close20?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state19 ? (
                      <span className="ans">
                        <p>
                          No, we are known for giving hygienic and zero mess
                          services; our professionals are highly trained with
                          all the work ethics they are required to incorporate
                          into their jobs. During training and skill development
                          sessions we teach them how to make a customer feel
                          blessed and bliss. Proper disposal in garbage bags, no
                          mess, and cleanliness maintained are the qualities of
                          our professionals you get to see. We assure no mess
                          would be there during the service at your place.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="Que">
                  <div className="que">
                    <h5 onClick={()=>{setState20(!state20);}}>
                      What if the expert reaches late?{" "}
                      <span onClick={()=>{setClose21(!close21)}}>{close21?<IoMdArrowDropdown className="drop_icon" />:<IoMdArrowDropup className="drop_icon" />}</span> 
                    </h5>
                    {state20 ? (
                      <span className="ans">
                        <p>
                          Our motto is to serve our clients in the best possible
                          manner, and our professionals take their sweet time
                          while providing the services. There may be an instance
                          where our expert isn`st able to reach on time because
                          of some extra time consumed in the previous booking.
                          In such a case, we present our sincere apologies and
                          request you to kindly cooperate, if possible. If you
                          are on a tight schedule and want to reschedule your
                          booking, we would be happy to do it for you.
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
