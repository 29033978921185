import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  mob: "",
  otp: "",
  partnermob: "",
  partnerOtp: "",
  isAuth: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  clientid: "",
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  agentid: "",
  email: "",
  name: "",
  address: localStorage.getItem("address")
    ? JSON.parse(localStorage.getItem("address"))
    : [],
  addressLoading: true,
  currentAddress: localStorage.getItem("currentAddress")
    ? JSON.parse(localStorage.getItem("currentAddress"))
    : "",
  updateClientLoading: false,
  pageStatus: 0,
  selectedCarModels: [],
  currentCarModels: "",
  currentCar: "",
  carTotal: [],
  carTotalLoading: true,
  carModeltotal: [],
  carModelLoading: true,
  currentCarPageStatus: 0,
  isLoginModalOpen: false,
  isAddressModalOpen: false,
  loginMobile:''
};

export const getcarManuf = createAsyncThunk(
  "Authentication/getcarManuf",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cars/all`;
      const resp = await axios(url);
      return resp.data.cars;
    } catch (error) {
      return thunkAPI.rejectWithValue("cars Not Found");
    }
  }
);
export const getcarModel = createAsyncThunk(
  "Authentication/getcarModel",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/carModel/all`;
      const resp = await axios(url);
      return resp.data.carModels;
    } catch (error) {
      return thunkAPI.rejectWithValue("carModel Not Found");
    }
  }
);

export const loginAuthentication = createAsyncThunk(
  "Athentication/loginAuthentication",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const otp = formdetails.OTP;

      const url = `https://mobicomm.dove-sms.com/submitsms.jsp?user=shantire&key=2ad16bc0f9XX&mobile=+91${mob}&message=Dear Customer, Your OTP for logging in to KiranaWorld account is ${otp}. Do not share with anyone. Regards Team Kirana World, a Unit of Shanti Retails.&senderid=KRNWLD&accusage=1&entityid=1201159194292972286&tempid=1407168898514000516`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) {}

      resp = await axios.get(url);

      return resp;
    } catch (error) {
      return error;
    }
  }
);

export const addAddress = createAsyncThunk(
  "address/addaddress",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "Content-Type": "application/Json",
        },
      };
      const url = `${Baseurl}/api/v1/client/address`;
      const resp = await axios.post(url, formData, config);
      console.log(resp, "resp");
      return resp.data;
    } catch (error) {
      thunkAPI.rejectWithValue("Address not able to save");
    }
  }
);

export const clientUpdateById = createAsyncThunk(
  "Athentication/userUpdateById",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/client/Clientid/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("User Not Updated");
    }
  }
);

const AuthenticationSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    setLoginMobile(state,action){
      state.loginMobile=action.payload
    },
    SetLoginModalOpen(state, action) {
      state.isLoginModalOpen = action.payload;
    },
    SetAddressModalOpen(state, action) {
      state.isAddressModalOpen = action.payload;
    },
    SetEditAddressModalOpen(state, action) {
      state.isAddressModalOpen = action.payload;
    },
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.OTP;
    },
    PartnerLoginOtp(state, action) {
      state.partnermob = action.payload.number;
      state.partnerOtp = action.payload.OTP;
    },
    setselectedCarModels(state, action) {
      state.selectedCarModels = [...state.selectedCarModels, action.payload];
      localStorage.setItem(
        "selectedCarModels",
        JSON.stringify(state.selectedCarModels)
      );
    },
    setRemoveselectedCarModels(state, action) {
      state.selectedCarModels = [];
      localStorage.setItem(
        "selectedCarModels",
        JSON.stringify(state.selectedCarModels)
      );
    },
    setcarTotal(state, action) {
      state.carTotal = action.payload;
    },
    setcarModeltotal(state, action) {
      state.carModeltotal = action.payload;
    },
    setCurrentCarPageStatus(state, action) {
      state.currentCarPageStatus = action.payload;
    },
    setcurrentCarModels(state, action) {
      state.currentCarModels = action.payload;
      localStorage.setItem(
        "currentCarModels",
        JSON.stringify(state.currentCarModels)
      );
    },
    pageStatusChange(state, action) {
      state.pageStatus = action.payload;
    },
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.loginData = action.payload;
      state.clientid = action.payload._id;
      state.address = action.payload.Addresses;
      if (state.address.length >= 1 && state.currentAddress === "") {
        state.currentAddress = state.address[0];
      }

      state.updateClientLoading = true;
      localStorage.setItem("loginData", JSON.stringify(action.payload));
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      state.isAuth = false;
      state.clientid = "";
      state.address = [];
      state.currentAddress = "";
    },
    updateAddress(state, action) {
      state.address = action.payload;
      localStorage.setItem("address", JSON.stringify(action.payload));
    },

    currAdress(state, action) {
      state.currentAddress = action.payload;
      localStorage.setItem("currentAddress", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getcarManuf.pending, (state) => {
        state.carTotalLoading = true;
      })
      .addCase(getcarManuf.fulfilled, (state, action) => {
        state.carTotalLoading = false;
        state.carTotal = action.payload;
      })
      .addCase(getcarManuf.rejected, (state) => {
        state.carTotalLoading = true;
      })
      .addCase(getcarModel.pending, (state) => {
        state.carModelLoading = true;
      })
      .addCase(getcarModel.fulfilled, (state, action) => {
        state.carModelLoading = false;
        state.carModeltotal = action.payload;
      })
      .addCase(getcarModel.rejected, (state) => {
        state.carModelLoading = true;
      })
      .addCase(addAddress.pending, (state) => {
        state.addressLoading = true;
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        // state.address=[action.payload.client.Addresses,...state.address];
        // state.currentAddress=action.payload.client.Addresses[0]
        // state.addressLoading=true
      })
      .addCase(addAddress.rejected, (state) => {
        state.addressLoading = true;
      });
  },
});

export const {
  LoginOtp,
  PartnerLoginOtp,
  pageStatusChange,
  signin,
  signout,
  updateAddress,
  currAdress,
  setselectedCarModels,
  setcurrentCarModels,
  setcarTotal,
  setcarModeltotal,
  setRemoveselectedCarModels,
  setCurrentCarPageStatus,
  SetLoginModalOpen,
  SetAddressModalOpen,
  setLoginMobile
} = AuthenticationSlice.actions;
export const authActions = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
