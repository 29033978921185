import React, { useEffect, useState } from "react";
import { Clock } from "react-bootstrap-icons";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { AiFillLike } from "react-icons/ai";
import { IoStar } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import SelectCarDetails from "./SelectCarDetails";
import { useNavigate } from "react-router-dom";

const SalonList = () => {
    const [salonList, setSalonList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const navigate=useNavigate()
    useEffect(() => {
        const getSaloonList = async () => {
            const url = `${Baseurl}/api/v1/saloon/all`;
            const response = await axios.get(url);
            if (response.data.success) {
                setSalonList(response.data.saloon);
            }
        };
        getSaloonList();
    }, []);
    useEffect(() => {
        const getServiceList = async () => {
            const url = `${Baseurl}/api/v1/saloonServices/all`;
            const response = await axios.get(url);
            if (response.data.success) {
                console.log(response.data.saloon);
                setServiceList(response.data.saloon);
            }
        };
        getServiceList();
    }, []);

    console.log(salonList, "salonList");

    return (
        <div className="booking-main">
            <div className="lib">
                <div className="secondbook" >
                    {salonList.map((indSaloon, index) => (
                        <div className="cardbox1" key={index} onClick={()=>navigate("/saloonNearMe")}>
                            <div className="cardbox">
                                <div className="p-2" style={{width:'20%'}}>
                                    <img
                                        src={indSaloon.desktopImage}
                                        className="w-100"
                                        style={{ height: "180px", borderRadius: "5px" }}
                                        alt=""
                                    />
                                </div>
                                <div className="p-2" style={{width:'80%'}}>
                                    <div>
                                        <p
                                            style={{ fontSize: "20px", fontWeight: "550" }}
                                            className="box-text"
                                        >
                                            <AiFillLike
                                                style={{
                                                    color: "#ffffff",
                                                    backgroundColor: "#2D3038",
                                                    marginRight: "1px",
                                                    marginBottom: "3px",
                                                    padding: "2px",
                                                    borderRadius: "5px",
                                                }}
                                            />{" "}
                                            {indSaloon.name}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                        className="box-text mt-2"
                                    >
                                        <p
                                            style={{
                                                display: "flex",
                                                fontSize: "20px",
                                                gap: "2px",
                                                alignItems: "center",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            <IoStar style={{ color: "#FF6E00" }} />
                                            <IoStar style={{ color: "#FF6E00" }} />
                                            <IoStar style={{ color: "#FF6E00" }} />
                                            <IoStar style={{ color: "#FF6E00" }} />
                                            <IoStar style={{ color: "#D7D7D7" }} />
                                        </p>
                                    </div>
                                    <div style={{ fontSize: "15px", display: 'flex', alignItems: 'center' }} className="box-text mt-2">
                                        <CiLocationOn style={{ fontSize: "20px", color: "#000" }} />{" "}
                                        <span style={{ marginTop: '3px' }}>{indSaloon.address},{indSaloon.area}</span>
                                    </div>
                                    <div style={{ display: 'flex',height:'10vh',justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                                        {
                                            serviceList.filter(indService =>
                                                salonList.find(indSalon =>
                                                    indSalon._id === indService.superCategoryId
                                                )
                                            ).slice(0,2).map(indService => (
                                                <div style={{ width: '25%', boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", borderRadius: '5px', padding: '5px 10px' }}>
                                                    <div>{indService.name}</div>
                                                    <div>₹{indService.price}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div id="whatsappbtn" className="box-text mt-2">
                                        <FaWhatsapp
                                            style={{ color: "#fff" }}
                                        />
                                        <span className="pt-1">{indSaloon.phone}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="thirdbook">
                    <SelectCarDetails />
                </div>
            </div>
        </div>
    );
};

export default SalonList;
