import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoClock } from "react-icons/go";
import { addtoCart } from "../redux/cart/CartSlice";
import Media1 from "../pages/media1";
import toast from "react-hot-toast";

function Facial() {
  const { categorytotal, categoryLoading } = useSelector(
    (store) => store.category
  );
  const dispatch = useDispatch();
  const [SaloonData, setSaloonData] = useState([]);
  function HandleClick(e) {
    dispatch(addtoCart(e));
    toast.success("Item added in cart");
  }
  return (
    <div className="container-fluid-lg">
      <div className="contmedia1">
        <Media1 />
      </div>
      <div id="waxing" className="waxtopselling mx-auto rounded  p-1 ">
        {/*first card */}
        <div id="topselling" className="heading1wax ">
          <h5>Top Selling</h5>
        </div>
        {categorytotal.map((element, index) => (
          <div className="card bg-white m-3 p-1">
            <div className="row w-100 mx-auto">
              <div className="col-lg-5 col-12">
                <div>
                  <img
                    className="img-fluid h-100 w-100"
                    src={element.Image}
                    alt=""
                  />
                  <span className="service_time">
                    <GoClock className="text-dark" />{" "}
                    <label className="mt-1" htmlFor="time">
                      1 hr 5mins
                    </label>
                  </span>
                  <span className="bestseller1">BESTSELLER</span>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="card-text">
                  <p>{element.Name}</p>
                  <ul id="card1">
                    <li>{element.list1}</li>
                    <li>{element.list2}</li>
                    <li id="detail1">View Details</li>
                  </ul>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mt-3">
                      <p id="p1">
                        ₹{element.Price}/- &nbsp;
                        <span className="text-success">50% off </span>{" "}
                        <span
                          style={{ textDecoration: "line-through" }}
                          className="text-secondary"
                        >
                          {" "}
                          ₹1600
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        justifyContent: window.matchMedia("(max-width:768px)")
                          .matches
                          ? "center"
                          : "normal",
                      }}
                      className="d-flex"
                    >
                      <button
                        onClick={() => HandleClick(element)}
                        style={{ backgroundColor: "#B93C5B" }}
                        className="btn text-white"
                      >
                        ADD TO CART
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div id="rica" className="mt-4">
          <div className="heading1wax ">
            <h5>Premium Facial</h5>
          </div>
          <div className=" mx-auto rounded mt-4 ">
            {/*first card */}
            {categorytotal.map((element, index) => (
                  <div className="card bg-white m-3 p-1">
                    <div className="row w-100 mx-auto">
                      <div className="col-lg-5 col-12">
                        <div>
                          <img
                            className="img-fluid h-100 w-100"
                            src={element.Image}
                            alt=""
                          />
                          <span className="service_time">
                            <GoClock className="text-dark" />{" "}
                            <label className="mt-1" htmlFor="time">
                              1 hr 5mins
                            </label>
                          </span>
                          <span className="bestseller1">BESTSELLER</span>
                        </div>
                      </div>
                      <div className="col-lg-7 col-12">
                        <div className="card-text">
                          <p>{element.Name}</p>
                          <ul id="card1">
                            <li>{element.list1}</li>
                            <li>{element.list2}</li>
                            <li id="detail1">View Details</li>
                          </ul>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-3">
                              <p id="p1">
                                ₹{element.Price}/- &nbsp;
                                <span className="text-success">
                                  50% off{" "}
                                </span>{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                  className="text-secondary"
                                >
                                  {" "}
                                  ₹1600
                                </span>
                              </p>
                            </div>
                            <div
                              style={{
                                justifyContent: window.matchMedia(
                                  "(max-width:768px)"
                                ).matches
                                  ? "center"
                                  : "normal",
                              }}
                              className="d-flex"
                            >
                              <button
                                onClick={() => HandleClick(element)}
                                style={{ backgroundColor: "#B93C5B" }}
                                className="btn text-white"
                              >
                                ADD TO CART
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
             ))}
          </div>
        </div>

        <div id="alovira" className="mt-4">
          <div className="heading1wax ">
            <h5>Classic Facial</h5>
          </div>
          <div className=" mx-auto rounded mt-4 ">
            {/*first card */}
            {categorytotal.map((element, index) => (
                  <div className="card bg-white m-3 p-1">
                    <div className="row w-100 mx-auto">
                      <div className="col-lg-5 col-12">
                        <div>
                          <img
                            className="img-fluid h-100 w-100"
                            src={element.Image}
                            alt=""
                          />
                          <span className="service_time">
                            <GoClock className="text-dark" />{" "}
                            <label className="mt-1" htmlFor="time">
                              1 hr 5mins
                            </label>
                          </span>
                          <span className="bestseller1">BESTSELLER</span>
                        </div>
                      </div>
                      <div className="col-lg-7 col-12">
                        <div className="card-text">
                          <p>{element.Name}</p>
                          <ul id="card1">
                            <li>{element.list1}</li>
                            <li>{element.list2}</li>
                            <li id="detail1">View Details</li>
                          </ul>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-3">
                              <p id="p1">
                                ₹{element.Price}/- &nbsp;
                                <span className="text-success">
                                  50% off{" "}
                                </span>{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                  className="text-secondary"
                                >
                                  {" "}
                                  ₹1600
                                </span>
                              </p>
                            </div>
                            <div
                              style={{
                                justifyContent: window.matchMedia(
                                  "(max-width:768px)"
                                ).matches
                                  ? "center"
                                  : "normal",
                              }}
                              className="d-flex"
                            >
                              <button
                                onClick={() => HandleClick(element)}
                                style={{ backgroundColor: "#B93C5B" }}
                                className="btn text-white"
                              >
                                ADD TO CART
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facial;
