import React, { useEffect, useState } from "react";
import { GoClock } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  SetSelectedCategory,
  addtoCart,
  clearCart,
  decrementCart,
  getCartTotal,
} from "../../redux/cart/CartSlice";

const SalonListDetail = () => {
  const dispatch = useDispatch();
  const { CartItems, cartTotalAmount, selectedCategory } = useSelector((store) => store.Cart);
  const { clickedSaloon, clickedCategory, choosedCategory} = useSelector((store) => store.saloon);
  const { isAuth } = useSelector((store) => store.Authentication);
  const { productBySubcat, subCategorytotal } = useSelector(
    (store) => store.subCategory
  );
  const [servicesByCat, setServicesByCat] = useState('')
  const [currCatTag, setCurrCatTag] = useState("");
  const [showProducts, setShowProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  useEffect(() => {
    if (clickedCategory) {
      const getCategories = async () => {
        const url = `${Baseurl}/api/v1/saloonServices/servicesbycatid/${clickedCategory}`
        console.log(url, 'url');
        const resp = await axios.get(url);
        if (resp.data.success) {
          console.log(resp.data.services, 'resp.data.services');
          const services = resp.data.services;
          let productbySubcat = services.map(
            (product) => (product = { ...product, cartQuantity: 0 })
          );
          console.log(productbySubcat, 'productbySubcat');
          CartItems.forEach((cartitem) => {
            let itemIndex = -1;
            itemIndex = productbySubcat.findIndex(
              (product) => product._id === cartitem.ProductId
            );
            if (itemIndex >= 0) {
              productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
            }
          });
          setServicesByCat(productbySubcat)
        }
      }
      getCategories()
    }
  }, [clickedCategory])

  const addToCartPress = (product, index) => {
    console.log(product, 'product');
    try {
        let products = servicesByCat;
        products[index].cartQuantity = products[index].cartQuantity + 1;
        setServicesByCat(products);
      } catch (error) {}
    const formData = {
        salonId: product.superCategoryId,
        ProductId: product._id,
        ProductName: product.name,
        Brand: choosedCategory.name,
        CatName: choosedCategory.name,
        CatId: choosedCategory._id,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.image,
        Mrp: product.mrp,
        Price: product.price,
        address: choosedCategory.address,
        area: choosedCategory.area,
        phone: choosedCategory.phone,
        Qty: 1,
        TotalAmount: product.price * 1,
        Mrp: product.mrp,
        TotalPrice: product.price * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.price * 1,
        productCostPrice: product.price,
        for: "Saloon"
    };
    console.log(formData, 'formData');
    dispatch(addtoCart(formData));
    dispatch(getCartTotal());
};

const decrementCartPress = (product, index) => {
    try {
        let products = showProducts;
        products[index].cartQuantity = products[index].cartQuantity - 1;
        setServicesByCat(products);
    } catch (error) { }
    const formData = {
        ProductId: product._id,
        ProductName: product.name,
        CatId: product.categoryId,
        CatName: product.category,
        Brand: product.brand,
        Services: product.services,
        Duration: product.duration,
        ImgUrl: product.ImgUrlMbl,
        Price: product.sellingPrice,
        Qty: 1,
        TotalAmount: product.sellingPrice * 1,
        Mrp: product.mrp,
        TotalPrice: product.sellingPrice * 1,
        TotalMrp: product.mrp * 1,
        Discount: product.mrp * 1 - product.sellingPrice * 1,

        productCostPrice: product.productCostPrice,
    };
    dispatch(decrementCart(formData));
    dispatch(getCartTotal());
};
console.log(servicesByCat,'servicesByCat');
  return (
    <>
      <div className="mobpro">
        {servicesByCat.length > 0 ? (
          <>
            <div id="waxing" className="waxtopselling mx-auto rounded p-1 ">
              {servicesByCat.map((product, index) => (
                <div className="card bg-white mb-2 p-1" key={index}>
                  <div className="row w-100 mx-auto">
                    <div className="col-lg-5 col-12">
                      <div>
                        <img
                          className="img-fluid"
                          src={product.image}
                          alt="img"
                        // style={{ height: "230px" }}
                        />
                        <span className="service_time">
                          <GoClock className="text-dark" />{" "}
                          <label className="mt-1" htmlFor="time">
                            {product.duration}
                          </label>
                        </span>
                        {product.newLaunch ? (
                          <>
                            <span className="bestseller1">BESTSELLER</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.mustTry ? (
                          <>
                            <span className="bestseller1">MustTry</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.outOfStock ? (
                          <>
                            <span className="bestseller1">OutOfSTock</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.inDemand ? (
                          <>
                            <span className="bestseller1">InDemand</span>
                          </>
                        ) : (
                          <></>
                        )}
                        {product.hotDeal ? (
                          <>
                            <span className="bestseller1">HotDeal</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {<div className="col-lg-7 col-12">
                      <div id="productcard" className="card-text">
                        <p id="product-name">{product.name}</p>
                        <ul id="card1">
                          {product.services.map((indService, index) => (
                            <li key={index}>{indService.service}</li>
                          ))}
                        </ul>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="ratebtn">
                            <p id="p1">
                              ₹{product.price}/- &nbsp;
                              <span
                                style={{ textDecoration: "line-through" }}
                                className="text-secondary"
                              >
                                ₹{product.mrp}
                              </span>
                            </p>
                          </div>

                          {product.cartQuantity > 0 ? (
                            <>
                              <button
                                id="btn-indec"
                                className="btn text-white  d-flex rounded justify-content-between"
                              >
                                <span
                                  onClick={() =>
                                    decrementCartPress(product, index)
                                  }
                                >
                                  <FaMinus style={{ cursor: "pointer" }} />
                                </span>
                                <span className="">{product.cartQuantity}</span>
                                <span
                                  onClick={() => addToCartPress(product, index)}
                                >
                                  <FaPlus style={{ cursor: "pointer" }} />
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  justifyContent: window.matchMedia(
                                    "(max-width:768px)"
                                  ).matches
                                    ? "center"
                                    : "normal",
                                }}
                                className="d-flex"
                                onClick={() => addToCartPress(product, index)}
                              >
                                <button
                                  id="addto"
                                  className="btn text-white "
                                >
                                  ADD TO CART
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              ))}
            </div>

            {/* 03-06-24 */}
            {/* <button>Toggle bottom offcanvas</button> */}
          </>
        ) : (
          <></>
        )}
      </div>

    </>
  );
};

export default SalonListDetail;
