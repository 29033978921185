import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";

import { FaMinus, FaPlus } from "react-icons/fa";
import { SetLoginModalOpen } from "../../redux/authentication/AuthenticationSlice";
import { setProductBySubcat } from "../../redux/subCategory/subCategorySlice";
import SelectCarDetails from "../SelectCarDetails";
import {
    SetSelectedCategory,
    addtoCart,
    clearCart,
    decrementCart,
    getCartTotal,
} from "../../redux/cart/CartSlice";
import { Delete } from "@mui/icons-material";
import { setChoosedCategory, setClickedCategory, setSelectedSaloon } from "../../redux/saloon/SaloonSlice";

const SearchCatSearch = () => {
    const { clickedSaloon, clickedCategory,choosedCategory,categoryChoosedBySearch } = useSelector((store) => store.saloon);
    const [selectedCat, setSelectedCat] = useState('')
    const [catBySaloon, setCatBySaloon] = useState('')
    const [servicesByCat, setServicesByCat] = useState('')

    useEffect(() => {
        if (clickedSaloon) {
            const getCategories = async () => {
                const url = `${Baseurl}/api/v1/saloonCategory/categorybysupercatid/${clickedSaloon}`
                const resp = await axios.get(url);
                if (resp.data.success) {
                    console.log(resp.data.category, 'resp.data.category');
                    setCatBySaloon(resp.data.category)
                    const categories=resp.data.category
                    const selectedCategory=categories.findIndex((indCategory)=>
                        indCategory._id===categoryChoosedBySearch
                    )
                    if (selectedCategory >=0) {
                        setSelectedCat(categories[selectedCategory]);
                        
                    await dispatch(setChoosedCategory(resp.data.category[selectedCategory]))
                    await dispatch(setClickedCategory(resp.data.category[selectedCategory]._id))
                    }
                }
            }
            getCategories()
        }
    }, [clickedSaloon])
    useEffect(() => {
        if (clickedCategory) {
            console.log(clickedCategory,'clickedCategory');
            const getCategories = async () => {
                const url = `${Baseurl}/api/v1/saloonServices/servicesbycatid/${clickedCategory}`
                console.log(url, 'url');
                const resp = await axios.get(url);
                if (resp.data.success) {
                    console.log(resp.data.services, 'resp.data.services');
                    const services = resp.data.services;
                    let productbySubcat = services.map(
                        (product) => (product = { ...product, cartQuantity: 0 })
                    );
                    console.log(productbySubcat,'productbySubcat');
                    CartItems.forEach((cartitem) => {
                        let itemIndex = -1;
                        itemIndex = productbySubcat.findIndex(
                            (product) => product._id === cartitem.ProductId
                        );
                        if (itemIndex >= 0) {
                            productbySubcat[itemIndex].cartQuantity = cartitem.Qty;
                        }
                    });
                    setServicesByCat(productbySubcat)
                }
            }
            getCategories()
        }
    }, [clickedCategory])
    const { CartItems, selectedCategory } = useSelector((store) => store.Cart);
    const { isAuth } = useSelector((store) => store.Authentication);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const targetDivRef = useRef(null);
    const [showProducts, setShowProducts] = useState([]);

    const handleSaloonChange = async (subcat) => {
        setSelectedCat(subcat)
        await dispatch(setClickedCategory(subcat._id))
        await dispatch(setChoosedCategory(subcat))
    }
    const addToCartPress = (product, index) => {
        console.log(product, 'product');
        try {
            let products = servicesByCat;
            products[index].cartQuantity = products[index].cartQuantity + 1;
            setShowProducts(products);
          } catch (error) {}
        const formData = {
            salonId: product.superCategoryId,
            ProductId: product._id,
            ProductName: product.name,
            Brand: selectedCat.name,
            CatName: selectedCat.name,
            CatId: selectedCat._id,
            Services: product.services,
            Duration: product.duration,
            ImgUrl: product.image,
            Mrp: product.mrp,
            Price: product.price,
            address: selectedCat.address,
            area: selectedCat.area,
            phone: selectedCat.phone,
            Qty: 1,
            TotalAmount: product.price * 1,
            Mrp: product.mrp,
            TotalPrice: product.price * 1,
            TotalMrp: product.mrp * 1,
            Discount: product.mrp * 1 - product.price * 1,
            productCostPrice: product.price,
            for: "Saloon"
        };
        console.log(formData, 'formData');
        dispatch(addtoCart(formData));
        dispatch(getCartTotal());
    };

    const decrementCartPress = (product, index) => {
        try {
            let products = showProducts;
            products[index].cartQuantity = products[index].cartQuantity - 1;
            setShowProducts(products);
        } catch (error) { }
        const formData = {
            ProductId: product._id,
            ProductName: product.name,
            CatId: product.categoryId,
            CatName: product.category,
            Brand: product.brand,
            Services: product.services,
            Duration: product.duration,
            ImgUrl: product.ImgUrlMbl,
            Price: product.sellingPrice,
            Qty: 1,
            TotalAmount: product.sellingPrice * 1,
            Mrp: product.mrp,
            TotalPrice: product.sellingPrice * 1,
            TotalMrp: product.mrp * 1,
            Discount: product.mrp * 1 - product.sellingPrice * 1,

            productCostPrice: product.productCostPrice,
        };
        dispatch(decrementCart(formData));
        dispatch(getCartTotal());
    };

    console.log(servicesByCat,'servicesByCat');

    const handleCheckout = () => {
        if (!isAuth) {
            dispatch(SetLoginModalOpen(true));
        } else {
            navigate("/cart");
        }
    };
    return (
        <>
            <div style={{ marginTop: "60px" }}>
                <div className="_3iw1l">
                    {/* <div className="_3_K4a"></div> */}
                    <div className="p-1" style={{ position: 'sticky', top: '6%', zIndex: '5', backgroundColor: '#fff' }}>
                        <div className="_3Mcoq" style={{ gridColumn: "1 / 2" }}>
                            <div className="_1PI3k" style={{ maxWidth: "unset" }}>
                                <div
                                    className="_2GkAY OBszR _1bZNH"
                                    itemScope
                                    itemType="http://schema.org/SiteNavigationElement"
                                    id="services-type-container"
                                    ref={targetDivRef}
                                >
                                    {catBySaloon &&
                                        catBySaloon.map((subcat, index) => (
                                            <Link
                                                style={{ textDecoration: "none", color: "#FFF" }}
                                                itemProp="url"
                                                key={index}
                                                id={index}
                                            >
                                                <div onClick={() => handleSaloonChange(subcat)}>
                                                    <div
                                                        className={
                                                            selectedCat.slugUrl === subcat.slugUrl
                                                                ? "_2GkAY _3O2l6 _37qa5 _17y17 _85XiK"
                                                                : "_2GkAY _3O2l6 _37qa5 _17y17"
                                                        }
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            src={subcat.desktopImage}
                                                            alt="Car Periodic Services"
                                                            className={
                                                                selectedCat.slugUrl === subcat.slugUrl
                                                                    ? "_1z4vM _3BshJ"
                                                                    : "_1z4vM"
                                                            }
                                                        />
                                                        <div
                                                            className={
                                                                selectedCat.slugUrl === subcat.slugUrl
                                                                    ? "_1qpUY d5ttk"
                                                                    : "_1qpUY"
                                                            }
                                                            style={{ fontWeight: "bold" }}
                                                            itemProp="name"
                                                        >
                                                            {subcat.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <SelectCarDetails />

                    <div style={{ backgroundColor: "#FFFFFF" }}>
                        <div className="_1t_e2" style={{ maxWidth: "unset" }}>
                            <div>
                                <div className="_13CKU">
                                    <div className="_5Ze1h" />
                                    <div className="_1ZZEG">
                                        <div>
                                            {servicesByCat &&
                                                servicesByCat?.map((product, index) => (
                                                    <div className="_1sR0p" key={index}>
                                                        {product.bestSeller ? (
                                                            <>
                                                                <div className="_1vXOy">
                                                                    <div>BESTSELLER</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {product.mustTry ? (
                                                            <>
                                                                <div className="_1vXOy">
                                                                    <div>MustTry</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {product.outOfStock ? (
                                                            <>
                                                                <div className="_1vXOy">
                                                                    <div>OutOfSTock</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {product.inDemand ? (
                                                            <>
                                                                <div className="_1vXOy">
                                                                    <div>InDemand</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {product.hotDeal ? (
                                                            <>
                                                                <div className="_1vXOy">
                                                                    <div>HotDeal</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div className="_34vKl">
                                                            <div className="_33hIJ">
                                                                <div className="_2RxcB">
                                                                    <img
                                                                        loading="lazy"
                                                                        className="_2OGze"
                                                                        src={product.image}
                                                                        alt="Car Comprehensive Service"
                                                                    />
                                                                </div>
                                                                <div className="_3Bc52">
                                                                    <div
                                                                        className="_2Rhyt"
                                                                        style={{ marginBottom: 15 }}
                                                                    >
                                                                        <div className="_1JPIu">
                                                                            <h2
                                                                                style={{ margin: 0 }}
                                                                                className="_1d-DS text-start d-flex justify-content-start"
                                                                            >
                                                                                {product.ItemName}
                                                                            </h2>
                                                                        </div>
                                                                        <div className="_1CkVx">
                                                                            <img
                                                                                loading="lazy"
                                                                                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Services/ServicesListDesktop/76D2D1C8-A85C-4C56-A53D-DE23B9C19F76.svg"
                                                                                className="_1QJKc"
                                                                                alt="Time Taken during service"
                                                                            />
                                                                            <div className="_2qAPV">
                                                                                {" "}
                                                                                {product.duration}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginLeft: 0, marginBottom: 20 }}
                                                                        className="_1qUuq"
                                                                        itemProp="name"
                                                                    >
                                                                        {product.name}
                                                                    </div>
                                                                    <div
                                                                        // className="_2UPbx"
                                                                        itemProp="itemListElement"
                                                                        itemScope
                                                                        itemType="http://schema.org/ListItem"
                                                                    >
                                                                        {product.services.map(
                                                                            (indService, index) => (
                                                                                <div
                                                                                    className="_3qzZ9"
                                                                                    itemProp="item"
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Services/B267FE43-1120-4815-B81E-6C2AE1EFC4C1.svg"
                                                                                        className="vZyEa"
                                                                                        alt="Included"
                                                                                    />
                                                                                    <div
                                                                                        className="_1qUuq"
                                                                                        itemProp="name"
                                                                                    >
                                                                                        {indService.service}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                    <div style={{ marginLeft: 0, marginTop: 20 }} className="_1qUuq">
                                                                        <span style={{ marginRight: '15px' }}><s style={{ color: 'red' }}>₹ {product.mrp}</s></span>
                                                                        <span>₹ {product.price}</span>
                                                                    </div>  
                                                                </div>
                                                            </div>

                                                            {product.cartQuantity > 0 ? (
                                                                <>
                                                                    <div
                                                                        className="_2sr-o"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <div className="I6yQz" />
                                                                        <div
                                                                            className="_59ZXH"
                                                                            style={{
                                                                                width: "20%",
                                                                                display: "flex",
                                                                                justifyContent: "space-around",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <span
                                                                                onClick={() =>
                                                                                    decrementCartPress(product, index)
                                                                                }
                                                                            >
                                                                                <FaMinus
                                                                                    style={{ cursor: "pointer" }}
                                                                                />
                                                                            </span>
                                                                            <span className="">
                                                                                {product.cartQuantity}
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    addToCartPress(product, index)
                                                                                }
                                                                            >
                                                                                <FaPlus style={{ cursor: "pointer" }} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="_2sr-o"
                                                                        onClick={() =>
                                                                            addToCartPress(product, index)
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <div className="I6yQz" />
                                                                        <div className="_59ZXH">+ ADD TO CART</div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: "2.5rem" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchCatSearch;
