import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSelectedCategory } from "../../redux/cart/CartSlice";

const AnimalCatsection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { animalCateory } = useSelector((store) => store.category);
  const { subCategorytotal } = useSelector((store) => store.subCategory);

  const [subCategories, setSubCategories] = useState("");

  const navigationClick = (cat) => {
    if (cat !== "") {
      const getSubCategory = subCategorytotal.filter(
        (subcat) => subcat.categoryId === cat._id
      );
      setSubCategories(getSubCategory[0]);
      navigate(`/pet/${cat.slugUrl}/${getSubCategory[0].name}`);
    }
  };
  return (
    <>
      {animalCateory.length > 0 ? (
        <>
          <div className="affordable">
            <div
              id="heading"
              className="px-2 d-flex justify-content-start mt-2"
            >
              <h4 >Animal Category</h4>
            </div>
            <div
              id="catogory"
              className="row mx-auto align-items-center w-100 mt-1"
            >
              {animalCateory.map((indSupCat, index) => (
                <div className="col-lg-2 col-6 col-md-4" key={index}>
                  <div
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                      cursor:"pointer"
                    }}
                    // onClick={() => {
                    //   navigate(`/saloonhome/${indSupCat.slugUrl}`);
                    // }}
                    onClick={() => {
                      navigationClick(indSupCat);
                    }}
                    className="card border-0 w-100 p-2  mx-auto "
                  >
                    <img
                      style={{
                        height: "150px",
                        width: "100%",
                        borderRadius: "5%",
                      }}
                      src={indSupCat.desktopImage}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="card-text">
                      <p className="text-center">{indSupCat.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AnimalCatsection;
